import { OnInit } from '@angular/core';
import { ToastComponent } from "../shared/toast/toast.component";
import { Router } from '@angular/router';
import { AuthService } from '../services/AuthService';
var LoadComponent = /** @class */ (function () {
    function LoadComponent(auth, router, toast) {
        this.auth = auth;
        this.router = router;
        this.toast = toast;
        this.tags = [];
        this.posts = [];
        this.isLoading = false;
    }
    LoadComponent.prototype.ngOnInit = function () {
        if (localStorage.getItem("access_token")) {
            this.auth.loggedIn = true;
            this.router.navigate(['/styleadvisor']);
        }
        else {
            this.router.navigate(['/account/login']);
        }
    };
    return LoadComponent;
}());
export { LoadComponent };
