var ProductStatus = /** @class */ (function () {
    function ProductStatus(active, inactive) {
        if (active === void 0) { active = "active"; }
        if (inactive === void 0) { inactive = "inactive"; }
        this.active = active;
        this.inactive = inactive;
    }
    return ProductStatus;
}());
export { ProductStatus };
