import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
var ConfigService = /** @class */ (function () {
    function ConfigService(http) {
        this.http = http;
        this.urlWeSaasApi = environment.apiURL;
    }
    ConfigService.prototype.get_det_to_supercat = function (user_id) {
        return this.http.get(this.urlWeSaasApi + "/mapping/mapping/detectors_supercats", { headers: this.generateHeaders(user_id) }).toPromise();
    };
    ConfigService.prototype.post_det_to_supercat = function (user_id, body) {
        return this.http.post(this.urlWeSaasApi + "/mapping/mapping/detectors_supercats", body, { headers: this.generateHeaders(user_id) }).toPromise();
    };
    ConfigService.prototype.get_cat_blacklist = function (user_id) {
        return this.http.get(this.urlWeSaasApi + "/mapping/mapping/category_blacklist", { headers: this.generateHeaders(user_id) }).toPromise();
    };
    ConfigService.prototype.set_cat_blacklist = function (user_id, body) {
        return this.http.post(this.urlWeSaasApi + "/mapping/mapping/category_blacklist", body, { headers: this.generateHeaders(user_id) }).toPromise();
    };
    ConfigService.prototype.getCsvMapping = function (user_id) {
        return this.http.get(this.urlWeSaasApi + "/mapping/detectors/csv", { headers: this.generateHeaders(user_id), responseType: 'text' });
    };
    ConfigService.prototype.postCsvMapping = function (user_id, body) {
        return this.http.post(this.urlWeSaasApi + "/mapping/detectors/csv", body, { headers: this.generateHeaders(user_id, true), responseType: 'text' });
    };
    // TODO: apikey is temporary and will remove when will move to we-saas
    ConfigService.prototype.generateHeaders = function (user_id, content) {
        if (content === void 0) { content = false; }
        var httpHeaders = new HttpHeaders({
            "userId": user_id,
            "apikey": "789655fcb02374a"
        });
        if (content) {
            var headers = httpHeaders.set("Content-Type", "text/csv");
            return headers;
        }
        return httpHeaders;
    };
    return ConfigService;
}());
export { ConfigService };
