/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "./not-found.component";
var styles_NotFoundComponent = [];
var RenderType_NotFoundComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NotFoundComponent, data: {} });
export { RenderType_NotFoundComponent as RenderType_NotFoundComponent };
export function View_NotFoundComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 12, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 11, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 10, "div", [["class", "card"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "h5", [["class", "card-header"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Featured"])), (_l()(), i0.ɵeld(6, 0, null, null, 7, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "h5", [["class", "card-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["404 Not Found"])), (_l()(), i0.ɵeld(9, 0, null, null, 1, "p", [["class", "card-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["The page you requested was not found."])), (_l()(), i0.ɵeld(11, 0, null, null, 2, "a", [["class", "btn btn-primary"], ["routerLink", "/"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 12).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(12, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵted(-1, null, ["Homepage"]))], function (_ck, _v) { var currVal_2 = "/"; _ck(_v, 12, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 12).target; var currVal_1 = i0.ɵnov(_v, 12).href; _ck(_v, 11, 0, currVal_0, currVal_1); }); }
export function View_NotFoundComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-not-found", [], null, null, null, View_NotFoundComponent_0, RenderType_NotFoundComponent)), i0.ɵdid(1, 49152, null, 0, i3.NotFoundComponent, [], null, null)], null, null); }
var NotFoundComponentNgFactory = i0.ɵccf("app-not-found", i3.NotFoundComponent, View_NotFoundComponent_Host_0, {}, {}, []);
export { NotFoundComponentNgFactory as NotFoundComponentNgFactory };
