import { ApplicationRef, Injector, NgModule, PlatformRef } from '@angular/core';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { routing } from './routing.module';
import { SharedModule } from './shared/shared.module';
import { UserService } from './services/user.service';
import { AuthService } from './services/AuthService';
import { Shared } from "./util/service/shared.methods";
import { AuthGuardLogin } from './services/authGuardLoginService';
import { AppComponent } from './app.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { BrowserModule } from '@angular/platform-browser';
import { DynamicModule } from './dynamic/dynamic.module';
import { environment } from '../environments/environment';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AuthInterceptor } from './util/interceptor/auth.interceptor';
import { ReactiveFormsModule } from '@angular/forms';
import { DashboardComponent } from "./dashboard/styleAdvisor/dashboard.component";
import { HomeComponent } from "./dashboard/home/home.component";
import { ComputeDetectorsComponent } from "./dashboard/computeDetectors/computedetectors.component";
import { ConfigPagesComponent } from "./dashboard/styleAdvisor/configPages/styleConfig.component";
import { VisionProductComponent } from "./dashboard/visionProducts/product.component";
import { ProductService } from "./services/ProductService";
import { ConfigService } from "./services/ConfigService";
import { LookService } from "./services/LookService";
import { LoadComponent } from "./load/load.component";
import { DeferLoadModule } from '@trademe/ng-defer-load';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PagerService } from './shared/page/pager.service';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatFormFieldModule, MatInputModule, MatCheckboxModule } from '@angular/material';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { MatButtonModule } from '@angular/material/button';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatToolbarModule } from '@angular/material/toolbar';
@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    DashboardComponent,
    HomeComponent,
    ComputeDetectorsComponent,
    ConfigPagesComponent,
    LoadComponent,
    VisionProductComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    environment.production ? ServiceWorkerModule.register('/ngsw-worker.js') : [],
    routing,
    SharedModule,
    DynamicModule,
    ReactiveFormsModule,
    DeferLoadModule,
    InfiniteScrollModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgbModule.forRoot(),
    MatButtonModule,
    DragDropModule,
    NgMultiSelectDropDownModule.forRoot(),
    MatFormFieldModule,
    MatInputModule,
    MatToolbarModule,
    MatCheckboxModule
  ],
  exports: [
    MatButtonModule
  ],
  providers: [
    AuthService,
    AuthGuardLogin,
    UserService,
    PagerService,
    LookService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    ProductService,
    ConfigService,
    Shared,
  ],
  bootstrap: [
    AppComponent,
  ],
})

export class AppModule {
  /**
   * Allows for retrieving singletons using `AppModule.injector.get(MyService)`
   * (whereas `ReflectiveInjector.resolveAndCreate(MyService)` would create a
   * new instance of the service).
   */

}

