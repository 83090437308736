import { ApplicationRef, enableProdMode } from '@angular/core';
import { environment } from './environments/environment.prod';
import { enableDebugTools } from '@angular/platform-browser';
import * as __NgCli_bootstrap_1 from "./app/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
if (environment.production) {
    enableProdMode();
}
__NgCli_bootstrap_2.platformBrowser()
    .bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory)
    .then(function (module) {
    var applicationRef = module.injector.get(ApplicationRef);
    var appComponent = applicationRef.components[0];
    window['appNg'] = applicationRef;
    window['injectorNg'] = module.injector;
    enableDebugTools(appComponent);
});
