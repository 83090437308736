var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __asyncValues = (this && this.__asyncValues) || function (o) {
    if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
    var m = o[Symbol.asyncIterator], i;
    return m ? m.call(o) : (o = typeof __values === "function" ? __values(o) : o[Symbol.iterator](), i = {}, verb("next"), verb("throw"), verb("return"), i[Symbol.asyncIterator] = function () { return this; }, i);
    function verb(n) { i[n] = o[n] && function (v) { return new Promise(function (resolve, reject) { v = o[n](v), settle(resolve, reject, v.done, v.value); }); }; }
    function settle(resolve, reject, d, v) { Promise.resolve(v).then(function(v) { resolve({ value: v, done: d }); }, reject); }
};
import { OnInit } from '@angular/core';
import { ToastComponent } from "../../shared/toast/toast.component";
import { ModalComponent } from "../../shared/modal/modal.component";
import { LookService } from "../../services/LookService";
import { ProductService } from "../../services/ProductService";
import { ErrFmt } from "../../util/helpers/err.helper";
import { Router } from '@angular/router';
import { AuthService } from '../../services/AuthService';
import { LookGenerationTypes, LookRecommendationTypes, LookNoCategories, LookNoCategoriesImages } from "../../shared/models/utils.models";
import { ProductStatus } from "../../shared/models/status.model";
var DashboardComponent = /** @class */ (function () {
    function DashboardComponent(auth, lookService, productService, router, toast, modal) {
        this.auth = auth;
        this.lookService = lookService;
        this.productService = productService;
        this.router = router;
        this.toast = toast;
        this.modal = modal;
        this.imagesList = [];
        this.numOfCandidates = 0;
        this.loadImage = false;
        this.looks = [];
        this.preSelectedProducts = {};
        this.isLoading = true;
        this.isLoadingCount = true;
        this.loggedUser = 0;
        this.recommends = {};
        this.recommendsNotFound = {};
        this.recommendsNoCandidates = {};
        this.look_count = [];
        this.pager = {};
        this.page_num = "1";
        this.categories_data = {};
        this.genders = [];
        this.categories = {};
        this.subcategories = {};
        this.objectKeys = Object.keys;
        this.selectedGender = null;
        this.selectedCategory = null;
        this.selectedSubcategory = null;
        this.selectedGenerationType = null;
        this.selectedRecommendationType = null;
        this.selectedNoCategory = null;
        this.selectedNoCategoryToAdd = null;
        this.selectedFilter = 'time';
        this.includeInactiveProducts = true;
        this.containUid = null;
        this.nextToken = null;
        this.nextTokenArray = {};
        this.nextTokenIndexKey = 0;
        this.owlDateTime = null;
        this.lookTypes = new LookGenerationTypes();
        this.lookRecommendationTypes = new LookRecommendationTypes();
        this.lookNoCategories = new LookNoCategories();
        this.lookNoCategoriesImages = new LookNoCategoriesImages();
        this.default_gen_types = Object.values(this.lookTypes);
        this.default_recom_types = Object.values(this.lookRecommendationTypes);
        this.default_no_categories = Object.values(this.lookNoCategories);
        this.main_products_of_looks = {};
        this.status = new ProductStatus();
    }
    DashboardComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.showAdvance = false;
                this.lazyLoad = true;
                this.token = localStorage.getItem("access_token");
                this.auth.user_id = localStorage.getItem("user_id");
                this.apikey = localStorage.getItem("apikey");
                if (this.auth.isAuthenticated()) {
                    this.auth.loggedIn = true;
                    this.router.navigate(['/styleadvisor']);
                    this.getCategoryData();
                    this.getLooksMany(true);
                }
                else {
                    this.auth.loggedIn = false;
                    this.router.navigate(['/account/login']);
                }
                return [2 /*return*/];
            });
        });
    };
    DashboardComponent.prototype.getLookByUid = function (product_uid) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(product_uid && product_uid !== "")) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.lookService.getLookByUid(product_uid, this.token).then(function (data) {
                                _this.hidePagination = true;
                                _this.looks = data.results;
                                _this.getRecommends();
                            }).catch(function (error) {
                                _this.toast.setMessage("Look not found", 'danger');
                            })];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        this.toast.setMessage("Type product uid", 'info');
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    DashboardComponent.prototype.getLookByContainUid = function (product_uid) {
        var _this = this;
        if (product_uid && product_uid !== "") {
            this.isLoading = true;
            this.lookService.containsUid(product_uid, this.token).subscribe(function (data) {
                _this.hidePagination = true;
                _this.looks = data.results;
                _this.getRecommends();
                _this.isLoading = false;
                if (!_this.looks.length) {
                    _this.toast.setMessage("There is not any look with given candidate UID", 'info');
                }
            }, function (error) { return _this.toast.setMessage(ErrFmt(error), 'danger'); }, function () { return _this.isLoading = false; });
        }
        else {
            this.toast.setMessage("Type product uid", 'info');
        }
    };
    DashboardComponent.prototype.deleteProductLook = function (look, look_product) {
        var _this = this;
        if (confirm("Are you sure you want to delete this candidate?")) {
            this.lookService.deleteProductLook(look.id, look_product.id, this.token).subscribe(function (data) {
                if (data.results.length > 0) {
                    look.products = look.products.filter(function (obj) { return obj.match !== look_product.match; });
                    _this.toast.setMessage("Candidate deleted sucessfully", 'success');
                }
                else {
                    _this.toast.setMessage("Unknown error occurred", 'danger');
                }
            }, function (error) { return _this.toast.setMessage(ErrFmt(error), 'danger'); }, function () { return _this.isLoading = false; });
        }
    };
    DashboardComponent.prototype.getNoCandidateImage = function (noCategory) {
    };
    DashboardComponent.prototype.getPreSelectedProducts = function (looks) {
        return __awaiter(this, void 0, void 0, function () {
            var e_1, _a, _i, looks_1, look, idsPreSelected, futures, _b, idsPreSelected_1, uid, futures_1, futures_1_1, response, _c, _d, product, e_1_1;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _i = 0, looks_1 = looks;
                        _e.label = 1;
                    case 1:
                        if (!(_i < looks_1.length)) return [3 /*break*/, 14];
                        look = looks_1[_i];
                        idsPreSelected = look.preSelectedProducts;
                        this.preSelectedProducts[look.id] = [];
                        futures = [];
                        for (_b = 0, idsPreSelected_1 = idsPreSelected; _b < idsPreSelected_1.length; _b++) {
                            uid = idsPreSelected_1[_b];
                            futures.push(this.productService.getProduct(uid, this.token, this.status.active).catch(function (error) { return error; }));
                        }
                        _e.label = 2;
                    case 2:
                        _e.trys.push([2, 7, 8, 13]);
                        futures_1 = __asyncValues(futures);
                        _e.label = 3;
                    case 3: return [4 /*yield*/, futures_1.next()];
                    case 4:
                        if (!(futures_1_1 = _e.sent(), !futures_1_1.done)) return [3 /*break*/, 6];
                        response = futures_1_1.value;
                        if (response && response.results) {
                            for (_c = 0, _d = response.results; _c < _d.length; _c++) {
                                product = _d[_c];
                                if (product.uid) {
                                    this.preSelectedProducts[look.id].push(product);
                                }
                            }
                        }
                        _e.label = 5;
                    case 5: return [3 /*break*/, 3];
                    case 6: return [3 /*break*/, 13];
                    case 7:
                        e_1_1 = _e.sent();
                        e_1 = { error: e_1_1 };
                        return [3 /*break*/, 13];
                    case 8:
                        _e.trys.push([8, , 11, 12]);
                        if (!(futures_1_1 && !futures_1_1.done && (_a = futures_1.return))) return [3 /*break*/, 10];
                        return [4 /*yield*/, _a.call(futures_1)];
                    case 9:
                        _e.sent();
                        _e.label = 10;
                    case 10: return [3 /*break*/, 12];
                    case 11:
                        if (e_1) throw e_1.error;
                        return [7 /*endfinally*/];
                    case 12: return [7 /*endfinally*/];
                    case 13:
                        _i++;
                        return [3 /*break*/, 1];
                    case 14: return [2 /*return*/];
                }
            });
        });
    };
    DashboardComponent.prototype.getRecommends = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _i, _a, i, look, products, _loop_1, this_1, _b, _c, j;
            var _this = this;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        this.isLoading = true;
                        this.recommends = {};
                        this.recommendsNotFound = {};
                        this.recommendsNoCandidates = {};
                        _i = 0, _a = Object.keys(this.looks);
                        _d.label = 1;
                    case 1:
                        if (!(_i < _a.length)) return [3 /*break*/, 6];
                        i = _a[_i];
                        look = this.looks[i];
                        products = look.products;
                        this.get_look_product(look.uid);
                        _loop_1 = function (j) {
                            var uid_1;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!products[j].hasOwnProperty('match')) return [3 /*break*/, 4];
                                        uid_1 = products[j].match;
                                        if (!(uid_1 !== null && uid_1 !== '')) return [3 /*break*/, 2];
                                        return [4 /*yield*/, this_1.productService.getProduct(uid_1, this_1.token, "active").then(function (data) {
                                                var product = data.results[0];
                                                if (product) {
                                                    product.visual_status = "active";
                                                    _this.recommends[product.uid] = product;
                                                }
                                            }).catch(function () {
                                                if (_this.includeInactiveProducts) {
                                                    _this.productService.getProduct(uid_1, _this.token, "inactive").then(function (data) {
                                                        var product = data.results[0];
                                                        if (product) {
                                                            product.visual_status = "inactive";
                                                            _this.recommends[product.uid] = product;
                                                        }
                                                    }).catch(function () {
                                                        _this.recommendsNotFound[uid_1] = uid_1;
                                                    });
                                                }
                                                else {
                                                    _this.recommendsNotFound[uid_1] = uid_1;
                                                }
                                            })];
                                    case 1:
                                        _a.sent();
                                        return [3 /*break*/, 3];
                                    case 2:
                                        this_1.recommendsNoCandidates[products[j].id] = products[j].id;
                                        _a.label = 3;
                                    case 3: return [3 /*break*/, 5];
                                    case 4:
                                        this_1.recommendsNoCandidates[products[j].id] = products[j].id;
                                        _a.label = 5;
                                    case 5: return [2 /*return*/];
                                }
                            });
                        };
                        this_1 = this;
                        _b = 0, _c = Object.keys(products);
                        _d.label = 2;
                    case 2:
                        if (!(_b < _c.length)) return [3 /*break*/, 5];
                        j = _c[_b];
                        return [5 /*yield**/, _loop_1(j)];
                    case 3:
                        _d.sent();
                        _d.label = 4;
                    case 4:
                        _b++;
                        return [3 /*break*/, 2];
                    case 5:
                        _i++;
                        return [3 /*break*/, 1];
                    case 6: return [4 /*yield*/, this.getPreSelectedProducts(this.looks)];
                    case 7:
                        _d.sent();
                        this.isLoading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    DashboardComponent.prototype.get_look_product = function (uid) {
        return __awaiter(this, void 0, void 0, function () {
            var data_active;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.productService.getProduct(uid, this.token, "active")];
                    case 1:
                        data_active = _a.sent();
                        if (data_active.results && data_active.results.length) {
                            this.main_products_of_looks[uid] = data_active.results[0];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    DashboardComponent.prototype.getCountLooks = function () {
        var _this = this;
        this.isLoadingCount = true;
        this.lookService.countLooksManyV2(this.selectedGender, this.selectedCategory, this.selectedSubcategory, this.selectedGenerationType, this.selectedRecommendationType, this.selectedNoCategory, this.startAt, this.endAt, this.token).then(function (data) {
            for (var i = 1; i <= data.result; i++) {
                _this.look_count.push(i);
                _this.isLoadingCount = false;
            }
        }, function (error) {
            if (error.status === 401) {
                _this.toast.setMessage("Session expired, please log in", "danger");
                console.log("Unauthorized!", error.status);
                _this.router.navigate(['/account/login']);
            }
        });
    };
    DashboardComponent.prototype.getCategoryData = function () {
        var _this = this;
        this.productService.getCategories(this.token).then(function (data) {
            _this.categories_data = data.categories;
            _this.genders = Object.keys(_this.categories_data);
            for (var _i = 0, _a = Object.keys(_this.categories_data); _i < _a.length; _i++) {
                var i = _a[_i];
                _this.categories[i] = _this.categories_data[i].map(function (key) { return ({ "category": key.name, "subcategory": key.subcategories.map(function (val) { return (val.name); }) }); });
                for (var _b = 0, _c = Object.keys(_this.categories[i]); _b < _c.length; _b++) {
                    var j = _c[_b];
                    _this.subcategories[i + _this.categories[i][j]["category"]] = _this.categories[i][j]["subcategory"];
                }
            }
        });
    };
    DashboardComponent.prototype.getPreviousBatch = function () {
        if (this.nextTokenIndexKey > 1) {
            this.nextTokenIndexKey = this.nextTokenIndexKey - 2;
            var last_token = this.nextTokenArray[this.nextTokenIndexKey];
            this.getLooksMany(false, last_token);
        }
    };
    DashboardComponent.prototype.getNextBatch = function () {
        var last_token = this.nextTokenArray[this.nextTokenIndexKey];
        this.getLooksMany(false, last_token);
    };
    DashboardComponent.prototype.getLooksMany = function (all, nextToken) {
        if (nextToken === void 0) { nextToken = null; }
        return __awaiter(this, void 0, void 0, function () {
            var data, e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isLoading = true;
                        this.hidePagination = false;
                        this.looks = [];
                        this.recommends = {};
                        this.recommendsNotFound = {};
                        this.main_products_of_looks = {};
                        if (!nextToken) {
                            this.look_count = [];
                            this.nextTokenArray = {};
                            this.nextTokenIndexKey = 0;
                            this.getCountLooks();
                        }
                        if (all) {
                            this.clearFilters();
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.lookService.getLooksManyV2(this.selectedGender, this.selectedCategory, this.selectedSubcategory, this.selectedGenerationType, this.selectedRecommendationType, this.selectedNoCategory, this.startAt, this.endAt, nextToken, this.token)];
                    case 2:
                        data = _a.sent();
                        this.nextToken = data.nextToken;
                        this.nextTokenIndexKey++;
                        this.nextTokenArray[this.nextTokenIndexKey] = this.nextToken;
                        this.looks = data.results;
                        this.isLoading = false;
                        window.scroll(0, 0);
                        if (!this.looks.length) {
                            this.toast.setMessage("There is not any look", 'info');
                        }
                        this.getRecommends();
                        return [3 /*break*/, 4];
                    case 3:
                        e_2 = _a.sent();
                        this.toast.setMessage("Interrupted getting all looks", 'danger');
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    DashboardComponent.prototype.reset_look = function (priority, look) {
        var _this = this;
        if (confirm("Are you sure you want to reset the look?")) {
            priority = JSON.parse(priority);
            this.lookService.resetLook(look.id, priority, this.token).subscribe(function (data) {
                if (data.errors && data.errors.length) {
                    _this.toast.setMessage("Unknown error occured", 'danger');
                }
                else {
                    _this.toast.setMessage("Look reset correctly", 'success');
                }
            });
        }
    };
    DashboardComponent.prototype.generate_cache = function (type, look) {
        if (confirm("Are you sure you want to generate the cache?")) {
            this.lookService.generateCache(look.uid, type, this.token, this.apikey).subscribe(function (data) {
                console.log(data);
            });
        }
    };
    DashboardComponent.prototype.changeMainImg = function (img, look) {
        for (var i in this.looks) {
            if (this.looks[i].uid === look.uid) {
                if (this.looks[i].alternativeImages.indexOf(this.looks[i].imageUrl) === -1) {
                    this.looks[i].alternativeImages.push(this.looks[i].imageUrl);
                }
                this.looks[i].imageUrl = img;
            }
        }
    };
    DashboardComponent.prototype.toggleAdvance = function () {
        this.showAdvance = !this.showAdvance;
    };
    DashboardComponent.prototype.onLoadLazy = function () {
        this.lazyLoad = false;
    };
    DashboardComponent.prototype.selectGender = function (value) {
        this.selectedFilter = "categorization";
        this.selectedGender = value;
        this.selectedCategory = null;
        this.selectedSubcategory = null;
    };
    DashboardComponent.prototype.selectCategory = function (value) {
        this.selectedCategory = value;
        this.selectedSubcategory = null;
    };
    DashboardComponent.prototype.selectSubcategory = function (value) {
        this.selectedSubcategory = value;
    };
    DashboardComponent.prototype.selectGenerationType = function (value) {
        this.selectedGenerationType = value;
    };
    DashboardComponent.prototype.selectorRecommendationType = function (value) {
        this.selectedRecommendationType = value;
        if (value !== this.lookRecommendationTypes.no) {
            this.selectedNoCategory = '';
        }
    };
    DashboardComponent.prototype.selectorNoCategory = function (value) {
        this.selectedNoCategory = value;
    };
    DashboardComponent.prototype.selectNoCategoryToAdd = function (value) {
        this.selectedNoCategoryToAdd = value;
    };
    DashboardComponent.prototype.selectRecommendationType = function (look, product) {
        var _this = this;
        // Clear noCategory if the recommendation type is not 'NO'
        if (product.recommendationType !== this.lookRecommendationTypes.no) {
            product.noCategory = ''; // Clear the value in the product
            look.products.forEach(function (prod) {
                if (prod.match === product.uid) {
                    prod.noCategory = '';
                }
            });
        }
        this.lookService.putLook(look, this.token).subscribe(function (data) {
            if (data.errors.length > 0) {
                _this.toast.setMessage("Unknown error occurred", 'danger');
            }
            else {
                _this.toast.setMessage("Recommendation type saved successfully", 'success');
            }
        }, function (error) { return _this.toast.setMessage(ErrFmt(error), 'danger'); }, function () { return _this.isLoading = false; });
    };
    DashboardComponent.prototype.addNoCandidate = function (look) {
        var _this = this;
        // Clear noCategory if the recommendation type is not 'NO'
        if (this.selectedNoCategoryToAdd !== null && this.selectedNoCategoryToAdd !== '') {
            var product = {
                noCategory: this.selectedNoCategoryToAdd,
                recommendationType: this.lookRecommendationTypes.no
            };
            look.products.push(product);
        }
        this.lookService.putLook(look, this.token).subscribe(function (data) {
            if (data.errors.length > 0) {
                _this.toast.setMessage("Unknown error occurred", 'danger');
            }
            else {
                _this.toast.setMessage("Recommendation type saved successfully", 'success');
            }
        }, function (error) { return _this.toast.setMessage(ErrFmt(error), 'danger'); }, function () { return _this.isLoading = false; });
    };
    DashboardComponent.prototype.selectByAnnotation = function (value) {
        if (value.id === "owl-dt-picker-0") {
            if (value.selected) {
                this.startAt = value.selected.toISOString();
            }
            else {
                this.startAt = null;
            }
        }
        if (value.id === "owl-dt-picker-1") {
            if (value.selected) {
                this.endAt = value.selected.toISOString();
            }
            else {
                this.endAt = null;
            }
        }
        this.selectedFilter = "time";
    };
    DashboardComponent.prototype.clearFilters = function () {
        this.selectedGender = null;
        this.selectedCategory = null;
        this.selectedSubcategory = null;
        this.startAt = null;
        this.endAt = null;
        this.nextToken = null;
        this.selectedGenerationType = null;
        this.selectedRecommendationType = null;
        this.selectedNoCategory = null;
    };
    DashboardComponent.prototype.validateLook = function (look) {
        return __awaiter(this, void 0, void 0, function () {
            var main_prd;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(look.products.length > 0)) return [3 /*break*/, 7];
                        main_prd = look.products.filter(function (obj) { return obj.match === look.uid; });
                        if (!(main_prd && main_prd.length)) return [3 /*break*/, 5];
                        if (!(look.products.length === 5)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.postPerfectMatch(look, main_prd[0])];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 2:
                        if (!confirm("the looks should contain 5 elements: STILL VALIDATE ?")) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.postPerfectMatch(look, main_prd[0])];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        this.toast.setMessage("Main product is not in the candidates", 'info');
                        _a.label = 6;
                    case 6: return [3 /*break*/, 8];
                    case 7:
                        this.toast.setMessage("There is not any candidate", 'info');
                        _a.label = 8;
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    DashboardComponent.prototype.postPerfectMatch = function (look, main_prd) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.lookService.setPerfectMatch(look.id, main_prd.id, main_prd.match, this.token)];
                    case 1:
                        _a.sent();
                        this.toast.setMessage("Correctly validated", "success");
                        look.generationType = "manual";
                        return [2 /*return*/];
                }
            });
        });
    };
    DashboardComponent.prototype.inactiveProductsAction = function (event) {
        this.includeInactiveProducts = event.target.checked;
    };
    DashboardComponent.prototype.goToConfig = function () {
        this.router.navigate(['/styleadvisor/configs']);
    };
    DashboardComponent.prototype.getDate = function (date) {
        return new Date(date).toLocaleString();
    };
    return DashboardComponent;
}());
export { DashboardComponent };
