/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./toast.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./toast.component";
var styles_ToastComponent = [i0.styles];
var RenderType_ToastComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ToastComponent, data: {} });
export { RenderType_ToastComponent as RenderType_ToastComponent };
function View_ToastComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["role", "alert"]], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["data-dismiss", "alert"], ["type", "button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Message:"])), (_l()(), i1.ɵted(6, null, [" ", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "alert alert-", _co.message.type, " alert-dismissible"); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.message.body; _ck(_v, 6, 0, currVal_1); }); }
export function View_ToastComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ToastComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.message.body; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ToastComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-toast", [], null, null, null, View_ToastComponent_0, RenderType_ToastComponent)), i1.ɵdid(1, 49152, null, 0, i3.ToastComponent, [], null, null)], null, null); }
var ToastComponentNgFactory = i1.ɵccf("app-toast", i3.ToastComponent, View_ToastComponent_Host_0, { message: "message" }, {}, []);
export { ToastComponentNgFactory as ToastComponentNgFactory };
