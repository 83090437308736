import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';


@Injectable()
export class ProductService {
  apiURL: string = environment.apiURL;
  apiURLSTAGING: string = environment.apiURLSTAGING;
  apiURLMapping: string = environment.apiURLMapping;
  apiMirrorURL: string = environment.apiMirrorURL;
  apiMirrorURLSTAGING: string = environment.apiMirrorURLSTAGING;
  constructor(private http: HttpClient) {
  }

  getProduct(uid: string, token: string, status: string): Promise<any> {
    const response = this.http.get(`${this.apiURL}/v2/product/${uid}?status=${status}`, { headers: this.getHeaders(token) });
    return response.toPromise();
  }

  getProductMany(gender: any, category: any, subcategory: any, page: string, limit: string, token: string, NextToken = null): Promise<any> {
    const params = this.generateQueryString(gender, category, subcategory, page, limit, NextToken);
    return this.http.get(`${this.apiURL}/v2/product?${params}`, { headers: this.getHeaders(token) }).toPromise();
  }

  insertOrUpdate(product: { uid: string }, token: string): Promise<any> {
    return this.http.put(`${this.apiURL}/v2/product/${product.uid}`, product, { headers: this.getHeaders(token) }).toPromise();
  }

  changeProductCategory(uid: string, body: Object, token: string): Promise<any> {
    return this.http.put(`${this.apiURL}/v2/product/${uid}/changeCategory`, body, { headers: this.getHeaders(token) }).toPromise();
  }

  getCategories(token: string): Promise<any> {
    return this.http.post(`${this.apiURL}/v2/get_category_data`, {}, { headers: this.getHeaders(token) }).toPromise();
  }

  getCount(gender: string, category: string, subcategory: string, token: string): Promise<any> {
    const params = this.generateQueryString(gender, category, subcategory);
    return this.http.get(`${this.apiURL}/v2/product/count?${params}`, { headers: this.getHeaders(token) }).toPromise();
  }

  async getSimilars(uid: string, body: Object, token: string): Promise<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'apikey': `${token}`
    });
    return this.http.post(`${this.apiURL}/v4/SearchById`, body, { headers: httpHeaders }).toPromise();
  }

  async getTextSearch(body: Object, token: string): Promise<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'apikey': `${token}`
    });
    return this.http.post(`${this.apiMirrorURL}/v5/textSearch`, body, { headers: httpHeaders }).toPromise();
  }

  deleteProduct(uid: string, token: string): Promise<any> {
    return this.http.delete(`${this.apiURL}/v2/product/${uid}`, { headers: this.getHeaders(token) }).toPromise();
  }

  getHeaders(token: string) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `bearer ${token}`
    });
    return httpHeaders;
  }

  generateQueryString(gender, category, subcategory, page = null, limit = null, NextToken = null) {
    let params = new HttpParams();
    if (gender) {
      params = params.append('gender', gender);
    }
    if (category) {
      params = params.append('category', category);
    }
    if (subcategory) {
      params = params.append('subcategory', subcategory);
    }
    if (page) {
      params = params.append('page', page);
    }
    if (limit) {
      params = params.append('limit', limit);
    }
    if (NextToken) {
      params = params.append('token', NextToken);
    }
    return params;
  }

}
