<app-toast [message]="toast.message"></app-toast>
<div class="container">
  <div role="tabpanel" class="tab-pane clearfix" id="detectors">
    <div class="csv-content">
      <table class="table table-striped">
        <tr>
          <th>Line num</th>
          <th>Client's Category</th>
          <th>Client's Subcategory</th>
          <th>Client's Gender</th>
          <th>Number of Products</th>
          <th>Wideeyes category</th>
          <th>Check img</th>
        </tr>
        <tr *ngFor="let line of parsialLines">
          <td>{{line.lineNumber}}</td>
          <td>{{line.clientCat}}</td>
          <td>{{line.clientSubcat}}</td>
          <td>{{line.clientGender}}</td>
          <td>{{line.prductsNumber}}</td>
          <td class="multiselect">
            <ng-multiselect-dropdown [placeholder]="'empty'" [data]="dropdownList" [settings]="dropdownSettings"
              [(ngModel)]="line.selectedItems" (onSelect)="onItemSelect(line)" (onDeSelect)="onDeSelect(line)"
              ngbTooltip={{line.wideeyesCategory}}>
            </ng-multiselect-dropdown>
          </td>
          <td><button id="showProducts" (click)="showProducts(line, content)"
              class="btn btn-outline-dark btn-xs ">Show</button>
          </td>
        </tr>
      </table>
    </div>
    <div class="pagination">
      <div class="text-center">
        <ul *ngIf="csvLines.length > 200">
          <li *ngFor="let page of pager">
            <a (click)="setPage(page)"
              [ngStyle]="{'border-bottom': page === selectedPage ? '1px solid black' : 'none'}">{{page + 1}}</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="text-center">
      <app-loading [condition]="isLoading"></app-loading>
    </div>
    <div id="bottom" class="col-xs-12 m-5 float-right">
      <button id="submitCsvButton2" (click)="postUsersCSVDetectors()" class="btn btn-success">Update
        mapping</button>
    </div>
  </div>
</div>


<!-- Modal to display products -->
<ng-template #content let-c="close" class="bg-dark" let-d="dismiss">
  <div class="modal-body bg-dark">
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
    <div class="text-center">
      <app-loading [condition]="isLoading"></app-loading>
    </div>
    <div *ngFor="let product of modalProducts" style="float:left;">
      <div class="card text-white bg-dark mb-1 m-1" style="max-width: 15rem;">
        <img class="card-img-top" src="{{product.images[0].imageToProcess || product.images[0].imageUrl}}" alt="Card image cap">
        <div class="card-body text-center" style="line-height: 90%; font-size: 90%;">
          <p class="card-text text-muted small">uid: {{product.uid}}</p>
        </div>
      </div>
    </div>
  </div>
</ng-template>
