import { Injectable } from '@angular/core';
import { ToastComponent } from '../../shared/toast/toast.component';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class Shared {

    constructor(
        private router: Router,
        public toast: ToastComponent, ) {
    }

    RouteTo(path: string) {
        switch (path) {
            case ("styleAdvisor"):
                this.router.navigate(['/styleadvisor']);
                break;
            case ("computeDetectors"):
                this.router.navigate(['/computedetectors']);
                break;
            case ("visionproducts"):
                this.router.navigate(['/visionproducts']);
                break;
            default:
                this.toast.setMessage("Coming soon", "info");
        }
    }
}
