var AuthInterceptor = /** @class */ (function () {
    function AuthInterceptor() {
        this.getAuthorizationHeader = function () {
            var token = localStorage.getItem('access_token');
            return token;
        };
    }
    AuthInterceptor.prototype.intercept = function (req, next) {
        var authHeader = req.headers.get('Authorization');
        var authReq = req;
        if (!authHeader) {
            console.log('intercepted request ... ');
            authHeader = this.getAuthorizationHeader();
            // Clone the request to add the new header.
            if (authHeader) {
                authReq = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + authHeader) });
                console.log(authReq);
            }
        }
        // send the newly created request
        return next.handle(authReq);
    };
    return AuthInterceptor;
}());
export { AuthInterceptor };
