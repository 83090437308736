import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ToastComponent } from './toast/toast.component';
import { ModalComponent } from './modal/modal.component';
import { LoadingComponent } from './loading/loading.component';
import { CommonModule } from '@angular/common';
import { TestComponent } from './test/test.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from '../util/interceptor/auth.interceptor';
import { HeaderComponent } from './page/header.component';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './page/footer.component';
import { AngularPaginatorModule } from 'angular-paginator';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    AngularPaginatorModule,
    NgbCollapseModule,
  ],
  exports: [
    // Shared Modules
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    // Shared Components
    HttpClientModule,
    ToastComponent,
    ModalComponent,
    LoadingComponent,
    HeaderComponent,
    FooterComponent,
    ProgressBarComponent,
    TestComponent,
  ],
  declarations: [
    ToastComponent,
    ModalComponent,
    LoadingComponent,
    HeaderComponent,
    FooterComponent,
    ProgressBarComponent,
    TestComponent,
  ],
  providers: [
    ToastComponent,
    ModalComponent,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
})
export class SharedModule {
}
