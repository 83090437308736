import { Component, Input } from '@angular/core';
import { ProductService } from "../../services/ProductService";
import { LookService } from "../../services/LookService";
import { ToastComponent } from "../../shared/toast/toast.component";
import { NgbModalConfig, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { PagerService } from '../../shared/page/pager.service';
import { ProductStatus } from "../../shared/models/status.model";

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  // add NgbModalConfig and NgbModal to the component providers
  providers: [NgbModalConfig, NgbModal],
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent {
  @Input() look_product;
  @Input() look;
  @Input() type;
  @Input() token;
  @Input() recommends;
  @Input() selectedGender;
  @Input() apikey;
  selectedCategory = null;
  selectedSubcategory = null;
  isLoading = true;
  buttonLoading = false;
  isPerfectMatchSelected = false;
  candidates_similars = [];
  categories_data = {};
  genders = [];
  categories = {};
  subcategories = {};
  objectKeys = Object.keys;
  new_products = [];
  selected_new_candidate = { "uid": "" };
  product_uid = "";
  product_count = [];
  pager: any = {};
  pagedItems: any[];
  cached_candidates = [];
  status = new ProductStatus();
  limit = "50";
  textSearch = "";
  constructor(
    public toast: ToastComponent,
    config: NgbModalConfig,
    private modalService: NgbModal,
    private productService: ProductService,
    private lookService: LookService,
    private pagerService: PagerService) {
    config.backdrop = true;
    config.keyboard = true;

  }

  open(content) {
    if (this.type === "updateCandidate") {
      this.lookService.getLookWithSimilars(this.look.id, this.token).subscribe(
        (data: any) => {
          this.isLoading = false;
          try {
            this.candidates_similars = data.results[0].products.filter(obj => obj.id === this.look_product.id)[0].similars.slice(0, 40);
            this.cached_candidates = this.candidates_similars;
          } catch (error) {
            this.candidates_similars = this.cached_candidates;
          }
          if (!this.candidates_similars.length) {
            this.toast.setMessage("Product without similars", 'info');
          }
        }
      );
    }
    if (this.type === "addCandidate") {
      this.productService.getCategories(this.token).then(data => {
        this.categories_data = data.categories;
        this.genders = Object.keys(this.categories_data);
        for (const i of Object.keys(this.categories_data)) {
          this.categories[i] = this.categories_data[i].map(key => ({ "category": key.name, "subcategory": key.subcategories.map(val => (val.name)) }));
          for (const j of Object.keys(this.categories[i])) {
            this.subcategories[i + this.categories[i][j]["category"]] = this.categories[i][j]["subcategory"];
          }
        }
      }
      );
    }
    const options: NgbModalOptions = {
      size: 'lg',
      windowClass: 'animated bounceInUp',
      beforeDismiss: () => {
        return true;
      }
    };
    this.modalService.open(content, options).result.then((result) => {
    }, (reason) => {
      console.log("reason ", reason);
      this.new_products = [];
      this.product_uid = "";
    });
  }

  async onKeydown(event) {
    if (event.key === "Enter") {
      const data = await this.productService.getProduct(this.product_uid, this.token, this.status.active);
      this.candidates_similars = data.results;
    }
  }

  async setPerfectMatch(product) {
    await this.lookService.setPerfectMatch(this.look.id, this.look_product.id, product.uid, this.token);
    const data = await this.lookService.getLookByUid(this.look.uid, this.token);
    const new_look_prd = data.results[0].products.filter(obj => product.uid === obj.match)[0];
    this.look_product = new_look_prd;
    this.recommends[product.uid] = product;
    this.recommends[product.uid].visual_status = this.status.active;
    this.look.products = data.results[0].products;
    this.toast.setMessage("Perfect match set correctly", 'success');
  }

  async listProducts() {
    this.new_products = [];
    this.buttonLoading = true;
    if (this.product_uid) {
      await this.productService.getProduct(this.product_uid, this.token, this.status.active).then(data => {
        if (data.results && data.results.length) {
          this.new_products = data.results;
        }
      }).catch(() => {
        this.toast.setMessage("product not found", 'danger');
      }).finally(() => {
        this.buttonLoading = false;
      });
    } else if (this.textSearch) {
      this.isLoading = true;
      const body = {
        text: this.textSearch,
        gender: this.selectedGender,
        category: this.selectedCategory,
        subcategory: this.selectedSubcategory
      };

      await this.productService
        .getTextSearch(body, this.apikey)
        .then((data) => {
          if (data.results.length) {
            this.new_products = data.results[0].products;
          }
        })
        .catch((error) => {
          this.toast.setMessage(error.statusText, "danger");
          console.log(error);
        })
        .finally(() => (this.buttonLoading = false));
    } else {
      const count_body = {};
      if (this.selectedGender) {
        count_body["gender"] = this.selectedGender;
      }
      if (this.selectedCategory) {
        count_body["category"] = this.selectedCategory;
      }
      if (this.selectedSubcategory) {
        count_body["subcategory"] = this.selectedSubcategory;
      }
      this.productService.getCount(this.selectedGender, this.selectedCategory, this.selectedSubcategory, this.token).then(data => {
        this.product_count = [];
        for (let i = 1; i <= data.count; i++) {
          this.product_count.push(i);
        }
        this.setPage(1);
        this.buttonLoading = false;
      }
      );

    }
  }

  getManyProducts(page_num: string) {
    this.productService.getProductMany(
      this.selectedGender,
      this.selectedCategory,
      this.selectedSubcategory,
      page_num,
      this.limit,
      this.token,
      null).then(data => {
        this.new_products = data.results;
        this.buttonLoading = false;
      }
      );
  }

  selectGender(value) {
    this.selectedGender = value;
    this.selectedCategory = null;
    this.selectedSubcategory = null;
  }

  selectCategory(value) {
    this.selectedCategory = value;
    this.selectedSubcategory = null;
  }

  selectSubcategory(value) {
    this.selectedSubcategory = value;
  }

  setNewCandidate(product) {
    this.selected_new_candidate["uid"] = product.uid;
    this.lookService.addProductLook(this.look.id, product.uid, this.token).subscribe(
      (data: any) => {
        if (data.results && data.results.length) {
          this.toast.setMessage("new candidate added successfully", 'success');
          this.look.products = data.results[0].products;
          for (let index = 0; index < this.look.products.length; index++) {
            const new_match = this.look.products[index].match;
            if (!this.recommends.hasOwnProperty(new_match)) {
              this.recommends[new_match] = product;
              this.recommends[new_match].visual_status = this.status.active;
            }
          }
        } else {
          this.toast.setMessage("unexpected error occured", 'danger');
        }
      }
    );
  }

  setPage(page: number) {
    this.pager = this.pagerService.getPager(this.product_count.length, page);
    this.pagedItems = this.product_count.slice(this.pager.startIndex, this.pager.endIndex + 1);
    this.getManyProducts(this.pager.currentPage);
  }

  clear() {
    this.product_uid = "";
  }

  clearText() {
    this.textSearch = "";
  }
}
