<app-toast [message]="toast.message"></app-toast>
<div class="container my-4">
  <div class="col-md-12">
    <div *ngIf="!isLoading"></div>
    <div class="filter-bar">
      <ul>
        <li>
          <P>find look by uid:</P>
        </li>
        <li>
          <input type="text" class="form-control" placeholder="type product uid" [(ngModel)]='product_uid' name="uid">
        </li>
        <li>
          <button type="submit" class="btn btn-we btn-block" [disabled]="" (click)="getLookByUid(product_uid)">
            <i class="fa fa-search" aria-hidden="true"></i> &nbsp; Find Look</button>
        </li>
        <li>
          <button type="submit" class="btn btn-we btn-block" [disabled]="" (click)="getLookByContainUid(product_uid)">
            <i class="fa" aria-hidden="true"></i> &nbsp; uid Contained Looks</button>
        </li>
        <li>
          <button type="submit" class="btn btn-we btn-block" [disabled]="" (click)="getLooksMany(true)">
            <i class="fa" aria-hidden="true"></i> &nbsp; Show All Looks</button>
        </li>
        <li class="inactive-control">
          <input type="checkbox" checked data-toggle="toggle" [checked]="true"
            (change)="inactiveProductsAction($event)">
          <label class="toggle-button-switch" for="toggle-button-checkbox"> &nbsp;inactives</label>
        </li>
        <li class="more-filters noselect">
          <p (click)="toggleAdvance()"><i class="fa fa-tasks" aria-hidden="true"> &nbsp; more filters</i></p>
        </li>
        <li class="pull-right">
          <button type="submit" class="btn btn-we btn-block" (click)="goToConfig()">Config</button>
        </li>
      </ul>
    </div>
    <div class="filter-bar" [ngStyle]="{'display' : showAdvance ? 'initial' : 'none'}">
      <ul class="advance-filters">
        <li>
          <input class="p-1" [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" placeholder="Annotated from"
            (dateTimeInput)="selectByAnnotation(dt1)">
          <owl-date-time #dt1></owl-date-time>
        </li>
        <li>
          <input class="p-1" [owlDateTime]="dt2" [owlDateTimeTrigger]="dt2" placeholder="Annotated to"
            (dateTimeInput)="selectByAnnotation(dt2)">
          <owl-date-time #dt2></owl-date-time>
        </li>
        <div class="cat-filters">
          <ul class="mt-1">
            <ul>
              <li class="list-name">
                Gender:
              </li>
              <li>
                <select [(ngModel)]="selectedGender" class="form-control" (click)="selectGender(selectedGender)">
                  <option value="">-- select gender --</option>
                  <option *ngFor="let item of genders">{{item}}</option>
                </select>
              </li>
            </ul>
            <ul>
              <li class="list-name">
                Category:
              </li>
              <li>
                <select [(ngModel)]="selectedCategory" class="form-control" (click)="selectCategory(selectedCategory)">
                  <option value="">-- select category --</option>
                  <option *ngFor="let item of categories[selectedGender]"> {{item.category}} </option>
                </select>
              </li>
            </ul>
            <ul>
              <li class="list-name">
                Subcategory:
              </li>
              <li>
                <select [(ngModel)]="selectedSubcategory" class="form-control"
                  (click)="selectSubcategory(selectedSubcategory)">
                  <option value="">-- select subcategory --</option>
                  <option *ngFor="let item of subcategories[selectedGender+selectedCategory]">{{item}}</option>
                </select>
              </li>
            </ul>
            <ul>
              <li class="list-name">
                Generation type:
              </li>
              <li>
                <select [(ngModel)]="selectedGenerationType" class="form-control"
                  (click)="selectGenerationType(selectedGenerationType)">
                  <option value="">-- select generation type --</option>
                  <option *ngFor="let item of default_gen_types">{{item}}</option>
                </select>
              </li>
            </ul>
            <ul>
              <li class="list-name">
                Recommendation type:
              </li>
              <li>
                <select [(ngModel)]="selectedRecommendationType" class="form-control"
                        (click)="selectorRecommendationType(selectedRecommendationType)">
                  <option value="">-- select recommendation type --</option>
                  <option *ngFor="let item of default_recom_types">{{item}}</option>
                </select>
              </li>
            </ul>
            <ul>
              <li class="list-name">
                No categories:
              </li>
              <li>
                <select [(ngModel)]="selectedNoCategory" class="form-control"
                        (click)="selectorNoCategory(selectedNoCategory)"
                        [disabled]="selectedRecommendationType !== 'no'">
                  <option value="">-- select no category --</option>
                  <option *ngFor="let item of default_no_categories">{{item}}</option>
                </select>
              </li>
            </ul>
            <li>
              <button type="submit" class="btn btn-we btn-sm btn-block" (click)="getLooksMany(false)">
                <i class="fa"></i>&nbsp; Filter Looks</button>
            </li>
            <li>
              <button class="btn btn-outline-secondary btn-sm btn-block" (click)="clearFilters()">clear all</button>
            </li>
          </ul>
        </div>
      </ul>
    </div>
  </div>
  <div class="text-center">
    <app-loading [condition]="isLoading"></app-loading>
  </div>
  <div class="row ml-5">
    <div *ngIf="isLoadingCount" class="spinner-border spinner-border-sm mt-1" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <div class="col-md-3">Active looks count: {{look_count.length}} </div>
  </div>
  <div class="col-md-12 scrollCandidates">
    <tr *ngFor="let look of looks">
      <td>
        <div class="card text-white bg-dark mb-3 m-3" style="min-width:16rem; max-width: 18rem; letter-spacing: -1px;">
          <div class="card-header">
            <div class="CardNgDrop">
              <ul>
                <li ngbDropdown class="nav-item noselect">
                  <a ngbDropdownToggle class="nav-link dropdown-toggle" id="navbarDropdownMenuLink"
                    data-toggle="dropdown" aria-expanded="false">
                    <i class="fa fa-bars fa-lg" aria-hidden="true"></i>
                  </a>
                  <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                    <a class="dropdown-item" name="false" (click)="reset_look($event.target.name, look)">Reset
                      Look</a>
                    <a class="dropdown-item" name="true" (click)="reset_look($event.target.name, look)">Reset Look With
                      Priority</a>
                    <a class="dropdown-item" name="searchbyid"
                      (click)="generate_cache($event.target.name, look)">Generate Similars</a>
                    <a class="dropdown-item" name="recommendbyid"
                      (click)="generate_cache($event.target.name, look)">Generate Recommends</a>
                  </div>
                </li>
                <li>
                  <h5>&nbsp;&nbsp; Main Look</h5>
                </li>
              </ul>
            </div>
          </div>
          <img *ngIf="lazyLoad" src="../../assets/lazyLoad.gif" class="card-img-top lazyLoad" alt="lazyLoad" />
          <img [hidden]="lazyLoad" (load)="onLoadLazy()" class="card-img-top" [attr.src]="look.imageUrl"
            alt="Card image cap">
          <button class="btn btn-success btn-large" (click)="validateLook(look)">validate</button>
          <div class="card-body text-center" style="line-height: 70%">
            <div class="card-deck">
              <div class="horizon-card" *ngFor="let alt of look.alternativeImages">
                <img class="card-img-top" [attr.src]="alt" alt="Card image cap" (click)="changeMainImg(alt, look)">
              </div>
            </div>
            <p class="card-text p-1 mb-1 medium"
              [ngStyle]="{'background-color':look.generationType === lookTypes.manual ? 'green': lookTypes.empty}">
              annotation type: {{look.generationType}}</p>
            <p class="card-text small">look id:{{look.id}}</p>
            <p class="card-text small">product uid:{{look.uid}}</p>
            <p class="card-text small">name: {{look.name}}</p>
            <p class="card-text small">gender: {{look.gender}}</p>
            <p class="card-text small">category: {{look.category.name}}</p>
            <p class="card-text small">subcategory: {{look.category.subcategory.name}}</p>
            <p class="card-text small">created at: {{getDate(look.createdAt)}}</p>
            <p class="card-text small">updated at: {{getDate(look.updatedAt)}}</p>
            <p class="card-text small">generated at: {{getDate(look.generatedAt)}}</p>
            <a *ngIf="main_products_of_looks[look.uid]" href="{{main_products_of_looks[look.uid].productUrl}}"
              target="_blank">product link</a>
          </div>
        </div>
      </td>
      <td>
    <tr class="candidates scrollHorizontal">
      <div class="row">
        <td *ngFor="let product of look.products">
          <div class="card text-white bg-dark mb-3 m-3 candidatecard text-center"
            style="min-width: 13rem; max-width: 15rem;">
            <div *ngIf="recommends[product.match] || recommendsNotFound[product.match] " class="card-header text-center">Candidate</div>
            <div *ngIf="recommendsNoCandidates[product.id]" class="card-header text-center">NO Candidate</div>
            <div *ngIf="recommends[product.match]" class="image-component">
              <img *ngIf="lazyLoad" src="../../assets/lazyLoad.gif" class="card-img-top lazyLoad" alt="lazyLoad" />
              <img *ngIf="recommends[product.match]" [hidden]="lazyLoad" (load)="onLoadLazy()" class="card-img-top"
                src="{{recommends[product.match].images[0].imageToProcess || recommends[product.match].images[0].imageUrl}}" alt="Card image cap" />
              <div *ngIf="recommends[product.match]" class="button-layer">
                <app-modal class="expand" [look_product]="recommends[product.match]" [type]='"expand"'>
                </app-modal>
                <div class="low-components">
                  <app-modal [recommends]=recommends [look_product]="product" [look]=look [type]='"updateCandidate"'
                    [token]="token">
                  </app-modal>
                  <button class="btn btn-block btn-danger" (click)="deleteProductLook(look, product)">Delete
                    Candidate</button>
                </div>
              </div>
            </div>
            <div *ngIf="recommendsNoCandidates[product.id]" class="image-component">
              <img *ngIf="lazyLoad" src="../../assets/lazyLoad.gif" class="card-img-top lazyLoad" alt="lazyLoad" />
              <img *ngIf="product.noCategory !== null && product.noCategory !== ''" [hidden]="lazyLoad" (load)="onLoadLazy()" class="card-img-top"
                   src="{{lookNoCategoriesImages.getImageUrl(product.noCategory)}}" alt="Card image cap" />
              <div *ngIf="recommends[product.match]" class="button-layer">
                <app-modal class="expand" [look_product]="recommends[product.match]" [type]='"expand"'>
                </app-modal>
                <div class="low-components">
                  <app-modal [recommends]=recommends [look_product]="product" [look]=look [type]='"updateCandidate"'
                             [token]="token">
                  </app-modal>
                  <button class="btn btn-block btn-danger" (click)="deleteProductLook(look, product)">Delete
                    Candidate</button>
                </div>
              </div>
            </div>

            <div *ngIf="recommends[product.match]" class="card-body" style="line-height: 90%; font-size: 90%;">
              <p class="card-text small">product id:{{recommends[product.match].id}}</p>
              <p class="card-text small">uid:{{recommends[product.match].uid}}</p>
              <p class="card-text small">name: {{recommends[product.match].name}}</p>
              <p class="card-text small">gender: {{recommends[product.match].gender}}</p>
              <p class="card-text small">cat:{{recommends[product.match].category.name}}</p>
              <p class="card-text small">subcat:{{recommends[product.match].category.subcategory.name}}
              </p>
              <div class="form-group">
                <label for="recommendTypeSelector" class="small">Recommendation type:</label>
                <select id="recommendTypeSelector" class="form-control" [(ngModel)]="product.recommendationType"
                        (change)="selectRecommendationType(look, product)">
                  <option value="">Select rec. type</option>
                  <option *ngFor="let item of default_recom_types">{{item}}</option>
                </select>
              </div>
              <div class="form-group">
                <label for="noRecommendTypeSelector" class="small">No reason:</label>
                <select id="noRecommendTypeSelector" class="form-control" [(ngModel)]="product.noCategory"
                        (change)="selectRecommendationType(look, product)"
                        [disabled]="product.recommendationType !== 'no'">
                  <option value="">Select no reason</option>
                  <option *ngFor="let item of default_no_categories">{{item}}</option>
                </select>
              </div>
              <p class="noselect p-1"
                [ngStyle]="{'background-color':recommends[product.match].visual_status === 'active' ? 'green' : 'red'}">
                {{recommends[product.match].visual_status}}</p>
            </div>
            <div *ngIf="recommendsNotFound[product.match]" class="card-footer"
              style="line-height: 90%; font-size: 90%;">
              <p class="p-1 m-1 rounded" [ngStyle]="{'background-color': 'red'}"> product not found</p>
              <p class="p-1 m-1 rounded" [ngStyle]="{'background-color': 'red'}">{{recommendsNotFound[product.match]}}
              </p>
              <button class="btn btn-block btn-danger" (click)="deleteProductLook(look, product)">Delete
                Candidate</button>
            </div>
            <div *ngIf="recommendsNoCandidates[product.id]" class="card-footer"
                 style="line-height: 90%; font-size: 90%;">
              <p class="p-1 m-1 rounded" [ngStyle]="{'background-color': 'green'}">Reason: {{product.noCategory}}
              </p>
              <div class="form-group mt-2">
                <label for="noCandidatesRecommendTypeSelector" class="small">Change the no reason:</label>
                <select id="noCandidatesRecommendTypeSelector" class="form-control" [(ngModel)]="product.noCategory"
                        (change)="selectRecommendationType(look, product)"
                        [disabled]="product.recommendationType !== 'no'">
                  <option value="">Select no reason</option>
                  <option *ngFor="let item of default_no_categories">{{item}}</option>
                </select>
              </div>
              <button class="btn btn-block btn-danger" (click)="deleteProductLook(look, product)">Delete
                Candidate</button>
            </div>
          </div>
        </td>
        <td>
          <div class="card text-white bg-dark mb-3 m-3 addonecard" style="min-width: 13rem; max-width: 18rem;">
            <div class="card-header text-center">Add Candidate</div>
            <app-modal [recommends]=recommends [look_product]="product" [look]=look [type]='"addCandidate"'
              [token]="token" [selectedGender]="selectedGender" [apikey]="apikey">
            </app-modal>
            <div class="card-body" style="line-height: 90%; font-size: 90%;">
            </div>
          </div>
          <div class="card text-white bg-dark mb-3 m-3 addonecard" style="min-width: 13rem; max-width: 18rem;">
            <div class="card-header text-center">Add No Candidate</div>
            <div class="card-body" style="line-height: 90%; font-size: 90%;">
              <label for="newNoCandidateSelector" class="small">Select the no reason:</label>
              <select [(ngModel)]="selectedNoCategoryToAdd"
                      id="newNoCandidateSelector"
                      class="form-control" (click)="selectNoCategoryToAdd(selectedNoCategoryToAdd)">
                <option *ngFor="let item of default_no_categories">{{item}}</option>
              </select>
              <button class="btn btn-block btn-info mt-3"
                      [disabled]="selectedNoCategoryToAdd == null || selectedNoCategoryToAdd == ''"
                      (click)="addNoCandidate(look)">Add</button>
            </div>
          </div>
        </td>
        <td *ngFor="let product of preSelectedProducts[look.id]">
          <div class="card text-white bg-info mb-3 m-3 candidatecard text-center" style="min-width: 13rem; max-width: 15rem;">
            <div class="card-header text-center">Preselected</div>
            <div class="image-component">
              <img *ngIf="lazyLoad" src="../../assets/lazyLoad.gif" class="card-img-top lazyLoad" alt="lazyLoad" />
              <img *ngIf="product.uid" [hidden]="lazyLoad" (load)="onLoadLazy()" class="card-img-top"
                src="{{product.images[0].imageToProcess ? product.images[0].imageToProcess : product.images[0].imageUrl}}" alt="Card image cap" />
              <div *ngIf="product.uid" class="button-layer">
                <app-modal class="expand" [look_product]="product" [type]='"expand"'>
                </app-modal>
              </div>
            </div>
            <div *ngIf="product.uid" class="card-body" style="line-height: 90%; font-size: 90%;">
              <p class="card-text small">product id:{{product.id}}</p>
              <p class="card-text small">uid:{{product.uid}}</p>
              <p class="card-text small">name: {{product.name}}</p>
              <p class="card-text small">gender: {{product.gender}}</p>
              <p class="card-text small">cat:{{product.category.name}}</p>
              <p class="card-text small">subcat:{{product.category.subcategory.name}}</p>
            </div>
          </div>
        </td>
      </div>
      <div class="candidate-line"></div>
    </tr>
    </td>
    </tr>
  </div>
  <div class="pagination noselect" [hidden]="hidePagination">
    <div class="text-center">
      <ul>
        <li>
          <a (click)="getPreviousBatch()">Previous</a>
        </li>
        <li>
          <a (click)="getNextBatch()">Next</a>
        </li>
      </ul>
      <ul>
        <li>
          {{look_count.length > 10 && look_count.length > nextTokenIndexKey*10 ? nextTokenIndexKey*10: look_count.length}}/{{look_count.length}}
        </li>
      </ul>
    </div>
  </div>
</div>
