export class LookGenerationTypes {
    constructor(
        public manual: string = "manual",
        public automatic: string = "automatic",
        public random: string = "random",
        public styleAdvisor: string = "styleAdvisor",
        public styleAdvisorWithManual: string = "styleAdvisorWithManual",
        public empty: string = "") { }
}

export class LookRecommendationTypes {
    constructor(
        public perfectMatch: string = "perfect_match",
        public similar: string = "similar",
        public fitb: string = "fitb",
        public maybe: string = "maybe",
        public no: string = "no") { }
}

export class LookNoCategories {
    constructor(
        public outwear: string = "outwear",
        public top: string = "top",
        public bottom: string = "bottom",
        public onePiece: string = "one_piece",
        public shoes: string = "shoes",
        public bag: string = "bag",
        public accessories: string = "accessories") { }
}

export class LookNoCategoriesImages {
    constructor(
        public outwear: string = "https://we-import-pipeline.s3.eu-west-1.amazonaws.com/fake-products/9999OUTWEAR.png",
        public top: string = "https://we-import-pipeline.s3.eu-west-1.amazonaws.com/fake-products/9999TOP.jpg",
        public bottom: string = "https://we-import-pipeline.s3.eu-west-1.amazonaws.com/fake-products/9999BOTTOM.jpg",
        public onepiece: string = "https://we-import-pipeline.s3.eu-west-1.amazonaws.com/fake-products/9999ONEPIECE.png",
        public shoes: string = "https://we-import-pipeline.s3.eu-west-1.amazonaws.com/fake-products/9999SHOES.png",
        public bag: string = "https://we-import-pipeline.s3.eu-west-1.amazonaws.com/fake-products/9999BAG.png",
        public accessories: string = "https://we-import-pipeline.s3.eu-west-1.amazonaws.com/fake-products/9999ACCESSORIES.png") { }

    public getImageUrl(noCategory: string): string {
        if ( noCategory !== undefined && noCategory !== null && noCategory !== "" ) {
          const propertyName = noCategory.replace(/[^a-zA-Z]/g, "").toLowerCase();
          return this[propertyName];
        } else {
          return "";
        }
    }
}

