import { Component, OnInit } from "@angular/core";
import { ToastComponent } from "../../shared/toast/toast.component";
import { ModalComponent } from "../../shared/modal/modal.component";
import { ProductService } from "../../services/ProductService";
import { Router } from "@angular/router";
import { AuthService } from "../../services/AuthService";
import { ProductStatus } from "../../shared/models/status.model";
import {
  NgbModalConfig,
  NgbModal,
  NgbModalOptions,
} from "@ng-bootstrap/ng-bootstrap";
import * as _ from "lodash";
import { ElementRef, QueryList, ViewChild, ViewChildren } from "@angular/core";

@Component({
  selector: "app-product",
  templateUrl: "./product.component.html",
  styleUrls: ["./product.component.scss"],
})
export class VisionProductComponent implements OnInit {
  @ViewChild("canvas") canvas: ElementRef;
  @ViewChildren("canvas", { read: ElementRef }) canvasElements: QueryList<
    ElementRef
  >;

  looks = [];
  token: string;
  isLoading = true;
  accountData: Object = {};
  selectedGender = "";
  selectedCategory = "";
  selectedSubcategory = "";
  categories = [];
  subcategories = [];
  products = [];
  objectKeys = Object.keys;
  objectValues = Object.values;
  status = new ProductStatus();
  selectedProducts = [];
  product: Object = {};
  newGender = null;
  newCategory = null;
  newSubcategory = null;
  similars = [];
  checkCat = false;
  checkSubcat = false;
  currentPage = 1;
  productsCount = 0;
  productLimit = 40;
  apikey: string;

  constructor(
    private auth: AuthService,
    private productService: ProductService,
    private router: Router,
    public toast: ToastComponent,
    public modal: ModalComponent,
    private modalService: NgbModal
  ) {}

  async ngOnInit() {
    this.token = localStorage.getItem("access_token");
    this.auth.user_id = localStorage.getItem("user_id");
    this.apikey = localStorage.getItem("apikey");
    if (this.auth.isAuthenticated()) {
      this.auth.loggedIn = true;
      this.router.navigate(["/visionproducts"]);
      await this.getCategoryData();
    } else {
      this.auth.loggedIn = false;
      this.router.navigate(["/account/login"]);
    }
  }

  async Load() {
    this.isLoading = true;
    this.accountData = {};
    this.selectedGender = "";
    this.selectedCategory = "";
    this.selectedSubcategory = "";
    this.categories = [];
    this.subcategories = [];
    this.products = [];
    await this.getCategoryData();
  }

  async getCount() {
    await this.productService
      .getCount(
        this.selectedGender,
        this.selectedCategory,
        this.selectedSubcategory,
        this.token
      )
      .then((data) => {
        this.productsCount = data.count;
      });
  }

  checkLazyLoad(product) {
    product.lazyLoad = true;
  }
  async getCategoryData() {
    await this.productService.getCategories(this.token).then((data) => {
      this.accountData = data.categories;
      this.isLoading = false;
    });
    await this.getCount();
  }

  /**
   * @ngdoc function
   * @name compare
   * @description Compare names and order the objects in list
   * @returns {*} sorted List objects
   */
  compare(a, b): any {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();

    let comparison = 0;
    if (nameA > nameB) {
      comparison = 1;
    } else if (nameA < nameB) {
      comparison = -1;
    }
    return comparison;
  }

  order(unOrderedList) {
    return unOrderedList.sort(this.compare);
  }

  async onPageChange(page, productLength) {
    if (productLength > 1) {
      await this.getProducts(page);
    }
  }

  async getProducts(page_num: string) {
    this.isLoading = true;
    this.productService
      .getProductMany(
        this.selectedGender,
        this.selectedCategory,
        this.selectedSubcategory,
        page_num,
        this.productLimit.toString(),
        this.token,
        null
      )
      .then((data) => {
        this.products = data.results;
        this.isLoading = false;
      });
    await this.getCount();
  }

  async onChange(event: any) {
    const value = event.target.value;
    if (value) {
      switch (true) {
        case event.target.name === "gender":
          this.selectedGender = value;
          this.selectedCategory = "";
          this.selectedSubcategory = "";
          this.subcategories = [];
          if (value !== "defaultgender") {
            const categoryObjects: { name: string }[] = Object.values(
              this.accountData[value]
            );
            this.categories = categoryObjects.map((x) => x.name).sort();
          } else {
            this.selectedGender = "";
            this.categories = [];
          }
          break;
        case event.target.name === "category":
          this.selectedSubcategory = "";
          if (value !== "defaultcategory") {
            const gender: string = this.selectedGender;
            const subcategoryObject: {
              subcategories: [];
              name: string;
            }[] = Object.values(this.accountData[gender]);
            this.subcategories = subcategoryObject
              .filter((x) => x.name === value)
              .map((sub) => sub.subcategories)[0];
            this.subcategories = this.subcategories.map((x) => x.name).sort();
          } else {
            this.subcategories = [];
            this.selectedCategory = "";
          }
          break;
        case event.target.name === "subcategory":
          if (value === "defaultsubcategory") {
            this.selectedSubcategory = "";
          }
          break;
      }
    }
    await this.getProducts("1");
  }

  async searchOne(event) {
    const uid = event.target.value;
    if (uid) {
      try {
        const data = await this.productService.getProduct(
          uid,
          this.token,
          this.status.active
        );
        this.products = data.results;
        this.productsCount = data.results.length;
      } catch (error) {
        this.toast.setMessage("Product " + error.statusText, "danger");
      }
    }
  }

  async textSearch(event) {
    const text = event.target.value;
    if (text) {
      this.isLoading = true;
      const body = {
        text: text,
        gender: this.selectedGender,
        category: this.selectedCategory,
        subcategory: this.selectedSubcategory
      };

      await this.productService
        .getTextSearch(body, this.apikey)
        .then((data) => {
          if (data.results.length) {
            this.products = data.results[0].products;
            this.productsCount = data.results.length;
          }
        })
        .catch((error) => {
          this.toast.setMessage(error.statusText, "danger");
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
      this.toast.setMessage("Text search:  " + text, "info");
    }
  }

  selectProducts(event, product) {
    // const ctrlKey = event.ctrlKey;
    if (this.selectedProducts.includes(product)) {
      this.selectedProducts = this.selectedProducts.filter(
        (obj) => obj !== product
      );
    } else {
      this.selectedProducts.push(product);
    }
  }

  isSelected(product) {
    return this.selectedProducts.includes(product);
  }

  selectAll() {
    if (this.selectedProducts.length < this.products.length) {
      this.selectedProducts = Object.assign([], this.products);
    } else {
      this.selectedProducts = Object.assign([], []);
    }
  }

  infoProduct(content, product) {
    this.product = Object.assign({}, product);
    const options: NgbModalOptions = {
      size: "lg",
      windowClass: "animated bounceInUp",
      beforeDismiss: () => {
        return true;
      },
    };
    this.modalService.open(content, options).result.then(
      (result) => {},
      (reason) => {
        console.log("reason ", reason);
      }
    );
  }

  async categoryChangeModal(content) {
    const options: NgbModalOptions = {
      size: "lg",
      windowClass: "animated bounceInUp",
      beforeDismiss: () => {
        return true;
      },
    };
    this.modalService.open(content, options).result.then(
      (result) => {
        const obj = {
          newGender: this.newGender,
          newCategory: this.newCategory,
          newSubcategory: this.newSubcategory,
        };
        const promises = [];
        this.isLoading = true;
        for (const key of Object.keys(this.selectedProducts)) {
          const uid = this.selectedProducts[key].uid;
          promises.push(
            this.productService.changeProductCategory(uid, obj, this.token)
          );
        }
        this.resetNewValues();
        Promise.all(promises).then(() => {
          this.getProducts(this.currentPage.toString());
          this.selectedProducts = [];
          this.isLoading = false;
        });
      },
      (reason) => {
        console.log("reason ", reason);
      }
    );
  }

  async getSimilars(content, product) {
    this.product = Object.assign({}, product);
    const options: NgbModalOptions = {
      size: "lg",
      windowClass: "animated bounceInUp",
      beforeDismiss: () => {
        return true;
      },
    };
    this.modalService.open(content, options).result.then(
      (result) => {},
      (reason) => {
        this.similars = [];
        console.log("reason ", reason);
      }
    );
    await this.similarCall();
  }

  async similarCall() {
    const product: any = this.product;
    this.isLoading = true;
    const body = {
      uid: product.uid,
      gender: product.gender,
      maxNumResults: 10,
    };
    if (this.checkCat) {
      body["category"] = product.category.name;
      if (this.checkSubcat) {
        body["subcategory"] = product.category.subcategory.name;
      }
    }
    await this.productService
      .getSimilars(product.uid, body, this.apikey)
      .then((data) => {
        if (data.results.length) {
          this.similars = data.results[0].products;
        }
      })
      .catch((error) => {
        this.toast.setMessage(error.statusText, "danger");
        console.log(error);
      })
      .finally(() => (this.isLoading = false));
  }

  async updateProduct(product) {
    const updatedProduct = Object.assign({}, product);
    delete updatedProduct["lazyLoad"];
    updatedProduct.gender = this.newGender.trim() || product.gender;
    updatedProduct.category.name = this.newCategory.trim() || product.category.name;
    updatedProduct.category.subcategory.name =
      this.newSubcategory.trim() || product.category.subcategory.name;
    const data = await this.productService.insertOrUpdate(
      updatedProduct,
      this.token
    );
    if (data.results.length) {
      this.toast.setMessage("Sucessfully updated", "success");
    } else {
      this.toast.setMessage("Error occured: " + data.errors[0], "danger");
    }
    this.resetNewValues();
    await this.getProducts(this.currentPage.toString());
  }

  async deleteSelectedProducts() {
    if (this.selectedProducts.length) {
      confirm("Are you sure you want to delete those selected products?");
      {
        const promises = [];
        this.isLoading = true;
        for (const key of Object.keys(this.selectedProducts)) {
          const uid = this.selectedProducts[key].uid;
          promises.push(this.productService.deleteProduct(uid, this.token));
        }
        Promise.all(promises);
        this.delay(2000);
        this.selectedProducts = [];
        this.isLoading = false;
        await this.getProducts(this.currentPage.toString());
      }
    }
  }

  goToConfig() {
    this.router.navigate(["/styleadvisor/configs"]);
  }

  getDate(date: string) {
    if (date) {
      return new Date(date).toLocaleString();
    } else {
      return "";
    }
  }

  showCrop() {
    this.canvasElements.toArray().forEach((item: any) => {
      const imgCanvas = item.nativeElement.getContext("2d");
      const product = this.products.filter(
        (x) => x.uid === imgCanvas.canvas.title
      )[0];
      const image = new Image();
      const bbox = product["images"][0].bbox;
      if (bbox) {
        imgCanvas.canvas.width = 200;
        imgCanvas.canvas.height = 300;
        imgCanvas.lineWidth = 2;
        imgCanvas.strokeStyle = "#0cacc4";
        image.onload = () => {
          imgCanvas.drawImage(image, 0, 0, 200, 300);
          imgCanvas.rect(
            (bbox.x * 200) / image.width,
            (bbox.y * 300) / image.height,
            (bbox.w * 200) / image.width,
            (bbox.h * 300) / image.height
          );
          imgCanvas.stroke();
        };
        image.src = product["images"][0]["imageUrl"];
      }
    });
  }

  filterSubcategories(list, catName) {
    try {
      const selectedCat = list.filter((x) => x.name === catName);
      return selectedCat[0].subcategories.map((x) => x.name).sort();
    } catch (error) {}
  }

  getValue(object, value) {
    return object[value];
  }

  resetNewValues() {
    this.newGender = "";
    this.newCategory = "";
    this.newSubcategory = "";
  }

  async delay(ms: number) {
    await new Promise((resolve) => setTimeout(() => resolve(), ms)).then(() =>
      console.log("fired")
    );
  }
}
