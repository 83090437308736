var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from "@angular/core";
import { ToastComponent } from "../../shared/toast/toast.component";
import { ModalComponent } from "../../shared/modal/modal.component";
import { ProductService } from "../../services/ProductService";
import { Router } from "@angular/router";
import { AuthService } from "../../services/AuthService";
import { ProductStatus } from "../../shared/models/status.model";
import { NgbModal, NgbModalOptions, } from "@ng-bootstrap/ng-bootstrap";
import { ElementRef, QueryList } from "@angular/core";
var VisionProductComponent = /** @class */ (function () {
    function VisionProductComponent(auth, productService, router, toast, modal, modalService) {
        this.auth = auth;
        this.productService = productService;
        this.router = router;
        this.toast = toast;
        this.modal = modal;
        this.modalService = modalService;
        this.looks = [];
        this.isLoading = true;
        this.accountData = {};
        this.selectedGender = "";
        this.selectedCategory = "";
        this.selectedSubcategory = "";
        this.categories = [];
        this.subcategories = [];
        this.products = [];
        this.objectKeys = Object.keys;
        this.objectValues = Object.values;
        this.status = new ProductStatus();
        this.selectedProducts = [];
        this.product = {};
        this.newGender = null;
        this.newCategory = null;
        this.newSubcategory = null;
        this.similars = [];
        this.checkCat = false;
        this.checkSubcat = false;
        this.currentPage = 1;
        this.productsCount = 0;
        this.productLimit = 40;
    }
    VisionProductComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.token = localStorage.getItem("access_token");
                        this.auth.user_id = localStorage.getItem("user_id");
                        this.apikey = localStorage.getItem("apikey");
                        if (!this.auth.isAuthenticated()) return [3 /*break*/, 2];
                        this.auth.loggedIn = true;
                        this.router.navigate(["/visionproducts"]);
                        return [4 /*yield*/, this.getCategoryData()];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        this.auth.loggedIn = false;
                        this.router.navigate(["/account/login"]);
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    VisionProductComponent.prototype.Load = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isLoading = true;
                        this.accountData = {};
                        this.selectedGender = "";
                        this.selectedCategory = "";
                        this.selectedSubcategory = "";
                        this.categories = [];
                        this.subcategories = [];
                        this.products = [];
                        return [4 /*yield*/, this.getCategoryData()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    VisionProductComponent.prototype.getCount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.productService
                            .getCount(this.selectedGender, this.selectedCategory, this.selectedSubcategory, this.token)
                            .then(function (data) {
                            _this.productsCount = data.count;
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    VisionProductComponent.prototype.checkLazyLoad = function (product) {
        product.lazyLoad = true;
    };
    VisionProductComponent.prototype.getCategoryData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.productService.getCategories(this.token).then(function (data) {
                            _this.accountData = data.categories;
                            _this.isLoading = false;
                        })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.getCount()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * @ngdoc function
     * @name compare
     * @description Compare names and order the objects in list
     * @returns {*} sorted List objects
     */
    VisionProductComponent.prototype.compare = function (a, b) {
        var nameA = a.name.toUpperCase();
        var nameB = b.name.toUpperCase();
        var comparison = 0;
        if (nameA > nameB) {
            comparison = 1;
        }
        else if (nameA < nameB) {
            comparison = -1;
        }
        return comparison;
    };
    VisionProductComponent.prototype.order = function (unOrderedList) {
        return unOrderedList.sort(this.compare);
    };
    VisionProductComponent.prototype.onPageChange = function (page, productLength) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(productLength > 1)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.getProducts(page)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    VisionProductComponent.prototype.getProducts = function (page_num) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isLoading = true;
                        this.productService
                            .getProductMany(this.selectedGender, this.selectedCategory, this.selectedSubcategory, page_num, this.productLimit.toString(), this.token, null)
                            .then(function (data) {
                            _this.products = data.results;
                            _this.isLoading = false;
                        });
                        return [4 /*yield*/, this.getCount()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    VisionProductComponent.prototype.onChange = function (event) {
        return __awaiter(this, void 0, void 0, function () {
            var value, categoryObjects, gender, subcategoryObject;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        value = event.target.value;
                        if (value) {
                            switch (true) {
                                case event.target.name === "gender":
                                    this.selectedGender = value;
                                    this.selectedCategory = "";
                                    this.selectedSubcategory = "";
                                    this.subcategories = [];
                                    if (value !== "defaultgender") {
                                        categoryObjects = Object.values(this.accountData[value]);
                                        this.categories = categoryObjects.map(function (x) { return x.name; }).sort();
                                    }
                                    else {
                                        this.selectedGender = "";
                                        this.categories = [];
                                    }
                                    break;
                                case event.target.name === "category":
                                    this.selectedSubcategory = "";
                                    if (value !== "defaultcategory") {
                                        gender = this.selectedGender;
                                        subcategoryObject = Object.values(this.accountData[gender]);
                                        this.subcategories = subcategoryObject
                                            .filter(function (x) { return x.name === value; })
                                            .map(function (sub) { return sub.subcategories; })[0];
                                        this.subcategories = this.subcategories.map(function (x) { return x.name; }).sort();
                                    }
                                    else {
                                        this.subcategories = [];
                                        this.selectedCategory = "";
                                    }
                                    break;
                                case event.target.name === "subcategory":
                                    if (value === "defaultsubcategory") {
                                        this.selectedSubcategory = "";
                                    }
                                    break;
                            }
                        }
                        return [4 /*yield*/, this.getProducts("1")];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    VisionProductComponent.prototype.searchOne = function (event) {
        return __awaiter(this, void 0, void 0, function () {
            var uid, data, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        uid = event.target.value;
                        if (!uid) return [3 /*break*/, 4];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.productService.getProduct(uid, this.token, this.status.active)];
                    case 2:
                        data = _a.sent();
                        this.products = data.results;
                        this.productsCount = data.results.length;
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        this.toast.setMessage("Product " + error_1.statusText, "danger");
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    VisionProductComponent.prototype.textSearch = function (event) {
        return __awaiter(this, void 0, void 0, function () {
            var text, body;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        text = event.target.value;
                        if (!text) return [3 /*break*/, 2];
                        this.isLoading = true;
                        body = {
                            text: text,
                            gender: this.selectedGender,
                            category: this.selectedCategory,
                            subcategory: this.selectedSubcategory
                        };
                        return [4 /*yield*/, this.productService
                                .getTextSearch(body, this.apikey)
                                .then(function (data) {
                                if (data.results.length) {
                                    _this.products = data.results[0].products;
                                    _this.productsCount = data.results.length;
                                }
                            })
                                .catch(function (error) {
                                _this.toast.setMessage(error.statusText, "danger");
                                console.log(error);
                            })
                                .finally(function () { return (_this.isLoading = false); })];
                    case 1:
                        _a.sent();
                        this.toast.setMessage("Text search:  " + text, "info");
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    VisionProductComponent.prototype.selectProducts = function (event, product) {
        // const ctrlKey = event.ctrlKey;
        if (this.selectedProducts.includes(product)) {
            this.selectedProducts = this.selectedProducts.filter(function (obj) { return obj !== product; });
        }
        else {
            this.selectedProducts.push(product);
        }
    };
    VisionProductComponent.prototype.isSelected = function (product) {
        return this.selectedProducts.includes(product);
    };
    VisionProductComponent.prototype.selectAll = function () {
        if (this.selectedProducts.length < this.products.length) {
            this.selectedProducts = Object.assign([], this.products);
        }
        else {
            this.selectedProducts = Object.assign([], []);
        }
    };
    VisionProductComponent.prototype.infoProduct = function (content, product) {
        this.product = Object.assign({}, product);
        var options = {
            size: "lg",
            windowClass: "animated bounceInUp",
            beforeDismiss: function () {
                return true;
            },
        };
        this.modalService.open(content, options).result.then(function (result) { }, function (reason) {
            console.log("reason ", reason);
        });
    };
    VisionProductComponent.prototype.categoryChangeModal = function (content) {
        return __awaiter(this, void 0, void 0, function () {
            var options;
            var _this = this;
            return __generator(this, function (_a) {
                options = {
                    size: "lg",
                    windowClass: "animated bounceInUp",
                    beforeDismiss: function () {
                        return true;
                    },
                };
                this.modalService.open(content, options).result.then(function (result) {
                    var obj = {
                        newGender: _this.newGender,
                        newCategory: _this.newCategory,
                        newSubcategory: _this.newSubcategory,
                    };
                    var promises = [];
                    _this.isLoading = true;
                    for (var _i = 0, _a = Object.keys(_this.selectedProducts); _i < _a.length; _i++) {
                        var key = _a[_i];
                        var uid = _this.selectedProducts[key].uid;
                        promises.push(_this.productService.changeProductCategory(uid, obj, _this.token));
                    }
                    _this.resetNewValues();
                    Promise.all(promises).then(function () {
                        _this.getProducts(_this.currentPage.toString());
                        _this.selectedProducts = [];
                        _this.isLoading = false;
                    });
                }, function (reason) {
                    console.log("reason ", reason);
                });
                return [2 /*return*/];
            });
        });
    };
    VisionProductComponent.prototype.getSimilars = function (content, product) {
        return __awaiter(this, void 0, void 0, function () {
            var options;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.product = Object.assign({}, product);
                        options = {
                            size: "lg",
                            windowClass: "animated bounceInUp",
                            beforeDismiss: function () {
                                return true;
                            },
                        };
                        this.modalService.open(content, options).result.then(function (result) { }, function (reason) {
                            _this.similars = [];
                            console.log("reason ", reason);
                        });
                        return [4 /*yield*/, this.similarCall()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    VisionProductComponent.prototype.similarCall = function () {
        return __awaiter(this, void 0, void 0, function () {
            var product, body;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        product = this.product;
                        this.isLoading = true;
                        body = {
                            uid: product.uid,
                            gender: product.gender,
                            maxNumResults: 10,
                        };
                        if (this.checkCat) {
                            body["category"] = product.category.name;
                            if (this.checkSubcat) {
                                body["subcategory"] = product.category.subcategory.name;
                            }
                        }
                        return [4 /*yield*/, this.productService
                                .getSimilars(product.uid, body, this.apikey)
                                .then(function (data) {
                                if (data.results.length) {
                                    _this.similars = data.results[0].products;
                                }
                            })
                                .catch(function (error) {
                                _this.toast.setMessage(error.statusText, "danger");
                                console.log(error);
                            })
                                .finally(function () { return (_this.isLoading = false); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    VisionProductComponent.prototype.updateProduct = function (product) {
        return __awaiter(this, void 0, void 0, function () {
            var updatedProduct, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        updatedProduct = Object.assign({}, product);
                        delete updatedProduct["lazyLoad"];
                        updatedProduct.gender = this.newGender.trim() || product.gender;
                        updatedProduct.category.name = this.newCategory.trim() || product.category.name;
                        updatedProduct.category.subcategory.name =
                            this.newSubcategory.trim() || product.category.subcategory.name;
                        return [4 /*yield*/, this.productService.insertOrUpdate(updatedProduct, this.token)];
                    case 1:
                        data = _a.sent();
                        if (data.results.length) {
                            this.toast.setMessage("Sucessfully updated", "success");
                        }
                        else {
                            this.toast.setMessage("Error occured: " + data.errors[0], "danger");
                        }
                        this.resetNewValues();
                        return [4 /*yield*/, this.getProducts(this.currentPage.toString())];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    VisionProductComponent.prototype.deleteSelectedProducts = function () {
        return __awaiter(this, void 0, void 0, function () {
            var promises, _i, _a, key, uid;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!this.selectedProducts.length) return [3 /*break*/, 2];
                        confirm("Are you sure you want to delete those selected products?");
                        promises = [];
                        this.isLoading = true;
                        for (_i = 0, _a = Object.keys(this.selectedProducts); _i < _a.length; _i++) {
                            key = _a[_i];
                            uid = this.selectedProducts[key].uid;
                            promises.push(this.productService.deleteProduct(uid, this.token));
                        }
                        Promise.all(promises);
                        this.delay(2000);
                        this.selectedProducts = [];
                        this.isLoading = false;
                        return [4 /*yield*/, this.getProducts(this.currentPage.toString())];
                    case 1:
                        _b.sent();
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    VisionProductComponent.prototype.goToConfig = function () {
        this.router.navigate(["/styleadvisor/configs"]);
    };
    VisionProductComponent.prototype.getDate = function (date) {
        if (date) {
            return new Date(date).toLocaleString();
        }
        else {
            return "";
        }
    };
    VisionProductComponent.prototype.showCrop = function () {
        var _this = this;
        this.canvasElements.toArray().forEach(function (item) {
            var imgCanvas = item.nativeElement.getContext("2d");
            var product = _this.products.filter(function (x) { return x.uid === imgCanvas.canvas.title; })[0];
            var image = new Image();
            var bbox = product["images"][0].bbox;
            if (bbox) {
                imgCanvas.canvas.width = 200;
                imgCanvas.canvas.height = 300;
                imgCanvas.lineWidth = 2;
                imgCanvas.strokeStyle = "#0cacc4";
                image.onload = function () {
                    imgCanvas.drawImage(image, 0, 0, 200, 300);
                    imgCanvas.rect((bbox.x * 200) / image.width, (bbox.y * 300) / image.height, (bbox.w * 200) / image.width, (bbox.h * 300) / image.height);
                    imgCanvas.stroke();
                };
                image.src = product["images"][0]["imageUrl"];
            }
        });
    };
    VisionProductComponent.prototype.filterSubcategories = function (list, catName) {
        try {
            var selectedCat = list.filter(function (x) { return x.name === catName; });
            return selectedCat[0].subcategories.map(function (x) { return x.name; }).sort();
        }
        catch (error) { }
    };
    VisionProductComponent.prototype.getValue = function (object, value) {
        return object[value];
    };
    VisionProductComponent.prototype.resetNewValues = function () {
        this.newGender = "";
        this.newCategory = "";
        this.newSubcategory = "";
    };
    VisionProductComponent.prototype.delay = function (ms) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, new Promise(function (resolve) { return setTimeout(function () { return resolve(); }, ms); }).then(function () {
                            return console.log("fired");
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return VisionProductComponent;
}());
export { VisionProductComponent };
