import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';


@Injectable()
export class LookService {
  apiURL: string = environment.apiURL;
  apiURLSTAGING: string = environment.apiURLSTAGING;
  apiURLMapping: string = environment.apiURLMapping;
  constructor(private http: HttpClient) {
  }

  getLookByUid(uid: string, token: string): Promise<any> {
    return this.http.get(`${this.apiURL}/v2/look/uid/${uid}`, { headers: this.getHeaders(token) }).toPromise();
  }

  // TODO get look count by categorization
  getCountLooks(gender: any,
    category: any,
    subcategory: any,
    selectedGenerationType: any,
    selectedRecommendationType: any,
    selectedNoCategory: any,
    startAt: any,
    endAt: any,
    NextToken: any,
    token: string) {
    const params = this.generateQueryString(gender, category, subcategory, selectedGenerationType, selectedRecommendationType, selectedNoCategory, startAt, endAt, NextToken);
    return this.http.get(`${this.apiURL}/v2/look/count?status=ACTIVE`, { headers: this.getHeaders(token), params: params });
  }

  getLooksMany(gender: any,
    category: any,
    subcategory: any,
    selectedGenerationType: any,
    selectedRecommendationType: any,
    selectedNoCategory: any,
    startAt: any,
    endAt: any,
    NextToken: any,
    token: string): Promise<any> {
    const params = this.generateQueryString(gender, category, subcategory, selectedGenerationType, selectedRecommendationType, selectedNoCategory, startAt, endAt, NextToken);
    return this.http.get(`${this.apiURL}/v2/look?status=ACTIVE&limit=10`, { headers: this.getHeaders(token), params: params }).toPromise();
  }

  getLooksManyV2(gender: any,
               category: any,
               subcategory: any,
               selectedGenerationType: any,
               selectedRecommendationType: any,
               selectedNoCategory: any,
               startAt: any,
               endAt: any,
               NextToken: any,
                 token: string): Promise<any> {
    const params = this.generateQueryString(gender, category, subcategory, selectedGenerationType, selectedRecommendationType, selectedNoCategory, startAt, endAt, NextToken);
    return this.http.get(`${this.apiURL}/v2/look/listActiveLooks?limit=10`, { headers: this.getHeaders(token), params: params }).toPromise();
  }

  countLooksManyV2(gender: any,
               category: any,
               subcategory: any,
               selectedGenerationType: any,
               selectedRecommendationType: any,
               selectedNoCategory: any,
               startAt: any,
               endAt: any,
                 token: string): Promise<any> {
    const params = this.generateQueryString(gender, category, subcategory, selectedGenerationType, selectedRecommendationType, selectedNoCategory, startAt, endAt, null);
    return this.http.get(`${this.apiURL}/v2/look/countActiveLooks`, { headers: this.getHeaders(token), params: params }).toPromise();
  }

  getLookWithSimilars(look_id: string, token: string) {
    return this.http.get(`${this.apiURL}/v2/look/${look_id}/withSimilars/`, { headers: this.getHeaders(token) });
  }

  setPerfectMatch(look_id: string, look_product_id: string, uid: string, token: string): Promise<any> {
    const body = {
      "productUid": uid
    };
    return this.http.put(`${this.apiURL}/v2/look/${look_id}/productLook/${look_product_id}/setPerfectMatch`, body, { headers: this.getHeaders(token) }).toPromise();
  }

  deleteProductLook(look_id: string, look_product_id: string, token: string) {
    const body = {
      "productLookIds": [look_product_id]
    };
    return this.http.put(`${this.apiURL}/v2/look/${look_id}/deleteProductLook`, body, { headers: this.getHeaders(token) });
  }

  addProductLook(look_id: string, uid: string, token: string) {
    const body = {
      "uids": [uid]
    };
    return this.http.put(`${this.apiURL}/v2/look/${look_id}/addProductLook`, body, { headers: this.getHeaders(token) });
  }

  resetLook(look_id: string, priority: boolean, token: string) {
    return this.http.put(`${this.apiURL}/v2/look/${look_id}/reset${priority ? "WithPriority" : ""}`, {}, { headers: this.getHeaders(token) });
  }

  generateCache(uid: string, type: string, token: string, apikey: string) {
    return this.http.put(`${this.apiURL}/cache/v2/${type === "recommendbyid" ? type : "searchbyid"}/${uid}`, {}, { headers: this.getHeadersWithApikey(token, apikey) });
  }

  containsUid(uid: string, token: string) {
    return this.http.get(`${this.apiURL}/v2/look/containsProduct/${uid}`, { headers: this.getHeaders(token) });
  }

  getHeaders(token: string) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `bearer ${token}`
    });
    return httpHeaders;
  }

  getHeadersWithApikey(token: string, apikey: string) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `bearer ${token}`,
      'apikey': apikey
    });
    return httpHeaders;
  }

  generateQueryString(gender, category, subcategory, selectedGenerationType, selectedRecommendationType, selectedNoCategory, startAt, endAt, NextToken) {
    let params = new HttpParams();
    if (gender) {
      params = params.append('gender', gender);
    }
    if (category) {
      params = params.append('category', category);
    }
    if (subcategory) {
      params = params.append('subcategory', subcategory);
    }
    if (selectedGenerationType || selectedGenerationType === "") {
      params = params.append('generationType', selectedGenerationType);
    }
    if (selectedRecommendationType || selectedRecommendationType === "") {
      params = params.append('recommendationType', selectedRecommendationType);
    }
    if (selectedNoCategory) {
      params = params.append('noCategory', selectedNoCategory);
    }
    if (startAt) {
      params = params.append('afterGeneratedAt', encodeURI(startAt));
    }
    if (endAt) {
      params = params.append('beforeGeneratedAt', encodeURI(endAt));
    }
    if (NextToken) {
      params = params.append('token', NextToken);
    }
    return params;
  }

  putLook(look: any, token: string) {
    const body = JSON.stringify([look]);
    return this.http.put(`${this.apiURL}/v2/look`, body, { headers: this.getHeaders(token) });
  }

}
