<header>
  <nav class="navbar navbar-expand-lg navbar-dark bg-light">
    <div class="container noselect">
      <img src="./assets/logo_white_sm.png" alt="" routerLinkActive="active"
        [routerLink]="auth.loggedIn ? ['/home'] : []" [routerLinkActiveOptions]="{exact:true}"
        (click)="Closeservices()">
      <h6 (click)="services()" *ngIf="auth.loggedIn">services <i class="fa fa-angle-double-down" aria-hidden="true"
          *ngIf="showServices"></i><i class="fa fa-angle-double-up" aria-hidden="true" *ngIf="!showServices"></i>
      </h6>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive"
        aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="navbar-collapse">
        <ul class="navbar-nav">
          <li class="nav-item nav-link">
            <i class="fa fa-user-circle" aria-hidden="true"></i> &nbsp; user: {{auth.user_id}} </li>
          <li class="nav-item">
            <a routerLink="/account/logout" class="nav-link" routerLinkActive="active" *ngIf="auth.loggedIn">
              <i class="fa fa-sign-out fa-fw text-light"></i> Logout
            </a>
          </li>
          <li class="nav-item">
            <a routerLink="/account/login" class="nav-link" routerLinkActive="active"
              [routerLinkActiveOptions]="{exact:true}" *ngIf="!auth.loggedIn">
              <i class="fa fa-sign-in fa-fw"></i> Login
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <div class="service-container" [hidden]="showServices">
    <div class="col-md-12 noselect">
      <ul>
        <li>
          <h4 (click)="RouteTo('styleAdvisor')">Style Advisor</h4>
        </li>
        <li>
          <h4 (click)="RouteTo('visionproducts')">Vision Products</h4>
        </li>
        <li>
          <h4 (click)="RouteTo('computeDetectors')">Compute Detectors</h4>
        </li>
        <li>
          <h4 (click)="RouteTo('')">Gender Mapping</h4>
        </li>
      </ul>
      <ul>
        <li>
          <h4 (click)="RouteTo('')">Annotator</h4>
        </li>
        <li>
          <h4 (click)="RouteTo('')">Backoffice</h4>
        </li>
        <li>
          <h4 (click)="RouteTo('')">Imports Control</h4>
        </li>
        <li>
          <h4 (click)="RouteTo('')">Lorem Ipsum</h4>
        </li>
      </ul>
    </div>
  </div>
</header>
