import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
var UserService = /** @class */ (function () {
    function UserService(http) {
        this.http = http;
        this.apiURL = environment.apiURL;
    }
    UserService.prototype.login = function (credentials) {
        return this.http.post(this.apiURL + "/login", credentials);
    };
    UserService.prototype.getUser = function (token) {
        return this.http.get(this.apiURL + "/users/apikeys", { headers: this.setHeaders(token) });
    };
    UserService.prototype.getUserId = function (token) {
        return this.http.get(this.apiURL + "/users/id", { headers: this.setHeaders(token) }).toPromise();
    };
    UserService.prototype.setHeaders = function (token) {
        return new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': "bearer " + token
        });
    };
    return UserService;
}());
export { UserService };
