var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ProductService } from "../../services/ProductService";
import { LookService } from "../../services/LookService";
import { ToastComponent } from "../../shared/toast/toast.component";
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PagerService } from '../../shared/page/pager.service';
import { ProductStatus } from "../../shared/models/status.model";
var ModalComponent = /** @class */ (function () {
    function ModalComponent(toast, config, modalService, productService, lookService, pagerService) {
        this.toast = toast;
        this.modalService = modalService;
        this.productService = productService;
        this.lookService = lookService;
        this.pagerService = pagerService;
        this.selectedCategory = null;
        this.selectedSubcategory = null;
        this.isLoading = true;
        this.buttonLoading = false;
        this.isPerfectMatchSelected = false;
        this.candidates_similars = [];
        this.categories_data = {};
        this.genders = [];
        this.categories = {};
        this.subcategories = {};
        this.objectKeys = Object.keys;
        this.new_products = [];
        this.selected_new_candidate = { "uid": "" };
        this.product_uid = "";
        this.product_count = [];
        this.pager = {};
        this.cached_candidates = [];
        this.status = new ProductStatus();
        this.limit = "50";
        this.textSearch = "";
        config.backdrop = true;
        config.keyboard = true;
    }
    ModalComponent.prototype.open = function (content) {
        var _this = this;
        if (this.type === "updateCandidate") {
            this.lookService.getLookWithSimilars(this.look.id, this.token).subscribe(function (data) {
                _this.isLoading = false;
                try {
                    _this.candidates_similars = data.results[0].products.filter(function (obj) { return obj.id === _this.look_product.id; })[0].similars.slice(0, 40);
                    _this.cached_candidates = _this.candidates_similars;
                }
                catch (error) {
                    _this.candidates_similars = _this.cached_candidates;
                }
                if (!_this.candidates_similars.length) {
                    _this.toast.setMessage("Product without similars", 'info');
                }
            });
        }
        if (this.type === "addCandidate") {
            this.productService.getCategories(this.token).then(function (data) {
                _this.categories_data = data.categories;
                _this.genders = Object.keys(_this.categories_data);
                for (var _i = 0, _a = Object.keys(_this.categories_data); _i < _a.length; _i++) {
                    var i = _a[_i];
                    _this.categories[i] = _this.categories_data[i].map(function (key) { return ({ "category": key.name, "subcategory": key.subcategories.map(function (val) { return (val.name); }) }); });
                    for (var _b = 0, _c = Object.keys(_this.categories[i]); _b < _c.length; _b++) {
                        var j = _c[_b];
                        _this.subcategories[i + _this.categories[i][j]["category"]] = _this.categories[i][j]["subcategory"];
                    }
                }
            });
        }
        var options = {
            size: 'lg',
            windowClass: 'animated bounceInUp',
            beforeDismiss: function () {
                return true;
            }
        };
        this.modalService.open(content, options).result.then(function (result) {
        }, function (reason) {
            console.log("reason ", reason);
            _this.new_products = [];
            _this.product_uid = "";
        });
    };
    ModalComponent.prototype.onKeydown = function (event) {
        return __awaiter(this, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(event.key === "Enter")) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.productService.getProduct(this.product_uid, this.token, this.status.active)];
                    case 1:
                        data = _a.sent();
                        this.candidates_similars = data.results;
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    ModalComponent.prototype.setPerfectMatch = function (product) {
        return __awaiter(this, void 0, void 0, function () {
            var data, new_look_prd;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.lookService.setPerfectMatch(this.look.id, this.look_product.id, product.uid, this.token)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.lookService.getLookByUid(this.look.uid, this.token)];
                    case 2:
                        data = _a.sent();
                        new_look_prd = data.results[0].products.filter(function (obj) { return product.uid === obj.match; })[0];
                        this.look_product = new_look_prd;
                        this.recommends[product.uid] = product;
                        this.recommends[product.uid].visual_status = this.status.active;
                        this.look.products = data.results[0].products;
                        this.toast.setMessage("Perfect match set correctly", 'success');
                        return [2 /*return*/];
                }
            });
        });
    };
    ModalComponent.prototype.listProducts = function () {
        return __awaiter(this, void 0, void 0, function () {
            var body, count_body;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.new_products = [];
                        this.buttonLoading = true;
                        if (!this.product_uid) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.productService.getProduct(this.product_uid, this.token, this.status.active).then(function (data) {
                                if (data.results && data.results.length) {
                                    _this.new_products = data.results;
                                }
                            }).catch(function () {
                                _this.toast.setMessage("product not found", 'danger');
                            }).finally(function () {
                                _this.buttonLoading = false;
                            })];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 2:
                        if (!this.textSearch) return [3 /*break*/, 4];
                        this.isLoading = true;
                        body = {
                            text: this.textSearch,
                            gender: this.selectedGender,
                            category: this.selectedCategory,
                            subcategory: this.selectedSubcategory
                        };
                        return [4 /*yield*/, this.productService
                                .getTextSearch(body, this.apikey)
                                .then(function (data) {
                                if (data.results.length) {
                                    _this.new_products = data.results[0].products;
                                }
                            })
                                .catch(function (error) {
                                _this.toast.setMessage(error.statusText, "danger");
                                console.log(error);
                            })
                                .finally(function () { return (_this.buttonLoading = false); })];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        count_body = {};
                        if (this.selectedGender) {
                            count_body["gender"] = this.selectedGender;
                        }
                        if (this.selectedCategory) {
                            count_body["category"] = this.selectedCategory;
                        }
                        if (this.selectedSubcategory) {
                            count_body["subcategory"] = this.selectedSubcategory;
                        }
                        this.productService.getCount(this.selectedGender, this.selectedCategory, this.selectedSubcategory, this.token).then(function (data) {
                            _this.product_count = [];
                            for (var i = 1; i <= data.count; i++) {
                                _this.product_count.push(i);
                            }
                            _this.setPage(1);
                            _this.buttonLoading = false;
                        });
                        _a.label = 5;
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    ModalComponent.prototype.getManyProducts = function (page_num) {
        var _this = this;
        this.productService.getProductMany(this.selectedGender, this.selectedCategory, this.selectedSubcategory, page_num, this.limit, this.token, null).then(function (data) {
            _this.new_products = data.results;
            _this.buttonLoading = false;
        });
    };
    ModalComponent.prototype.selectGender = function (value) {
        this.selectedGender = value;
        this.selectedCategory = null;
        this.selectedSubcategory = null;
    };
    ModalComponent.prototype.selectCategory = function (value) {
        this.selectedCategory = value;
        this.selectedSubcategory = null;
    };
    ModalComponent.prototype.selectSubcategory = function (value) {
        this.selectedSubcategory = value;
    };
    ModalComponent.prototype.setNewCandidate = function (product) {
        var _this = this;
        this.selected_new_candidate["uid"] = product.uid;
        this.lookService.addProductLook(this.look.id, product.uid, this.token).subscribe(function (data) {
            if (data.results && data.results.length) {
                _this.toast.setMessage("new candidate added successfully", 'success');
                _this.look.products = data.results[0].products;
                for (var index = 0; index < _this.look.products.length; index++) {
                    var new_match = _this.look.products[index].match;
                    if (!_this.recommends.hasOwnProperty(new_match)) {
                        _this.recommends[new_match] = product;
                        _this.recommends[new_match].visual_status = _this.status.active;
                    }
                }
            }
            else {
                _this.toast.setMessage("unexpected error occured", 'danger');
            }
        });
    };
    ModalComponent.prototype.setPage = function (page) {
        this.pager = this.pagerService.getPager(this.product_count.length, page);
        this.pagedItems = this.product_count.slice(this.pager.startIndex, this.pager.endIndex + 1);
        this.getManyProducts(this.pager.currentPage);
    };
    ModalComponent.prototype.clear = function () {
        this.product_uid = "";
    };
    ModalComponent.prototype.clearText = function () {
        this.textSearch = "";
    };
    return ModalComponent;
}());
export { ModalComponent };
