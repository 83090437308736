import { Component, OnInit } from '@angular/core';
import { ToastComponent } from "../../shared/toast/toast.component";
import { ModalComponent } from "../../shared/modal/modal.component";
import { ProductService } from "../../services/ProductService";
import { ErrFmt } from "../../util/helpers/err.helper";
import * as moment from 'moment';
import { Router } from '@angular/router';
import { AuthService } from '../../services/AuthService';
import { UserService } from '../../services/user.service';
import { Shared } from "../../util/service/shared.methods";

import * as _ from 'lodash';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {

  constructor(
    private auth: AuthService,
    private router: Router,
    public toast: ToastComponent,
    public modal: ModalComponent,
    public share: Shared) {
  }


  async ngOnInit() {
    if (this.auth.isAuthenticated()) {
      this.auth.loggedIn = true;
    } else {
      this.auth.loggedIn = false;
      this.router.navigate(['/account/login']);
    }
  }

  RouteTo(path: string) {
    this.share.RouteTo(path);
  }


}
