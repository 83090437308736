<app-toast [message]="toast.message"></app-toast>
<div class="wrapper">
  <!-- Sidebar -->
  <nav id="sidebar">
    <div class="sidebar-header">
      <div [hidden]="!isLoading" class="spinner-border text-primary" role="status">
        <span class="sr-only"></span>
      </div>
      <h3>Products controller</h3>
      <button mat-raised-button (click)="Load()">RESET</button>
      <select class="browser-default custom-select" [(ngModel)]="selectedGender" name="gender"
        (change)=onChange($event)>
        <option value="defaultgender">select gender</option>
        <option *ngFor="let key of objectKeys(accountData)">{{key}} <i class="fa fa-times"></i>
        </option>
      </select>

      <select class="browser-default custom-select" [(ngModel)]="selectedCategory" name="category"
        (change)=onChange($event)>
        <option value="defaultcategory">select category</option>
        <option *ngFor="let key of categories;">
          {{key}}
        </option>
      </select>

      <select class="browser-default custom-select" [(ngModel)]="selectedSubcategory" name="subcategory"
        (change)=onChange($event)>
        <option value="defaultsubcategory">select subcategory</option>
        <option *ngFor="let key of subcategories;">
          {{key}}
        </option>
      </select>
      <div class="search-bar mt-3">
        <mat-form-field class="example-full-width">
          <mat-label>Product UID</mat-label>
          <input matInput placeholder="Type uid and press ENTER" (keyup.enter)="searchOne($event)">
        </mat-form-field>
      </div>
      <div class="search-bar mt-3">
        <mat-form-field class="example-full-width">
          <mat-label>Text Search</mat-label>
          <input matInput placeholder="Type text and press ENTER" (keyup.enter)="textSearch($event)">
        </mat-form-field>
      </div>
      <div class="extra-buttons">
        <button class="d-block" [disabled]="!selectedGender" mat-raised-button color="primary"
          (click)="categoryChangeModal(categoryContent)">UPDATE
          SELECTED</button>
        <button class="d-block" [disabled]="!selectedGender" mat-raised-button color="warn"
          (click)="deleteSelectedProducts()">DELETE
          SELECTED</button>
      </div>
    </div>
  </nav>
  <!-- Page Content -->
  <div id="content">
    <div class="body col-md-12">
      <div class="container-fluid">
        <div class="row">
          <mat-toolbar color="#adf1f7" class="noselect">
            <span class="text-danger" [hidden]="products.length">No products</span>
            <div class="navbar" [hidden]="!products.length">
              <span class="text-info buttonstyle" (click)="selectAll()">SELECT ALL</span>
              <span class="text-success buttonstyle pl-2" (click)="showCrop()">SHOW CROPS</span>
              <span class="mat-small pl-2">products count: {{productsCount}}</span>
            </div>
          </mat-toolbar>
          <div class="card-deck m-1" *ngFor=" let product of products">
            <div class="card" [ngStyle]="{'border' : isSelected(product) ? '1px solid green' : '1px solid #e3e3e3'}">
              <img [hidden]="product.lazyLoad" class="img-thumbnail" src="../../../assets/lazyLoad.gif" alt="">
              <img [hidden]="!product.lazyLoad" class="card-img-top" src="{{product.images[0].imageToProcess ? product.images[0].imageToProcess : product.images[0].imageUrl}}"
                alt="Card image cap" (click)="selectProducts($event, product)" (load)="checkLazyLoad(product)">
              <canvas class="noselect" #canvas title={{product.uid}} (click)="selectProducts($event, product)"></canvas>
              <div class="card-body">
                <p class="card-text">UID: {{product.uid}}</p>
                <p class="card-text">NAME: {{product.name}}</p>
              </div>
              <button class="w-100" mat-raised-button (click)="getSimilars(similarContent, product)">SIMILARS</button>
              <button class="w-100" mat-raised-button (click)="infoProduct(content, product)">INFO</button>
            </div>
          </div>
        </div>
        <ngb-pagination [hidden]="!products.length" [collectionSize]="productsCount" [maxSize]="15"
          [pageSize]="productLimit" [(page)]="currentPage" (pageChange)="onPageChange(currentPage, products.length)">
        </ngb-pagination>
      </div>
    </div>
  </div>
</div>
<!-- Modal to display products -->
<ng-template #content let-c="close" class="bg-dark" let-d="dismiss" #testWrap>
  <div class="modal-body bg-dark">
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
    <div class="text-center">
      <table class="table">
        <tbody class="bg-light">
          <tr>
            <th scope="row">UID:</th>
            <th><input type="text" class="p-2 w-100" value="{{product.uid}}" disabled></th>
          </tr>
          <tr>
            <th scope="row">NAME:</th>
            <th><input type="text" class="p-2 w-100" value="{{product.name}}" [(ngModel)]="product.name">
            </th>
          </tr>
          <tr>
            <th scope="row">CREATED AT:</th>
            <th><input type="text" class="p-2 w-100" value="{{getDate(product.createdAt)}}" disabled></th>
          </tr>
          <tr>
            <th scope="row">UPDATED AT:</th>
            <th><input type="text" class="p-2 w-100" value="{{getDate(product.updatedAt)}}" disabled></th>
          </tr>
          <tr>
            <th scope="row">IMAGE URL:</th>
            <th><input type="text" class="p-2 w-100" value="{{product.images[0].imageUrl}}" name="imageUrl"
                [(ngModel)]="product.images[0].imageUrl"></th>
          </tr>
          <tr>
            <th scope="row">IMAGE TO PROCESS:</th>
            <th>
              <input type="text" class="p-2 w-100" value="{{product.images[0].imageToProcess}}" name="imageToProcess"
                [(ngModel)]="product.images[0].imageToProcess">
            </th>
          </tr>
          <tr>
            <th scope="row">PRODUCT URL:</th>
            <th>
              <input type="text" class="p-2 w-100" value="{{product.productUrl}}" name="productUrl"
                [(ngModel)]="product.productUrl">
            </th>
          </tr>
          <tr>
            <th scope="row">GENDER:</th>
            <th>
              <select class="browser-default custom-select" [(ngModel)]="product.gender">
                <option value="defaultgender">select gender</option>
                <option *ngFor="let key of objectKeys(this.accountData)">{{key}}
                </option>
              </select>
              <div class="input-group">
                <input class="w-100 mt-1 p-1" type="text" placeholder="type new gender" [(ngModel)]="newGender">
                <span [hidden]="!newGender" (click)="newGender=''">
                  <i class="fa fa-times" aria-hidden="true"></i>
                </span>
              </div>
            </th>
          </tr>
          <tr>
            <th scope="row">CATEGORY:</th>
            <th>
              <select class="browser-default custom-select" [(ngModel)]="product.category.name">
                <option value="defaultcategory">select category</option>
                <option *ngFor="let values of objectValues(this.accountData[product.gender]);"> {{values.name}}
                </option>
              </select>
              <div class="input-group">
                <input class="w-100 mt-1 p-1" type="text" placeholder="type new category" [(ngModel)]="newCategory">
                <span [hidden]="!newCategory" (click)="newCategory=''">
                  <i class="fa fa-times" aria-hidden="true"></i>
                </span>
              </div>
            </th>
          </tr>
          <tr>
            <th scope="row">SUBCATEGORY:</th>
            <th>
              <select class="browser-default custom-select" [(ngModel)]="product.category.subcategory.name">
                <option value="defaultsubcategory">select subcategory</option>
                <option
                  *ngFor="let values of filterSubcategories(objectValues(this.accountData[product.gender]), product.category.name) ;">
                  {{values}}
                </option>
              </select>
              <div class="input-group">
                <input class="w-100 mt-1 p-1" type="text" placeholder="type new subcategory"
                  [(ngModel)]="newSubcategory">
                <span [hidden]="!newSubcategory" (click)="newSubcategory=''">
                  <i class="fa fa-times" aria-hidden="true"></i>
                </span>
              </div>
            </th>
          </tr>
          <tr>
            <th colspan="2">
              <button class="w-100 right" mat-raised-button color="primary"
                (click)="updateProduct(product)">UPDATE</button>
            </th>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-template>

<!-- Modal Update category -->
<ng-template #categoryContent let-modal class="bg-dark">
  <div class="modal-body bg-dark">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
    <div class="text-center">
      <table class="table">
        <tbody class="bg-light">
          <tr>
            <th scope="row">GENDER:</th>
            <th>
              <select class="browser-default custom-select" [(ngModel)]="newGender">
                <option value="defaultgender">select gender</option>
                <option *ngFor="let key of objectKeys(this.accountData)">{{key}}
                </option>
              </select>
              <div class="input-group">
                <input class="w-100 mt-1 p-1" type="text" placeholder="type new gender" [(ngModel)]="newGender">
                <span [hidden]="!newGender" (click)="newGender=''">
                  <i class="fa fa-times" aria-hidden="true"></i>
                </span>
              </div>
            </th>
          </tr>
          <tr>
            <th scope="row">CATEGORY:</th>
            <th>
              <select class="browser-default custom-select" [(ngModel)]="newCategory">
                <option value="defaultcategory">select category</option>
                <option *ngFor="let values of order(objectValues(this.accountData[selectedGender]));"> {{values.name}}
                </option>
              </select>
              <div class="input-group">
                <input class="w-100 mt-1 p-1" type="text" placeholder="type new category" [(ngModel)]="newCategory">
                <span [hidden]="!newCategory" (click)="newCategory=''">
                  <i class="fa fa-times" aria-hidden="true"></i>
                </span>
              </div>
            </th>
          </tr>
          <tr>
            <th scope="row">SUBCATEGORY:</th>
            <th>
              <select class="browser-default custom-select" [(ngModel)]="newSubcategory">
                <option value="defaultsubcategory">select subcategory</option>
                <option
                  *ngFor="let values of filterSubcategories(objectValues(this.accountData[selectedGender]), newCategory) ;">
                  {{values}}
                </option>
              </select>
              <div class="input-group">
                <input class="w-100 mt-1 p-1" type="text" placeholder="type new subcategory"
                  [(ngModel)]="newSubcategory">
                <span [hidden]="!newSubcategory" (click)="newSubcategory=''">
                  <i class="fa fa-times" aria-hidden="true"></i>
                </span>
              </div>
            </th>
          </tr>
          <tr>
            <th colspan="2">
              <button class="w-100 right" mat-raised-button color="primary"
                (click)="modal.close('Save click')">UPDATE</button>
            </th>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-template>

<!-- Show similars in modal -->
<ng-template #similarContent let-c="close" class="bg-dark" let-d="dismiss">
  <div class="modal-body bg-dark">
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
    <mat-checkbox class="text-light mr-2" name="category" (change)="similarCall()" [(ngModel)]="checkCat">in
      category </mat-checkbox>
    <mat-checkbox class="text-light" name="subcategory" (change)="similarCall()" [(ngModel)]="checkSubcat">in
      subcategory</mat-checkbox>
    <div [hidden]="!isLoading" class="spinner-border text-primary" role="status">
      <span class="sr-only"></span>
    </div>
    <div class="text-center">
      <div class="body col-md-12">
        <div class="row">
          <div class="card-deck m-1" *ngFor=" let product of similars">
            <div class="card" style="width: 12rem;">
              <img class="card-img-top" src="{{product.images[0].imageToProcess || product.images[0].imageUrl}}" alt="Card image cap">
              <div class="card-body">
                <p class="card-text">UID: {{product.uid}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
