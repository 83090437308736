import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
var LookService = /** @class */ (function () {
    function LookService(http) {
        this.http = http;
        this.apiURL = environment.apiURL;
        this.apiURLSTAGING = environment.apiURLSTAGING;
        this.apiURLMapping = environment.apiURLMapping;
    }
    LookService.prototype.getLookByUid = function (uid, token) {
        return this.http.get(this.apiURL + "/v2/look/uid/" + uid, { headers: this.getHeaders(token) }).toPromise();
    };
    // TODO get look count by categorization
    LookService.prototype.getCountLooks = function (gender, category, subcategory, selectedGenerationType, selectedRecommendationType, selectedNoCategory, startAt, endAt, NextToken, token) {
        var params = this.generateQueryString(gender, category, subcategory, selectedGenerationType, selectedRecommendationType, selectedNoCategory, startAt, endAt, NextToken);
        return this.http.get(this.apiURL + "/v2/look/count?status=ACTIVE", { headers: this.getHeaders(token), params: params });
    };
    LookService.prototype.getLooksMany = function (gender, category, subcategory, selectedGenerationType, selectedRecommendationType, selectedNoCategory, startAt, endAt, NextToken, token) {
        var params = this.generateQueryString(gender, category, subcategory, selectedGenerationType, selectedRecommendationType, selectedNoCategory, startAt, endAt, NextToken);
        return this.http.get(this.apiURL + "/v2/look?status=ACTIVE&limit=10", { headers: this.getHeaders(token), params: params }).toPromise();
    };
    LookService.prototype.getLooksManyV2 = function (gender, category, subcategory, selectedGenerationType, selectedRecommendationType, selectedNoCategory, startAt, endAt, NextToken, token) {
        var params = this.generateQueryString(gender, category, subcategory, selectedGenerationType, selectedRecommendationType, selectedNoCategory, startAt, endAt, NextToken);
        return this.http.get(this.apiURL + "/v2/look/listActiveLooks?limit=10", { headers: this.getHeaders(token), params: params }).toPromise();
    };
    LookService.prototype.countLooksManyV2 = function (gender, category, subcategory, selectedGenerationType, selectedRecommendationType, selectedNoCategory, startAt, endAt, token) {
        var params = this.generateQueryString(gender, category, subcategory, selectedGenerationType, selectedRecommendationType, selectedNoCategory, startAt, endAt, null);
        return this.http.get(this.apiURL + "/v2/look/countActiveLooks", { headers: this.getHeaders(token), params: params }).toPromise();
    };
    LookService.prototype.getLookWithSimilars = function (look_id, token) {
        return this.http.get(this.apiURL + "/v2/look/" + look_id + "/withSimilars/", { headers: this.getHeaders(token) });
    };
    LookService.prototype.setPerfectMatch = function (look_id, look_product_id, uid, token) {
        var body = {
            "productUid": uid
        };
        return this.http.put(this.apiURL + "/v2/look/" + look_id + "/productLook/" + look_product_id + "/setPerfectMatch", body, { headers: this.getHeaders(token) }).toPromise();
    };
    LookService.prototype.deleteProductLook = function (look_id, look_product_id, token) {
        var body = {
            "productLookIds": [look_product_id]
        };
        return this.http.put(this.apiURL + "/v2/look/" + look_id + "/deleteProductLook", body, { headers: this.getHeaders(token) });
    };
    LookService.prototype.addProductLook = function (look_id, uid, token) {
        var body = {
            "uids": [uid]
        };
        return this.http.put(this.apiURL + "/v2/look/" + look_id + "/addProductLook", body, { headers: this.getHeaders(token) });
    };
    LookService.prototype.resetLook = function (look_id, priority, token) {
        return this.http.put(this.apiURL + "/v2/look/" + look_id + "/reset" + (priority ? "WithPriority" : ""), {}, { headers: this.getHeaders(token) });
    };
    LookService.prototype.generateCache = function (uid, type, token, apikey) {
        return this.http.put(this.apiURL + "/cache/v2/" + (type === "recommendbyid" ? type : "searchbyid") + "/" + uid, {}, { headers: this.getHeadersWithApikey(token, apikey) });
    };
    LookService.prototype.containsUid = function (uid, token) {
        return this.http.get(this.apiURL + "/v2/look/containsProduct/" + uid, { headers: this.getHeaders(token) });
    };
    LookService.prototype.getHeaders = function (token) {
        var httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': "bearer " + token
        });
        return httpHeaders;
    };
    LookService.prototype.getHeadersWithApikey = function (token, apikey) {
        var httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': "bearer " + token,
            'apikey': apikey
        });
        return httpHeaders;
    };
    LookService.prototype.generateQueryString = function (gender, category, subcategory, selectedGenerationType, selectedRecommendationType, selectedNoCategory, startAt, endAt, NextToken) {
        var params = new HttpParams();
        if (gender) {
            params = params.append('gender', gender);
        }
        if (category) {
            params = params.append('category', category);
        }
        if (subcategory) {
            params = params.append('subcategory', subcategory);
        }
        if (selectedGenerationType || selectedGenerationType === "") {
            params = params.append('generationType', selectedGenerationType);
        }
        if (selectedRecommendationType || selectedRecommendationType === "") {
            params = params.append('recommendationType', selectedRecommendationType);
        }
        if (selectedNoCategory) {
            params = params.append('noCategory', selectedNoCategory);
        }
        if (startAt) {
            params = params.append('afterGeneratedAt', encodeURI(startAt));
        }
        if (endAt) {
            params = params.append('beforeGeneratedAt', encodeURI(endAt));
        }
        if (NextToken) {
            params = params.append('token', NextToken);
        }
        return params;
    };
    LookService.prototype.putLook = function (look, token) {
        var body = JSON.stringify([look]);
        return this.http.put(this.apiURL + "/v2/look", body, { headers: this.getHeaders(token) });
    };
    return LookService;
}());
export { LookService };
