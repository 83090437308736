<app-toast [message]="toast.message"></app-toast>
<div class="container my-4">
  <div class="col-md-12">
    <div class="col-md-12 noselect">
      <h3>Wide Eyes Internal Services</h3>
      <ul>
        <li>
          <button class="btn btn-dark" (click)="RouteTo('styleAdvisor')">Style Advisor Dashboard</button>
        </li>
        <li>
          <button class="btn btn-dark" (click)="RouteTo('visionproducts')">Vision Products Dashboard</button>
        </li>
        <li>
          <button class="btn btn-dark" (click)="RouteTo('computeDetectors')">Compute Detectors Dashboard</button>
        </li>
        <li>
          <button class="btn btn-dark" (click)="RouteTo('')">Gender Mapping Dashboard</button>
        </li>
      </ul>
      <ul>
        <li>
          <button class="btn btn-dark" (click)="RouteTo('')">Annotator Dashboard</button>
        </li>
        <li>
          <button class="btn btn-dark" (click)="RouteTo('')">Backoffice Dashboard</button>
        </li>
        <li>
          <button class="btn btn-dark" (click)="RouteTo('')">Imports Control Dashboard</button>
        </li>
        <li>
          <button class="btn btn-dark" (click)="RouteTo('')">Lorem Ipsum Dashboard</button>
        </li>
      </ul>
    </div>
  </div>
</div>
