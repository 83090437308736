var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { ToastComponent } from "../../shared/toast/toast.component";
import { ModalComponent } from "../../shared/modal/modal.component";
import { ProductService } from "../../services/ProductService";
import { ConfigService } from "../../services/ConfigService";
import { Router } from '@angular/router';
import { AuthService } from '../../services/AuthService';
import { UserService } from '../../services/user.service';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as $ from 'jquery';
var ComputeDetectorsComponent = /** @class */ (function () {
    function ComputeDetectorsComponent(auth, userservice, productService, configService, router, config, modalService, toast, modal) {
        this.auth = auth;
        this.userservice = userservice;
        this.productService = productService;
        this.configService = configService;
        this.router = router;
        this.modalService = modalService;
        this.toast = toast;
        this.modal = modal;
        this.csvLines = [];
        this.limit = "10";
        this.dropdownList = [
            { map_id: 1, detect_value: 'accessories' },
            { map_id: 2, detect_value: 'bag' },
            { map_id: 3, detect_value: 'belt' },
            { map_id: 4, detect_value: 'blazer' },
            { map_id: 5, detect_value: 'blouse' },
            { map_id: 6, detect_value: 'bodysuit' },
            { map_id: 7, detect_value: 'boots' },
            { map_id: 8, detect_value: 'bra' },
            { map_id: 9, detect_value: 'bracelet' },
            { map_id: 10, detect_value: 'cape' },
            { map_id: 11, detect_value: 'cardigan' },
            { map_id: 12, detect_value: 'clogs' },
            { map_id: 13, detect_value: 'coat' },
            { map_id: 14, detect_value: 'dress' },
            { map_id: 15, detect_value: 'earrings' },
            { map_id: 16, detect_value: 'flats' },
            { map_id: 17, detect_value: 'glasses' },
            { map_id: 18, detect_value: 'gloves' },
            { map_id: 19, detect_value: 'hat' },
            { map_id: 20, detect_value: 'heels' },
            { map_id: 21, detect_value: 'intimate' },
            { map_id: 22, detect_value: 'jacket' },
            { map_id: 23, detect_value: 'jeans' },
            { map_id: 24, detect_value: 'jumper' },
            { map_id: 25, detect_value: 'jumpsuit' },
            { map_id: 26, detect_value: 'leggings' },
            { map_id: 27, detect_value: 'loafers' },
            { map_id: 28, detect_value: 'necklace' },
            { map_id: 29, detect_value: 'pants' },
            { map_id: 30, detect_value: 'pumps' },
            { map_id: 31, detect_value: 'purse' },
            { map_id: 32, detect_value: 'ring' },
            { map_id: 33, detect_value: 'romper' },
            { map_id: 34, detect_value: 'sandals' },
            { map_id: 35, detect_value: 'scarf' },
            { map_id: 36, detect_value: 'shirt' },
            { map_id: 37, detect_value: 'shoes' },
            { map_id: 38, detect_value: 'shorts' },
            { map_id: 39, detect_value: 'skirt' },
            { map_id: 40, detect_value: 'sneakers' },
            { map_id: 41, detect_value: 'socks' },
            { map_id: 42, detect_value: 'stockings' },
            { map_id: 43, detect_value: 'suit' },
            { map_id: 44, detect_value: 'sunglasses' },
            { map_id: 45, detect_value: 'sweater' },
            { map_id: 46, detect_value: 'sweatshirt' },
            { map_id: 47, detect_value: 't-shirt' },
            { map_id: 48, detect_value: 'tie' },
            { map_id: 49, detect_value: 'tights' },
            { map_id: 50, detect_value: 'top' },
            { map_id: 51, detect_value: 'vest' },
            { map_id: 52, detect_value: 'wallet' },
            { map_id: 53, detect_value: 'watch' },
            { map_id: 54, detect_value: 'wedges' }
        ];
        this.selectedItems = [];
        this.pager = [];
        this.parsialLines = [];
        this.dropdownSettings = {};
        this.isLoading = true;
        this.modalProductPageNum = 0;
        this.lineNumber = -1;
        this.modalProducts = [];
        this.selectedPage = 0;
    }
    ComputeDetectorsComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (this.auth.isAuthenticated()) {
                    this.auth.loggedIn = true;
                    this.user_id = this.auth.user_id;
                    this.token = this.auth.token;
                    this.getCsvMapping();
                }
                else {
                    this.auth.loggedIn = false;
                    this.router.navigate(['/account/login']);
                }
                return [2 /*return*/];
            });
        });
    };
    ComputeDetectorsComponent.prototype.getCsvMapping = function () {
        var _this = this;
        this.configService.getCsvMapping(this.user_id).subscribe(function (data) {
            _this.generateLines(data);
        }, function (err) {
            _this.toast.setMessage(err.message, 'danger', 5000);
            _this.isLoading = false;
        });
    };
    ComputeDetectorsComponent.prototype.generateLines = function (text) {
        var lines = text.split('\n');
        lines.pop();
        var lineNumber = 0;
        var _loop_1 = function (itrt) {
            var l = lines[itrt];
            lineNumber++;
            var data = this_1.CSVtoArray(l);
            if (data != null) {
                var selectedItems = [];
                var clientCat = data[0] || '';
                var clientSubcat = data[1] || '';
                var clientGender = data[2] || '';
                var prductsNumber = data[3] || '';
                var wideeyesCategory = data[4] || '';
                var wideeyesGender = data[5] || '';
                if (wideeyesCategory !== "") {
                    var mappings_1 = wideeyesCategory.split(",");
                    var _loop_2 = function (itr) {
                        selectedItems.push(this_1.dropdownList.filter(function (obj) {
                            return obj.detect_value === mappings_1[itr];
                        })[0]);
                    };
                    for (var _i = 0, _a = Object.keys(mappings_1); _i < _a.length; _i++) {
                        var itr = _a[_i];
                        _loop_2(itr);
                    }
                }
                var newObj = {
                    lineNumber: lineNumber,
                    clientCat: clientCat,
                    clientSubcat: clientSubcat,
                    clientGender: clientGender,
                    prductsNumber: prductsNumber,
                    wideeyesCategory: wideeyesCategory,
                    wideeyesGender: wideeyesGender,
                    selectedItems: selectedItems
                };
                this_1.csvLines.push(newObj);
            }
        };
        var this_1 = this;
        for (var itrt = lines.length - 1; itrt >= 0; itrt--) {
            _loop_1(itrt);
        }
        this.dropdownSettings = {
            singleSelection: false,
            idField: 'map_id',
            textField: 'detect_value',
            itemsShowLimit: 3,
            allowSearchFilter: true,
            clearSearchFilter: true,
            enableCheckAll: false,
            searchPlaceholderText: "click here to remove after select"
        };
        this.isLoading = false;
        for (var i = 0; i < this.csvLines.length; i = i + 200) {
            var quotient = Math.floor(i / 200);
            this.pager.push(quotient);
        }
        this.parsialLines = this.csvLines.slice(0, 200);
        $(document).ready(function () {
            $(".multiselect").on('click', function (event) {
                var input_field = $(this).find('input[type=text]');
                input_field.addClass('fa fa-eraser');
                input_field.focus();
            });
            $(".filter-textbox").on("click", function (event) {
                var input_field = $(this).find('input[type=text]');
                input_field.val("");
            });
        });
    };
    ComputeDetectorsComponent.prototype.setPage = function (number) {
        this.parsialLines = this.csvLines.slice(number * 200, (number + 1) * 200);
        this.selectedPage = number;
    };
    ComputeDetectorsComponent.prototype.onItemSelect = function (line) {
        var detectLine = "";
        for (var _i = 0, _a = Object.keys(line.selectedItems); _i < _a.length; _i++) {
            var i = _a[_i];
            detectLine = detectLine + "," + line.selectedItems[i].detect_value;
        }
        this.csvLines[line.lineNumber - 1].wideeyesCategory = detectLine.substr(1);
    };
    ComputeDetectorsComponent.prototype.onDeSelect = function (line) {
        var detectLine = "";
        for (var _i = 0, _a = Object.keys(line.selectedItems); _i < _a.length; _i++) {
            var i = _a[_i];
            detectLine = detectLine + "," + line.selectedItems[i].detect_value;
        }
        this.csvLines[line.lineNumber - 1].wideeyesCategory = detectLine.substr(1);
    };
    ComputeDetectorsComponent.prototype.showProducts = function (line, content) {
        var _this = this;
        this.isLoading = true;
        var options = {
            size: 'lg',
            windowClass: 'animated bounceInUp',
            beforeDismiss: function () {
                return true;
            }
        };
        this.modalService.open(content, options).result.then(function (result) {
        }, function (reason) {
            console.log("reason ", reason);
            _this.modalProducts = [];
        });
        if (this.lineNumber === -1 || this.lineNumber !== line.lineNumber) {
            this.lineNumber = line.lineNumber;
            this.modalProductPageNum = 1;
        }
        else {
            this.modalProductPageNum++;
        }
        this.productService.getProductMany(line.clientGender, line.clientCat, line.clientSubcat, this.modalProductPageNum.toString(), this.limit, this.token, null).then(function (data) {
            if (data.results && data.results.length) {
                _this.modalProducts = data.results;
            }
            if (data.results && data.results.length < 10) {
                _this.modalProductPageNum = 0;
            }
            _this.isLoading = false;
        }).catch(function (error) {
            _this.toast.setMessage(JSON.stringify(error), "danger");
        });
    };
    ComputeDetectorsComponent.prototype.postUsersCSVDetectors = function () {
        var _this = this;
        this.isLoading = true;
        var csvToSend = '';
        for (var i = this.csvLines.length - 1; i >= 0; i--) {
            var line = this.csvLines[i];
            csvToSend = csvToSend + "\"" + line.clientCat + "\",\"" + line.clientSubcat + "\",\"" + line.clientGender + "\",\"" + line.prductsNumber + "\",\"" + line.wideeyesCategory + "\",\"" + line.wideeyesGender + "\"\n";
        }
        this.configService.postCsvMapping(this.user_id, csvToSend).subscribe(function (data) {
            _this.isLoading = false;
            _this.toast.setMessage("Mapping saved", 'success');
        });
    };
    ComputeDetectorsComponent.prototype.CSVtoArray = function (text) {
        var re_valid = /^\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*(?:,\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*)*$/;
        var re_value = /(?!\s*$)\s*(?:'([^'\\]*(?:\\[\S\s][^'\\]*)*)'|"([^"\\]*(?:\\[\S\s][^"\\]*)*)"|([^,'"\s\\]*(?:\s+[^,'"\s\\]+)*))\s*(?:,|$)/g;
        // Return NULL if input string is not well formed CSV string.
        if (!re_valid.test(text)) {
            return null;
        }
        var a = []; // Initialize array to receive values.
        text.replace(re_value, // "Walk" the string using replace with callback.
        function (m0, m1, m2, m3) {
            // Remove backslash from \' in single quoted values.
            if (m1 !== undefined) {
                a.push(m1.replace(/\\'/g, "'"));
            }
            else if (m2 !== undefined) {
                // Remove backslash from \" in double quoted values.
                a.push(m2.replace(/\\"/g, '"'));
            }
            else if (m3 !== undefined) {
                a.push(m3);
            }
            return ''; // Return empty string.
        });
        // Handle special case of empty last value.
        if (/,\s*$/.test(text)) {
            a.push('');
        }
        return a;
    };
    return ComputeDetectorsComponent;
}());
export { ComputeDetectorsComponent };
