import { OnInit } from '@angular/core';
import { AuthService } from '../../services/AuthService';
import { ToastComponent } from '../../shared/toast/toast.component';
import { UserService } from '../../services/user.service';
import { Shared } from "../../util/service/shared.methods";
var HeaderComponent = /** @class */ (function () {
    function HeaderComponent(auth, toast, userservice, share) {
        this.auth = auth;
        this.toast = toast;
        this.userservice = userservice;
        this.share = share;
        this.showServices = true;
    }
    HeaderComponent.prototype.ngOnInit = function () {
    };
    HeaderComponent.prototype.services = function () {
        this.showServices = !this.showServices;
    };
    HeaderComponent.prototype.Closeservices = function () {
        if (this.showServices === false) {
            this.showServices = true;
        }
    };
    HeaderComponent.prototype.RouteTo = function (path) {
        this.showServices = !this.showServices;
        this.share.RouteTo(path);
    };
    return HeaderComponent;
}());
export { HeaderComponent };
