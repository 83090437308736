<div class="container">
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <h5 class="card-header">Featured</h5>
        <div class="card-body">
          <h5 class="card-title">404 Not Found</h5>
          <p class="card-text">The page you requested was not found.</p>
          <a class="btn btn-primary" routerLink="/">Homepage</a>
        </div>
      </div>
    </div>
  </div>
</div>
