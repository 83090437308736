import { Router } from '@angular/router';
import { AuthService } from './AuthService';
var AuthGuardLogin = /** @class */ (function () {
    function AuthGuardLogin(auth, router) {
        this.auth = auth;
        this.router = router;
    }
    AuthGuardLogin.prototype.canActivate = function () {
        if (!this.auth.isAuthenticated()) {
            this.router.navigate(['login']);
            return false;
        }
        return true;
    };
    return AuthGuardLogin;
}());
export { AuthGuardLogin };
