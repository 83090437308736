/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./home.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/toast/toast.component.ngfactory";
import * as i3 from "../../shared/toast/toast.component";
import * as i4 from "./home.component";
import * as i5 from "../../services/AuthService";
import * as i6 from "@angular/router";
import * as i7 from "../../shared/modal/modal.component";
import * as i8 from "../../util/service/shared.methods";
var styles_HomeComponent = [i0.styles];
var RenderType_HomeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HomeComponent, data: {} });
export { RenderType_HomeComponent as RenderType_HomeComponent };
export function View_HomeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-toast", [], null, null, null, i2.View_ToastComponent_0, i2.RenderType_ToastComponent)), i1.ɵdid(1, 49152, null, 0, i3.ToastComponent, [], { message: [0, "message"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 30, "div", [["class", "container my-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 29, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 28, "div", [["class", "col-md-12 noselect"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Wide Eyes Internal Services"])), (_l()(), i1.ɵeld(7, 0, null, null, 12, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "button", [["class", "btn btn-dark"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.RouteTo("styleAdvisor") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Style Advisor Dashboard"])), (_l()(), i1.ɵeld(11, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "button", [["class", "btn btn-dark"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.RouteTo("visionproducts") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Vision Products Dashboard"])), (_l()(), i1.ɵeld(14, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "button", [["class", "btn btn-dark"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.RouteTo("computeDetectors") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Compute Detectors Dashboard"])), (_l()(), i1.ɵeld(17, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 1, "button", [["class", "btn btn-dark"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.RouteTo("") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Gender Mapping Dashboard"])), (_l()(), i1.ɵeld(20, 0, null, null, 12, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 1, "button", [["class", "btn btn-dark"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.RouteTo("") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Annotator Dashboard"])), (_l()(), i1.ɵeld(24, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 1, "button", [["class", "btn btn-dark"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.RouteTo("") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Backoffice Dashboard"])), (_l()(), i1.ɵeld(27, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(28, 0, null, null, 1, "button", [["class", "btn btn-dark"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.RouteTo("") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Imports Control Dashboard"])), (_l()(), i1.ɵeld(30, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(31, 0, null, null, 1, "button", [["class", "btn btn-dark"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.RouteTo("") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Lorem Ipsum Dashboard"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.toast.message; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_HomeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-home", [], null, null, null, View_HomeComponent_0, RenderType_HomeComponent)), i1.ɵdid(1, 114688, null, 0, i4.HomeComponent, [i5.AuthService, i6.Router, i3.ToastComponent, i7.ModalComponent, i8.Shared], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HomeComponentNgFactory = i1.ɵccf("app-home", i4.HomeComponent, View_HomeComponent_Host_0, {}, {}, []);
export { HomeComponentNgFactory as HomeComponentNgFactory };
