import { Router } from '@angular/router';
import { UserService } from './user.service';
import { User } from '../shared/models/user.model';
import { map } from 'rxjs/operators';
var AuthService = /** @class */ (function () {
    function AuthService(userService, router) {
        this.userService = userService;
        this.router = router;
        this.loggedIn = false;
        this.token = null;
        this.user_id = null;
        this.loggedInUser = 0;
        this.currentUser = new User();
        this.token = localStorage.getItem('access_token');
        this.user_id = localStorage.getItem('user_id');
    }
    AuthService.prototype.isAuthenticated = function () {
        if (this.token) {
            return true;
        }
        return false;
    };
    AuthService.prototype.login = function (emailAndPassword) {
        var _this = this;
        return this.userService
            .login(emailAndPassword)
            .pipe(map(function (res) {
            if (res.access_token) {
                localStorage.setItem('access_token', res.access_token);
                _this.loggedIn = false;
                _this.token = res.access_token;
                return _this.loggedIn;
            }
            else {
                throw new Error('Invalid Credentials');
            }
        }));
    };
    AuthService.prototype.getUser = function (token) {
        var _this = this;
        return this.userService.getUser(token)
            .pipe(map(function (res) {
            if (res) {
                localStorage.setItem('user_id', res[0].user_id);
                localStorage.setItem('apikey', res[0].hash);
                _this.user_id = localStorage.getItem('user_id');
                return true;
            }
            else {
                throw new Error('Could not get user');
            }
        }));
    };
    AuthService.prototype.logout = function () {
        localStorage.removeItem('access_token');
        localStorage.removeItem('user_id');
        this.loggedIn = false;
        this.user_id = null;
        this.currentUser = new User();
        this.router.navigate(['/account/login']);
    };
    return AuthService;
}());
export { AuthService };
