import { Component, OnInit } from '@angular/core';
import { ToastComponent } from "../../shared/toast/toast.component";
import { ModalComponent } from "../../shared/modal/modal.component";
import { LookService } from "../../services/LookService";
import { ProductService } from "../../services/ProductService";
import { ErrFmt } from "../../util/helpers/err.helper";
import { Router } from '@angular/router';
import { AuthService } from '../../services/AuthService';
import { LookGenerationTypes, LookRecommendationTypes, LookNoCategories, LookNoCategoriesImages } from "../../shared/models/utils.models";
import { ProductStatus } from "../../shared/models/status.model";
import * as _ from 'lodash';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  imagesList = [];
  numOfCandidates = 0;
  loadImage = false;
  looks = [];
  preSelectedProducts = {};
  isLoading = true;
  isLoadingCount = true;
  loggedUser = 0;
  recommends = {};
  recommendsNotFound = {};
  recommendsNoCandidates = {};
  look_count = [];
  pager: any = {};
  pagedItems: any[];
  page_num = "1";
  categories_data = {};
  genders = [];
  categories = {};
  subcategories = {};
  objectKeys = Object.keys;
  selectedGender = null;
  selectedCategory = null;
  selectedSubcategory = null;
  selectedGenerationType = null;
  selectedRecommendationType = null;
  selectedNoCategory = null;
  selectedNoCategoryToAdd = null;
  selectedFilter = 'time';
  token: string;
  product_uid: string;
  hidePagination: boolean;
  showAdvance: boolean;
  lazyLoad: boolean;
  startAt: string;
  endAt: string;
  includeInactiveProducts = true;
  containUid = null;
  nextToken = null;
  nextTokenArray = {};
  nextTokenIndexKey = 0;
  owlDateTime = null;
  lookTypes = new LookGenerationTypes();
  lookRecommendationTypes = new LookRecommendationTypes();
  lookNoCategories = new LookNoCategories();
  lookNoCategoriesImages = new LookNoCategoriesImages();
  default_gen_types = Object.values(this.lookTypes);
  default_recom_types = Object.values(this.lookRecommendationTypes);
  default_no_categories = Object.values(this.lookNoCategories);
  main_products_of_looks = {};
  status = new ProductStatus();
  apikey: string;
  constructor(
    private auth: AuthService,
    private lookService: LookService,
    private productService: ProductService,
    private router: Router,
    public toast: ToastComponent,
    public modal: ModalComponent) {
  }

  async ngOnInit() {
    this.showAdvance = false;
    this.lazyLoad = true;
    this.token = localStorage.getItem("access_token");
    this.auth.user_id = localStorage.getItem("user_id");
    this.apikey = localStorage.getItem("apikey");
    if (this.auth.isAuthenticated()) {
      this.auth.loggedIn = true;
      this.router.navigate(['/styleadvisor']);
      this.getCategoryData();
      this.getLooksMany(true);
    } else {
      this.auth.loggedIn = false;
      this.router.navigate(['/account/login']);
    }
  }

  async getLookByUid(product_uid: string) {
    if (product_uid && product_uid !== "") {
      await this.lookService.getLookByUid(product_uid, this.token).then(data => {
        this.hidePagination = true;
        this.looks = data.results;
        this.getRecommends();
      }).catch(error => {
        this.toast.setMessage("Look not found", 'danger');
      });
    } else {
      this.toast.setMessage("Type product uid", 'info');
    }
  }

  getLookByContainUid(product_uid) {
    if (product_uid && product_uid !== "") {
      this.isLoading = true;
      this.lookService.containsUid(product_uid, this.token).subscribe(
        (data: any) => {
          this.hidePagination = true;
          this.looks = data.results;
          this.getRecommends();
          this.isLoading = false;
          if (!this.looks.length) {
            this.toast.setMessage("There is not any look with given candidate UID", 'info');
          }
        },
        error => this.toast.setMessage(ErrFmt(error), 'danger'),
        () => this.isLoading = false,
      );
    } else {
      this.toast.setMessage("Type product uid", 'info');
    }
  }

  deleteProductLook(look: any, look_product: any) {
    if (confirm("Are you sure you want to delete this candidate?")) {
      this.lookService.deleteProductLook(look.id, look_product.id, this.token).subscribe(
        (data: any) => {
          if (data.results.length > 0) {
            look.products = look.products.filter(obj => obj.match !== look_product.match);
            this.toast.setMessage("Candidate deleted sucessfully", 'success');
          } else {
            this.toast.setMessage("Unknown error occurred", 'danger');
          }
        },
        error => this.toast.setMessage(ErrFmt(error), 'danger'),
        () => this.isLoading = false,
      );
    }
  }

  getNoCandidateImage(noCategory: any) {

  }

  async getPreSelectedProducts(looks: any) {
    for (const look of looks) {
      const idsPreSelected = look.preSelectedProducts;
      this.preSelectedProducts[look.id] = [];
      const futures = [];
      for (const uid of idsPreSelected) {
        futures.push(this.productService.getProduct(uid, this.token, this.status.active).catch(error => error));
      }
      for await (const response of futures) {
        if (response && response.results) {
          for (const product of response.results) {
            if (product.uid) { this.preSelectedProducts[look.id].push(product); }
          }
        }
      }
    }
  }

  async getRecommends() {
    this.isLoading = true;
    this.recommends = {};
    this.recommendsNotFound = {};
    this.recommendsNoCandidates = {};
    for (const i of Object.keys(this.looks)) {
      const look = this.looks[i];
      const products = look.products;
      this.get_look_product(look.uid);
      for (const j of Object.keys(products)) {
        if (products[j].hasOwnProperty('match')) {
          const uid = products[j].match;
          if ( uid !== null && uid !== '') {
            await this.productService.getProduct(uid, this.token, "active").then(data => {
              const product = data.results[0];
              if (product) {
                product.visual_status = "active";
                this.recommends[product.uid] = product;
              }
            }).catch(() => {
              if (this.includeInactiveProducts) {
                this.productService.getProduct(uid, this.token, "inactive").then(data => {
                  const product = data.results[0];
                  if (product) {
                    product.visual_status = "inactive";
                    this.recommends[product.uid] = product;
                  }
                }).catch(() => {
                  this.recommendsNotFound[uid] = uid;
                });
              } else {
                this.recommendsNotFound[uid] = uid;
              }
            });
          } else {
            this.recommendsNoCandidates[products[j].id] = products[j].id;
          }
        } else {
          this.recommendsNoCandidates[products[j].id] = products[j].id;
        }

      }
    }
    await this.getPreSelectedProducts(this.looks);
    this.isLoading = false;
  }

  async get_look_product(uid: string) {
    const data_active = await this.productService.getProduct(uid, this.token, "active");
    if (data_active.results && data_active.results.length) {
      this.main_products_of_looks[uid] = data_active.results[0];
    }
  }

  getCountLooks() {
    this.isLoadingCount = true;
   this.lookService.countLooksManyV2(this.selectedGender,
         this.selectedCategory,
         this.selectedSubcategory,
         this.selectedGenerationType,
         this.selectedRecommendationType,
         this.selectedNoCategory,
         this.startAt,
         this.endAt,
         this.token).then(
           (data: any) => {
             for (let i = 1; i <= data.result; i++) {
            this.look_count.push(i);
            this.isLoadingCount = false;
          }
        },
        error => {
          if (error.status === 401) {
            this.toast.setMessage("Session expired, please log in", "danger");
            console.log("Unauthorized!", error.status);
            this.router.navigate(['/account/login']);
          }
        }
      );
  }

  getCategoryData() {
    this.productService.getCategories(this.token).then(data => {
      this.categories_data = data.categories;
      this.genders = Object.keys(this.categories_data);
      for (const i of Object.keys(this.categories_data)) {
        this.categories[i] = this.categories_data[i].map(key => ({ "category": key.name, "subcategory": key.subcategories.map(val => (val.name)) }));
        for (const j of Object.keys(this.categories[i])) {
          this.subcategories[i + this.categories[i][j]["category"]] = this.categories[i][j]["subcategory"];
        }
      }
    });
  }

  getPreviousBatch() {
    if (this.nextTokenIndexKey > 1) {
      this.nextTokenIndexKey = this.nextTokenIndexKey - 2;
      const last_token = this.nextTokenArray[this.nextTokenIndexKey];
      this.getLooksMany(false, last_token);
    }
  }

  getNextBatch() {
    const last_token = this.nextTokenArray[this.nextTokenIndexKey];
    this.getLooksMany(false, last_token);
  }

  async getLooksMany(all: boolean, nextToken = null) {
    this.isLoading = true;
    this.hidePagination = false;
    this.looks = [];
    this.recommends = {};
    this.recommendsNotFound = {};
    this.main_products_of_looks = {};
    if (!nextToken) {
      this.look_count = [];
      this.nextTokenArray = {};
      this.nextTokenIndexKey = 0;
      this.getCountLooks();
    }
    if (all) {
      this.clearFilters();
    }
    try {
      const data = await this.lookService.getLooksManyV2(
        this.selectedGender,
        this.selectedCategory,
        this.selectedSubcategory,
        this.selectedGenerationType,
        this.selectedRecommendationType,
        this.selectedNoCategory,
        this.startAt,
        this.endAt,
        nextToken,
        this.token);
      this.nextToken = data.nextToken;
      this.nextTokenIndexKey++;
      this.nextTokenArray[this.nextTokenIndexKey] = this.nextToken;
      this.looks = data.results;
      this.isLoading = false;
      window.scroll(0, 0);
      if (!this.looks.length) {
        this.toast.setMessage("There is not any look", 'info');
      }
      this.getRecommends();
    } catch (e) {
      this.toast.setMessage("Interrupted getting all looks", 'danger');
    }
  }

  reset_look(priority, look) {
    if (confirm("Are you sure you want to reset the look?")) {
      priority = JSON.parse(priority);
      this.lookService.resetLook(look.id, priority, this.token).subscribe(
        (data: any) => {
          if (data.errors && data.errors.length) {
            this.toast.setMessage("Unknown error occured", 'danger');
          } else {
            this.toast.setMessage("Look reset correctly", 'success');
          }
        }
      );
    }
  }

  generate_cache(type, look) {
    if (confirm("Are you sure you want to generate the cache?")) {
      this.lookService.generateCache(look.uid, type, this.token, this.apikey).subscribe(
        (data: any) => {
          console.log(data);
        }
      );
    }
  }

  changeMainImg(img, look) {
    for (const i in this.looks) {
      if (this.looks[i].uid === look.uid) {
        if (this.looks[i].alternativeImages.indexOf(this.looks[i].imageUrl) === -1) {
          this.looks[i].alternativeImages.push(this.looks[i].imageUrl);
        }
        this.looks[i].imageUrl = img;
      }
    }
  }
  toggleAdvance() {
    this.showAdvance = !this.showAdvance;
  }

  onLoadLazy() {
    this.lazyLoad = false;
  }
  selectGender(value) {
    this.selectedFilter = "categorization";
    this.selectedGender = value;
    this.selectedCategory = null;
    this.selectedSubcategory = null;
  }

  selectCategory(value) {
    this.selectedCategory = value;
    this.selectedSubcategory = null;
  }

  selectSubcategory(value) {
    this.selectedSubcategory = value;
  }

  selectGenerationType(value) {
    this.selectedGenerationType = value;
  }
  selectorRecommendationType(value) {
    this.selectedRecommendationType = value;
    if (value !== this.lookRecommendationTypes.no) {
      this.selectedNoCategory = '';
    }
  }
  selectorNoCategory(value) {
    this.selectedNoCategory = value;
  }

  selectNoCategoryToAdd(value) {
    this.selectedNoCategoryToAdd = value;
  }

  selectRecommendationType(look, product) {
     // Clear noCategory if the recommendation type is not 'NO'
     if (product.recommendationType !== this.lookRecommendationTypes.no) {
       product.noCategory = ''; // Clear the value in the product
       look.products.forEach(prod => {
         if (prod.match === product.uid) {
           prod.noCategory = '';
         }
       });
     }
     this.lookService.putLook(look, this.token).subscribe(
       (data: any) => {
         if (data.errors.length > 0) {
           this.toast.setMessage("Unknown error occurred", 'danger');
         } else {
           this.toast.setMessage("Recommendation type saved successfully", 'success');
         }
       },
       error => this.toast.setMessage(ErrFmt(error), 'danger'),
       () => this.isLoading = false,
     );
  }

    addNoCandidate(look) {
       // Clear noCategory if the recommendation type is not 'NO'
       if (this.selectedNoCategoryToAdd !== null && this.selectedNoCategoryToAdd !== '') {
         const product = {
          noCategory: this.selectedNoCategoryToAdd,
          recommendationType: this.lookRecommendationTypes.no
         };
         look.products.push(product);
       }

       this.lookService.putLook(look, this.token).subscribe(
         (data: any) => {
           if (data.errors.length > 0) {
             this.toast.setMessage("Unknown error occurred", 'danger');
           } else {
             this.toast.setMessage("Recommendation type saved successfully", 'success');
           }
         },
         error => this.toast.setMessage(ErrFmt(error), 'danger'),
         () => this.isLoading = false,
       );
    }

  selectByAnnotation(value) {
    if (value.id === "owl-dt-picker-0") {
      if (value.selected) {
        this.startAt = value.selected.toISOString();
      } else {
        this.startAt = null;
      }
    }
    if (value.id === "owl-dt-picker-1") {
      if (value.selected) {
        this.endAt = value.selected.toISOString();
      } else {
        this.endAt = null;
      }
    }
    this.selectedFilter = "time";
  }

  clearFilters() {
    this.selectedGender = null;
    this.selectedCategory = null;
    this.selectedSubcategory = null;
    this.startAt = null;
    this.endAt = null;
    this.nextToken = null;
    this.selectedGenerationType = null;
    this.selectedRecommendationType = null;
    this.selectedNoCategory = null;
  }

  async validateLook(look) {
    if (look.products.length > 0) {
      const main_prd = look.products.filter(obj => obj.match === look.uid);
      if (main_prd && main_prd.length) {
        if (look.products.length === 5) {
          await this.postPerfectMatch(look, main_prd[0]);
        } else {
          if (confirm("the looks should contain 5 elements: STILL VALIDATE ?")) {
            await this.postPerfectMatch(look, main_prd[0]);
          }
        }
      } else {
        this.toast.setMessage("Main product is not in the candidates", 'info');
      }
    } else {
      this.toast.setMessage("There is not any candidate", 'info');
    }
  }

  async postPerfectMatch(look, main_prd) {
    await this.lookService.setPerfectMatch(look.id, main_prd.id, main_prd.match, this.token);
    this.toast.setMessage("Correctly validated", "success");
    look.generationType = "manual";
  }

  inactiveProductsAction(event) {
    this.includeInactiveProducts = event.target.checked;
  }

  goToConfig() {
    this.router.navigate(['/styleadvisor/configs']);
  }

  getDate(date: string) {
    return new Date(date).toLocaleString();
  }
}
