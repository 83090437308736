var ToastComponent = /** @class */ (function () {
    function ToastComponent() {
        this.message = { body: '', type: '' };
    }
    ToastComponent.prototype.setMessage = function (body, type, time) {
        var _this = this;
        if (time === void 0) { time = 3000; }
        this.message.body = body;
        this.message.type = type;
        setTimeout(function () {
            _this.message.body = '';
        }, time);
    };
    return ToastComponent;
}());
export { ToastComponent };
