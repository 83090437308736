var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { ToastComponent } from "../../../shared/toast/toast.component";
import { ModalComponent } from "../../../shared/modal/modal.component";
import { ProductService } from "../../../services/ProductService";
import { Router } from '@angular/router';
import { AuthService } from '../../../services/AuthService';
import { ConfigService } from '../../../services/ConfigService';
import { PagerService } from '../../../shared/page/pager.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { moveItemInArray } from '@angular/cdk/drag-drop';
var ConfigPagesComponent = /** @class */ (function () {
    function ConfigPagesComponent(auth, productService, configservice, router, toast, modal, modalService, pagerService) {
        this.auth = auth;
        this.productService = productService;
        this.configservice = configservice;
        this.router = router;
        this.toast = toast;
        this.modal = modal;
        this.modalService = modalService;
        this.pagerService = pagerService;
        this.newCatFallBack = "";
        this.dropdownSettings = {};
        this.categories_data = [];
        this.isLoading = false;
        this.gender_cat_line = [];
        this.gender_cat_mapping_obj = {};
        this.blackListBody = [];
        this.unselect_line = -1;
        this.showJsonBody = "";
        this.addNewMapPair = false;
        this.blacklisted = {};
        this.selected_option = { "map_id": null, fallback_value: null };
    }
    ConfigPagesComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.token = localStorage.getItem("access_token");
                this.auth.user_id = localStorage.getItem("user_id");
                if (this.auth.isAuthenticated()) {
                    this.auth.loggedIn = true;
                    this.router.navigate(['/styleadvisor/configs']);
                    this.loadSupercat(this.auth.user_id);
                    this.loadConfigs();
                }
                else {
                    this.auth.loggedIn = false;
                    this.router.navigate(['/account/login']);
                }
                return [2 /*return*/];
            });
        });
    };
    ConfigPagesComponent.prototype.isArray = function (obj) {
        return Array.isArray(obj);
    };
    ConfigPagesComponent.prototype.loadConfigs = function () {
        this.DefaultCatTypeFallBack = [
            { map_id: 1, fallback_value: "outerwear" },
            { map_id: 2, fallback_value: "sweatshirt|sweaters" },
            { map_id: 3, fallback_value: "top" },
            { map_id: 4, fallback_value: "bottom" },
            { map_id: 5, fallback_value: "one-piece" },
            { map_id: 6, fallback_value: "shoes" },
            { map_id: 7, fallback_value: "bags" },
            { map_id: 8, fallback_value: "accessories" },
            { map_id: 9, fallback_value: "intimates|swimwear" },
        ];
        this.OrderedCatTypeFallBack = JSON.parse(JSON.stringify(this.DefaultCatTypeFallBack));
    };
    ConfigPagesComponent.prototype.getDropDownSettings = function (field_name) {
        return {
            singleSelection: true,
            idField: 'map_id',
            textField: field_name,
            itemsShowLimit: 1,
            allowSearchFilter: true,
            clearSearchFilter: true,
            enableCheckAll: false,
        };
    };
    ConfigPagesComponent.prototype.loadSupercat = function (user_id) {
        return __awaiter(this, void 0, void 0, function () {
            var supercats, i, prop, innerObj;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isLoading = true;
                        this.DetectorMappings = [];
                        return [4 /*yield*/, this.configservice.get_det_to_supercat(user_id)];
                    case 1:
                        supercats = _a.sent();
                        supercats = supercats.mapping;
                        i = 1;
                        for (prop in supercats) {
                            if (supercats.hasOwnProperty(prop)) {
                                innerObj = { map_id: i++, detect_value: prop, supercat: [supercats[prop]] };
                                this.DetectorMappings.push(innerObj);
                            }
                        }
                        this.isLoading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    ConfigPagesComponent.prototype.addCatFallback = function (value) {
        if (value) {
            var temp = this.OrderedCatTypeFallBack;
            var last_id = temp[temp.length - 1].map_id;
            temp.push({ map_id: last_id + 1, fallback_value: value });
            this.newCatFallBack = "";
            this.OrderedCatTypeFallBack = JSON.parse(JSON.stringify(temp));
        }
    };
    ConfigPagesComponent.prototype.removeFallbackFromList = function (value) {
        var indexT = this.OrderedCatTypeFallBack.indexOf(value);
        this.OrderedCatTypeFallBack.splice(indexT, 1);
        for (var _i = 0, _a = Object.keys(this.OrderedCatTypeFallBack); _i < _a.length; _i++) {
            var i = _a[_i];
            this.OrderedCatTypeFallBack[i].map_id = parseInt(i, 1);
        }
    };
    ConfigPagesComponent.prototype.drop = function (event) {
        moveItemInArray(this.OrderedCatTypeFallBack, event.previousIndex, event.currentIndex);
    };
    ConfigPagesComponent.prototype.saveCatFallbackOrder = function () {
        var order_array = [];
        for (var _i = 0, _a = Object.keys(this.OrderedCatTypeFallBack); _i < _a.length; _i++) {
            var i = _a[_i];
            order_array.push(this.OrderedCatTypeFallBack[i].fallback_value);
        }
        console.log(order_array);
    };
    ConfigPagesComponent.prototype.addFallbackToList = function (value) {
        var array_len = this.OrderedCatTypeFallBack.length;
        this.OrderedCatTypeFallBack.push({ map_id: array_len + 1, fallback_value: value.fallback_value });
    };
    ConfigPagesComponent.prototype.loadCategories = function () {
        var _this = this;
        this.gender_cat_line = [];
        this.gender_cat_mapping_obj = [];
        this.isLoading = true;
        this.productService.getCategories(this.token).then(function (data) {
            _this.categories_data = data.categories;
            _this.isLoading = false;
            for (var _i = 0, _a = Object.keys(_this.categories_data); _i < _a.length; _i++) {
                var i = _a[_i];
                var gender = i;
                for (var _b = 0, _c = Object.keys(_this.categories_data[i]); _b < _c.length; _b++) {
                    var j = _c[_b];
                    var category = _this.categories_data[i][j].name;
                    for (var _d = 0, _e = Object.keys(_this.categories_data[i][j].subcategories); _d < _e.length; _d++) {
                        var k = _e[_d];
                        var subcategory = _this.categories_data[i][j].subcategories[k].name;
                        _this.gender_cat_line.push(_this.generateColumnLine(gender, category, subcategory));
                    }
                }
            }
            _this.loadBlackList();
        });
    };
    ConfigPagesComponent.prototype.generateColumnLine = function (gender, category, subcategory, blackCategory, blackSubcategory, AvoidLook, AvoidStyleAdvisor, AvoidRecognize) {
        if (blackCategory === void 0) { blackCategory = false; }
        if (blackSubcategory === void 0) { blackSubcategory = false; }
        if (AvoidLook === void 0) { AvoidLook = false; }
        if (AvoidStyleAdvisor === void 0) { AvoidStyleAdvisor = false; }
        if (AvoidRecognize === void 0) { AvoidRecognize = false; }
        return {
            "gender": gender,
            "category": category,
            "subcategory": subcategory,
            "blackCategory": blackCategory,
            "blackSubcategory": blackSubcategory,
            "AvoidLook": AvoidLook,
            "AvoidStyleAdvisor": AvoidStyleAdvisor,
            "AvoidRecognize": AvoidRecognize
        };
    };
    ConfigPagesComponent.prototype.loadBlackList = function () {
        return __awaiter(this, void 0, void 0, function () {
            var blackList, _i, _a, obj, _b, _c, index, blackedObject, gender, category, subcategory, avoidCreateLook, avoidRecognize, avoidStyleAdvisor;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0: return [4 /*yield*/, this.configservice.get_cat_blacklist(this.auth.user_id)];
                    case 1:
                        blackList = _d.sent();
                        for (_i = 0, _a = Object.keys(this.gender_cat_line); _i < _a.length; _i++) {
                            obj = _a[_i];
                            for (_b = 0, _c = Object.keys(blackList); _b < _c.length; _b++) {
                                index = _c[_b];
                                blackedObject = blackList[index];
                                gender = blackedObject.gender;
                                category = blackedObject.category;
                                subcategory = blackedObject.subcategory;
                                avoidCreateLook = blackedObject.avoidCreateLook;
                                avoidRecognize = blackedObject.avoidRecognize;
                                avoidStyleAdvisor = blackedObject.avoidStyleAdvisor;
                                if (gender === this.gender_cat_line[obj].gender && category === this.gender_cat_line[obj].category) {
                                    if (subcategory === null) {
                                        this.gender_cat_line[obj].category = category;
                                        this.gender_cat_line[obj].blackCategory = true;
                                        this.gender_cat_line[obj].AvoidLook = avoidCreateLook;
                                        this.gender_cat_line[obj].AvoidRecognize = avoidRecognize;
                                        this.gender_cat_line[obj].AvoidStyleAdvisor = avoidStyleAdvisor;
                                    }
                                    else if (subcategory === this.gender_cat_line[obj].subcategory) {
                                        this.gender_cat_line[obj].category = category;
                                        this.gender_cat_line[obj].blackCategory = true;
                                        this.gender_cat_line[obj].subcategory = subcategory;
                                        this.gender_cat_line[obj].blackSubcategory = true;
                                        this.gender_cat_line[obj].AvoidLook = avoidCreateLook;
                                        this.gender_cat_line[obj].AvoidRecognize = avoidRecognize;
                                        this.gender_cat_line[obj].AvoidStyleAdvisor = avoidStyleAdvisor;
                                    }
                                }
                            }
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ConfigPagesComponent.prototype.categoryToBlackList = function (line, event, line_num) {
        for (var _i = 0, _a = Object.keys(this.gender_cat_line); _i < _a.length; _i++) {
            var obj = _a[_i];
            if (JSON.stringify(this.gender_cat_line[obj]) === JSON.stringify(line)) {
                if (event.target.id === "category") {
                    this.gender_cat_line[obj].blackCategory = event.target.checked;
                }
                else if (event.target.id === "subcategory") {
                    this.gender_cat_line[obj].blackSubcategory = event.target.checked;
                }
                else {
                    this.gender_cat_line[obj][event.target.id] = event.target.checked;
                }
            }
        }
    };
    ConfigPagesComponent.prototype.saveBlackList = function (content) {
        var BlackList = [];
        for (var _i = 0, _a = Object.keys(this.gender_cat_line); _i < _a.length; _i++) {
            var arg = _a[_i];
            if (!this.gender_cat_line[arg].blackCategory) {
                continue;
            }
            var subcategory_arg = this.gender_cat_line[arg].subcategory;
            var line_body_obj = {
                "gender": this.gender_cat_line[arg].gender,
                "category": this.gender_cat_line[arg].category,
                "subcategory": this.gender_cat_line[arg].blackSubcategory ? subcategory_arg : null,
                "avoidCreateLook": this.gender_cat_line[arg].AvoidLook,
                "avoidStyleAdvisor": this.gender_cat_line[arg].AvoidStyleAdvisor,
                "avoidRecognize": this.gender_cat_line[arg].AvoidRecognize
            };
            BlackList.push(line_body_obj);
        }
        var filteredBlackList = BlackList.filter(function (thing, index) {
            return index === BlackList.findIndex(function (obj) {
                return JSON.stringify(obj) === JSON.stringify(thing);
            });
        });
        var response = this.configservice.set_cat_blacklist(this.auth.user_id, filteredBlackList);
        this.toast.setMessage("Black list saved successfully", "success");
        this.showJsonBody = JSON.stringify(filteredBlackList);
        var options = {
            size: 'lg',
            windowClass: 'animated bounceInUp',
            beforeDismiss: function () {
                return true;
            }
        };
        this.modalService.open(content, options).result.then(function (result) {
        }, function (reason) {
            console.log("reason ", reason);
        });
    };
    ConfigPagesComponent.prototype.saveSupercat = function (content) {
        return __awaiter(this, void 0, void 0, function () {
            var mapping, prop, obj, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isLoading = true;
                        mapping = {};
                        // tslint:disable-next-line: forin
                        for (prop in content) {
                            mapping[content[prop].detect_value] = content[prop].supercat[0];
                        }
                        obj = { "mapping": mapping };
                        return [4 /*yield*/, this.configservice.post_det_to_supercat(this.auth.user_id, obj)];
                    case 1:
                        result = _a.sent();
                        if (result === true) {
                            this.toast.setMessage("New mapping saved successfully", "success");
                        }
                        this.isLoading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    return ConfigPagesComponent;
}());
export { ConfigPagesComponent };
