import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '../shared/models/user.model';
import { Observable } from 'rxjs/internal/Observable';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { promise } from 'selenium-webdriver';

@Injectable()
export class UserService {
  apiURL: string = environment.apiURL;
  constructor(private http: HttpClient) {
  }

  login(credentials): Observable<any> {
    return this.http.post<any>(`${this.apiURL}/login`, credentials);
  }

  getUser(token) {
    return this.http.get(`${this.apiURL}/users/apikeys`, { headers: this.setHeaders(token) });
  }

  getUserId(token): Promise<any> {
    return this.http.get(`${this.apiURL}/users/id`, { headers: this.setHeaders(token) }).toPromise();
  }

  setHeaders(token) {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `bearer ${token}`
    });
  }
}
