var LookGenerationTypes = /** @class */ (function () {
    function LookGenerationTypes(manual, automatic, random, styleAdvisor, styleAdvisorWithManual, empty) {
        if (manual === void 0) { manual = "manual"; }
        if (automatic === void 0) { automatic = "automatic"; }
        if (random === void 0) { random = "random"; }
        if (styleAdvisor === void 0) { styleAdvisor = "styleAdvisor"; }
        if (styleAdvisorWithManual === void 0) { styleAdvisorWithManual = "styleAdvisorWithManual"; }
        if (empty === void 0) { empty = ""; }
        this.manual = manual;
        this.automatic = automatic;
        this.random = random;
        this.styleAdvisor = styleAdvisor;
        this.styleAdvisorWithManual = styleAdvisorWithManual;
        this.empty = empty;
    }
    return LookGenerationTypes;
}());
export { LookGenerationTypes };
var LookRecommendationTypes = /** @class */ (function () {
    function LookRecommendationTypes(perfectMatch, similar, fitb, maybe, no) {
        if (perfectMatch === void 0) { perfectMatch = "perfect_match"; }
        if (similar === void 0) { similar = "similar"; }
        if (fitb === void 0) { fitb = "fitb"; }
        if (maybe === void 0) { maybe = "maybe"; }
        if (no === void 0) { no = "no"; }
        this.perfectMatch = perfectMatch;
        this.similar = similar;
        this.fitb = fitb;
        this.maybe = maybe;
        this.no = no;
    }
    return LookRecommendationTypes;
}());
export { LookRecommendationTypes };
var LookNoCategories = /** @class */ (function () {
    function LookNoCategories(outwear, top, bottom, onePiece, shoes, bag, accessories) {
        if (outwear === void 0) { outwear = "outwear"; }
        if (top === void 0) { top = "top"; }
        if (bottom === void 0) { bottom = "bottom"; }
        if (onePiece === void 0) { onePiece = "one_piece"; }
        if (shoes === void 0) { shoes = "shoes"; }
        if (bag === void 0) { bag = "bag"; }
        if (accessories === void 0) { accessories = "accessories"; }
        this.outwear = outwear;
        this.top = top;
        this.bottom = bottom;
        this.onePiece = onePiece;
        this.shoes = shoes;
        this.bag = bag;
        this.accessories = accessories;
    }
    return LookNoCategories;
}());
export { LookNoCategories };
var LookNoCategoriesImages = /** @class */ (function () {
    function LookNoCategoriesImages(outwear, top, bottom, onepiece, shoes, bag, accessories) {
        if (outwear === void 0) { outwear = "https://we-import-pipeline.s3.eu-west-1.amazonaws.com/fake-products/9999OUTWEAR.png"; }
        if (top === void 0) { top = "https://we-import-pipeline.s3.eu-west-1.amazonaws.com/fake-products/9999TOP.jpg"; }
        if (bottom === void 0) { bottom = "https://we-import-pipeline.s3.eu-west-1.amazonaws.com/fake-products/9999BOTTOM.jpg"; }
        if (onepiece === void 0) { onepiece = "https://we-import-pipeline.s3.eu-west-1.amazonaws.com/fake-products/9999ONEPIECE.png"; }
        if (shoes === void 0) { shoes = "https://we-import-pipeline.s3.eu-west-1.amazonaws.com/fake-products/9999SHOES.png"; }
        if (bag === void 0) { bag = "https://we-import-pipeline.s3.eu-west-1.amazonaws.com/fake-products/9999BAG.png"; }
        if (accessories === void 0) { accessories = "https://we-import-pipeline.s3.eu-west-1.amazonaws.com/fake-products/9999ACCESSORIES.png"; }
        this.outwear = outwear;
        this.top = top;
        this.bottom = bottom;
        this.onepiece = onepiece;
        this.shoes = shoes;
        this.bag = bag;
        this.accessories = accessories;
    }
    LookNoCategoriesImages.prototype.getImageUrl = function (noCategory) {
        if (noCategory !== undefined && noCategory !== null && noCategory !== "") {
            var propertyName = noCategory.replace(/[^a-zA-Z]/g, "").toLowerCase();
            return this[propertyName];
        }
        else {
            return "";
        }
    };
    return LookNoCategoriesImages;
}());
export { LookNoCategoriesImages };
