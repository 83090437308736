import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';


@Injectable()
export class ConfigService {
  urlWeSaasApi: string = environment.apiURL;
  constructor(private http: HttpClient) {}

  get_det_to_supercat(user_id: string): Promise<any> {
    return this.http.get(`${this.urlWeSaasApi}/mapping/mapping/detectors_supercats`, { headers: this.generateHeaders(user_id) }).toPromise();
  }

  post_det_to_supercat(user_id: string, body: any) {
    return this.http.post(`${this.urlWeSaasApi}/mapping/mapping/detectors_supercats`, body, { headers: this.generateHeaders(user_id) }).toPromise();
  }

  get_cat_blacklist(user_id: string): Promise<any> {
    return this.http.get(`${this.urlWeSaasApi}/mapping/mapping/category_blacklist`, { headers: this.generateHeaders(user_id) }).toPromise();
  }

  set_cat_blacklist(user_id: string, body: any): Promise<any> {
    return this.http.post(`${this.urlWeSaasApi}/mapping/mapping/category_blacklist`, body, { headers: this.generateHeaders(user_id) }).toPromise();
  }

  getCsvMapping(user_id: string) {
    return this.http.get(`${this.urlWeSaasApi}/mapping/detectors/csv`, { headers: this.generateHeaders(user_id), responseType: 'text' });
  }

  postCsvMapping(user_id: string, body: any) {
    return this.http.post(`${this.urlWeSaasApi}/mapping/detectors/csv`, body, { headers: this.generateHeaders(user_id, true), responseType: 'text' });
  }

  // TODO: apikey is temporary and will remove when will move to we-saas
  generateHeaders(user_id: string, content = false) {
    const httpHeaders = new HttpHeaders({
      "userId": user_id,
      "apikey": "789655fcb02374a"
    });
    if (content) {
      const headers = httpHeaders.set("Content-Type", "text/csv");
      return headers;
    }
    return httpHeaders;
  }

}
