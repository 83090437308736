import { Component, Input, OnInit, HostListener } from '@angular/core';
import { AuthService } from '../../services/AuthService';
import { ToastComponent } from '../../shared/toast/toast.component';
import { UserService } from '../../services/user.service';
import { Shared } from "../../util/service/shared.methods";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() condition: boolean;
  user_id: string;
  showServices = true;
  constructor(
    public auth: AuthService,
    public toast: ToastComponent,
    public userservice: UserService,
    private share: Shared) {
  }

  ngOnInit() {

  }

  services() {
    this.showServices = !this.showServices;
  }
  Closeservices() {
    if (this.showServices === false) {
      this.showServices = true;
    }
  }

  RouteTo(path: string) {
    this.showServices = !this.showServices;
    this.share.RouteTo(path);
  }
}
