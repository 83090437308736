import { Component, OnInit } from '@angular/core';
import { ToastComponent } from "../../../shared/toast/toast.component";
import { ModalComponent } from "../../../shared/modal/modal.component";
import { ProductService } from "../../../services/ProductService";
import { Router } from '@angular/router';
import { AuthService } from '../../../services/AuthService';
import { UserService } from '../../../services/user.service';
import { ConfigService } from '../../../services/ConfigService';
import * as _ from 'lodash';
import { PagerService } from '../../../shared/page/pager.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import * as $ from 'jquery';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
@Component({
  selector: 'app-styleconfig',
  templateUrl: './styleConfig.component.html',
  styleUrls: ['./styleConfig.component.scss'],
})
export class ConfigPagesComponent implements OnInit {
  token: string;
  DetectorMappings: Array<{ map_id: number, detect_value: string, supercat: Array<string> }>;
  newCatFallBack = "";
  dropdownSettings = {};
  categories_data = [];
  isLoading = false;
  gender_cat_line = [];
  gender_cat_mapping_obj = {};
  blackListBody = [];
  unselect_line = -1;
  showJsonBody = "";
  addNewMapPair = false;
  blacklisted = {};
  DefaultCatTypeFallBack: Array<{ map_id: number, fallback_value: string }>;
  OrderedCatTypeFallBack: Array<{ map_id: number, fallback_value: string }>;
  selected_option = { "map_id": null, fallback_value: null };
  constructor(
    private auth: AuthService,
    private productService: ProductService,
    private configservice: ConfigService,
    private router: Router,
    public toast: ToastComponent,
    public modal: ModalComponent,
    private modalService: NgbModal,
    private pagerService: PagerService) {
  }

  async ngOnInit() {
    this.token = localStorage.getItem("access_token");
    this.auth.user_id = localStorage.getItem("user_id");
    if (this.auth.isAuthenticated()) {
      this.auth.loggedIn = true;
      this.router.navigate(['/styleadvisor/configs']);
      this.loadSupercat(this.auth.user_id);
      this.loadConfigs();
    } else {
      this.auth.loggedIn = false;
      this.router.navigate(['/account/login']);
    }
  }

  isArray(obj: any) {
    return Array.isArray(obj);
  }

  loadConfigs() {
    this.DefaultCatTypeFallBack = [
      { map_id: 1, fallback_value: "outerwear" },
      { map_id: 2, fallback_value: "sweatshirt|sweaters" },
      { map_id: 3, fallback_value: "top" },
      { map_id: 4, fallback_value: "bottom" },
      { map_id: 5, fallback_value: "one-piece" },
      { map_id: 6, fallback_value: "shoes" },
      { map_id: 7, fallback_value: "bags" },
      { map_id: 8, fallback_value: "accessories" },
      { map_id: 9, fallback_value: "intimates|swimwear" },
    ];
    this.OrderedCatTypeFallBack = JSON.parse(JSON.stringify(this.DefaultCatTypeFallBack));
  }

  getDropDownSettings(field_name) {
    return {
      singleSelection: true,
      idField: 'map_id',
      textField: field_name,
      itemsShowLimit: 1,
      allowSearchFilter: true,
      clearSearchFilter: true,
      enableCheckAll: false,
    };
  }

  async loadSupercat(user_id) {
    this.isLoading = true;
    this.DetectorMappings = [];
    let supercats = await this.configservice.get_det_to_supercat(user_id);
    supercats = supercats.mapping;
    let i = 1;
    for (const prop in supercats) {
      if (supercats.hasOwnProperty(prop)) {
        const innerObj = { map_id: i++, detect_value: prop, supercat: [supercats[prop]] };
        this.DetectorMappings.push(innerObj);
      }
    }
    this.isLoading = false;
  }

  addCatFallback(value) {
    if (value) {
      const temp = this.OrderedCatTypeFallBack;
      const last_id = temp[temp.length - 1].map_id;
      temp.push(
        { map_id: last_id + 1, fallback_value: value }
      );
      this.newCatFallBack = "";
      this.OrderedCatTypeFallBack = JSON.parse(JSON.stringify(temp));
    }
  }

  removeFallbackFromList(value) {
    const indexT = this.OrderedCatTypeFallBack.indexOf(value);
    this.OrderedCatTypeFallBack.splice(indexT, 1);
    for (const i of Object.keys(this.OrderedCatTypeFallBack)) {
      this.OrderedCatTypeFallBack[i].map_id = parseInt(i, 1);
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.OrderedCatTypeFallBack, event.previousIndex, event.currentIndex);
  }

  saveCatFallbackOrder() {
    const order_array = [];
    for (const i of Object.keys(this.OrderedCatTypeFallBack)) {
      order_array.push(this.OrderedCatTypeFallBack[i].fallback_value);
    }
    console.log(order_array);
  }

  addFallbackToList(value) {
    const array_len = this.OrderedCatTypeFallBack.length;
    this.OrderedCatTypeFallBack.push(
      { map_id: array_len + 1, fallback_value: value.fallback_value }
    );
  }

  loadCategories() {
    this.gender_cat_line = [];
    this.gender_cat_mapping_obj = [];
    this.isLoading = true;
    this.productService.getCategories(this.token).then(data => {
      this.categories_data = data.categories;
      this.isLoading = false;
      for (const i of Object.keys(this.categories_data)) {
        const gender = i;
        for (const j of Object.keys(this.categories_data[i])) {
          const category = this.categories_data[i][j].name;
          for (const k of Object.keys(this.categories_data[i][j].subcategories)) {
            const subcategory = this.categories_data[i][j].subcategories[k].name;
            this.gender_cat_line.push(this.generateColumnLine(gender, category, subcategory));
          }
        }
      }
      this.loadBlackList();
    });
  }

  generateColumnLine(
    gender,
    category,
    subcategory,
    blackCategory = false,
    blackSubcategory = false,
    AvoidLook = false,
    AvoidStyleAdvisor = false,
    AvoidRecognize = false) {

    return {
      "gender": gender,
      "category": category,
      "subcategory": subcategory,
      "blackCategory": blackCategory,
      "blackSubcategory": blackSubcategory,
      "AvoidLook": AvoidLook,
      "AvoidStyleAdvisor": AvoidStyleAdvisor,
      "AvoidRecognize": AvoidRecognize
    };
  }

  async loadBlackList() {
    const blackList = await this.configservice.get_cat_blacklist(this.auth.user_id);
    for (const obj of Object.keys(this.gender_cat_line)) {
      for (const index of Object.keys(blackList)) {
        const blackedObject = blackList[index];
        const gender = blackedObject.gender;
        const category = blackedObject.category;
        const subcategory = blackedObject.subcategory;
        const avoidCreateLook = blackedObject.avoidCreateLook;
        const avoidRecognize = blackedObject.avoidRecognize;
        const avoidStyleAdvisor = blackedObject.avoidStyleAdvisor;
        if (gender === this.gender_cat_line[obj].gender && category === this.gender_cat_line[obj].category) {
          if (subcategory === null) {
            this.gender_cat_line[obj].category = category;
            this.gender_cat_line[obj].blackCategory = true;
            this.gender_cat_line[obj].AvoidLook = avoidCreateLook;
            this.gender_cat_line[obj].AvoidRecognize = avoidRecognize;
            this.gender_cat_line[obj].AvoidStyleAdvisor = avoidStyleAdvisor;
          } else if (subcategory === this.gender_cat_line[obj].subcategory) {
            this.gender_cat_line[obj].category = category;
            this.gender_cat_line[obj].blackCategory = true;
            this.gender_cat_line[obj].subcategory = subcategory;
            this.gender_cat_line[obj].blackSubcategory = true;
            this.gender_cat_line[obj].AvoidLook = avoidCreateLook;
            this.gender_cat_line[obj].AvoidRecognize = avoidRecognize;
            this.gender_cat_line[obj].AvoidStyleAdvisor = avoidStyleAdvisor;
          }
        }
      }
    }
  }

  categoryToBlackList(line, event, line_num) {
    for (const obj of Object.keys(this.gender_cat_line)) {
      if (JSON.stringify(this.gender_cat_line[obj]) === JSON.stringify(line)) {
        if (event.target.id === "category") {
          this.gender_cat_line[obj].blackCategory = event.target.checked;
        } else if (event.target.id === "subcategory") {
          this.gender_cat_line[obj].blackSubcategory = event.target.checked;
        } else {
          this.gender_cat_line[obj][event.target.id] = event.target.checked;
        }
      }
    }
  }

  saveBlackList(content) {
    const BlackList = [];
    for (const arg of Object.keys(this.gender_cat_line)) {
      if (!this.gender_cat_line[arg].blackCategory) {
        continue;
      }
      const subcategory_arg = this.gender_cat_line[arg].subcategory;
      const line_body_obj = {
        "gender": this.gender_cat_line[arg].gender,
        "category": this.gender_cat_line[arg].category,
        "subcategory": this.gender_cat_line[arg].blackSubcategory ? subcategory_arg : null,
        "avoidCreateLook": this.gender_cat_line[arg].AvoidLook,
        "avoidStyleAdvisor": this.gender_cat_line[arg].AvoidStyleAdvisor,
        "avoidRecognize": this.gender_cat_line[arg].AvoidRecognize
      };
      BlackList.push(line_body_obj);
    }
    const filteredBlackList = BlackList.filter((thing, index) => {
      return index === BlackList.findIndex(obj => {
        return JSON.stringify(obj) === JSON.stringify(thing);
      });
    });
    const response = this.configservice.set_cat_blacklist(this.auth.user_id, filteredBlackList);
    this.toast.setMessage("Black list saved successfully", "success");
    this.showJsonBody = JSON.stringify(filteredBlackList);
    const options: NgbModalOptions = {
      size: 'lg',
      windowClass: 'animated bounceInUp',
      beforeDismiss: () => {
        return true;
      }
    };
    this.modalService.open(content, options).result.then((result) => {
    }, (reason) => {
      console.log("reason ", reason);
    });
  }

  async saveSupercat(content) {
    this.isLoading = true;
    const mapping = {};
    // tslint:disable-next-line: forin
    for (const prop in content) {
      mapping[content[prop].detect_value] = content[prop].supercat[0];
    }
    const obj = { "mapping": mapping };
    const result = await this.configservice.post_det_to_supercat(this.auth.user_id, obj);
    if (result === true) {
      this.toast.setMessage("New mapping saved successfully", "success");
    }
    this.isLoading = false;
  }
}
