import { Component, OnInit } from '@angular/core';
import { ToastComponent } from "../../shared/toast/toast.component";
import { ModalComponent } from "../../shared/modal/modal.component";
import { ProductService } from "../../services/ProductService";
import { ConfigService } from "../../services/ConfigService";
import { ErrFmt } from "../../util/helpers/err.helper";
import * as moment from 'moment';
import { Router } from '@angular/router';
import { AuthService } from '../../services/AuthService';
import { UserService } from '../../services/user.service';
import { NgbModalConfig, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import * as $ from 'jquery';


@Component({
  selector: 'app-computedetectors',
  templateUrl: './computedetectors.component.html',
  styleUrls: ['./computedetectors.component.scss'],
})
export class ComputeDetectorsComponent implements OnInit {
  user_id: string;
  token: string;
  csvLines = [];
  limit = "10";
  dropdownList = [
    { map_id: 1, detect_value: 'accessories' },
    { map_id: 2, detect_value: 'bag' },
    { map_id: 3, detect_value: 'belt' },
    { map_id: 4, detect_value: 'blazer' },
    { map_id: 5, detect_value: 'blouse' },
    { map_id: 6, detect_value: 'bodysuit' },
    { map_id: 7, detect_value: 'boots' },
    { map_id: 8, detect_value: 'bra' },
    { map_id: 9, detect_value: 'bracelet' },
    { map_id: 10, detect_value: 'cape' },
    { map_id: 11, detect_value: 'cardigan' },
    { map_id: 12, detect_value: 'clogs' },
    { map_id: 13, detect_value: 'coat' },
    { map_id: 14, detect_value: 'dress' },
    { map_id: 15, detect_value: 'earrings' },
    { map_id: 16, detect_value: 'flats' },
    { map_id: 17, detect_value: 'glasses' },
    { map_id: 18, detect_value: 'gloves' },
    { map_id: 19, detect_value: 'hat' },
    { map_id: 20, detect_value: 'heels' },
    { map_id: 21, detect_value: 'intimate' },
    { map_id: 22, detect_value: 'jacket' },
    { map_id: 23, detect_value: 'jeans' },
    { map_id: 24, detect_value: 'jumper' },
    { map_id: 25, detect_value: 'jumpsuit' },
    { map_id: 26, detect_value: 'leggings' },
    { map_id: 27, detect_value: 'loafers' },
    { map_id: 28, detect_value: 'necklace' },
    { map_id: 29, detect_value: 'pants' },
    { map_id: 30, detect_value: 'pumps' },
    { map_id: 31, detect_value: 'purse' },
    { map_id: 32, detect_value: 'ring' },
    { map_id: 33, detect_value: 'romper' },
    { map_id: 34, detect_value: 'sandals' },
    { map_id: 35, detect_value: 'scarf' },
    { map_id: 36, detect_value: 'shirt' },
    { map_id: 37, detect_value: 'shoes' },
    { map_id: 38, detect_value: 'shorts' },
    { map_id: 39, detect_value: 'skirt' },
    { map_id: 40, detect_value: 'sneakers' },
    { map_id: 41, detect_value: 'socks' },
    { map_id: 42, detect_value: 'stockings' },
    { map_id: 43, detect_value: 'suit' },
    { map_id: 44, detect_value: 'sunglasses' },
    { map_id: 45, detect_value: 'sweater' },
    { map_id: 46, detect_value: 'sweatshirt' },
    { map_id: 47, detect_value: 't-shirt' },
    { map_id: 48, detect_value: 'tie' },
    { map_id: 49, detect_value: 'tights' },
    { map_id: 50, detect_value: 'top' },
    { map_id: 51, detect_value: 'vest' },
    { map_id: 52, detect_value: 'wallet' },
    { map_id: 53, detect_value: 'watch' },
    { map_id: 54, detect_value: 'wedges' }];
  selectedItems = [];
  pager = [];
  parsialLines = [];
  dropdownSettings = {};
  isLoading = true;
  modalProductPageNum = 0;
  lineNumber = -1;
  modalProducts = [];
  selectedPage = 0;
  constructor(
    private auth: AuthService,
    private userservice: UserService,
    private productService: ProductService,
    private configService: ConfigService,
    private router: Router,
    config: NgbModalConfig,
    private modalService: NgbModal,
    public toast: ToastComponent,
    public modal: ModalComponent) {
  }

  async ngOnInit() {
    if (this.auth.isAuthenticated()) {
      this.auth.loggedIn = true;
      this.user_id = this.auth.user_id;
      this.token = this.auth.token;
      this.getCsvMapping();
    } else {
      this.auth.loggedIn = false;
      this.router.navigate(['/account/login']);
    }
  }


  getCsvMapping() {
    this.configService.getCsvMapping(this.user_id).subscribe(
      (data) => {
        this.generateLines(data);
      },
      (err) => {
        this.toast.setMessage(err.message, 'danger', 5000);
        this.isLoading = false;
      }
    );
  }

  generateLines(text) {
    const lines = text.split('\n');
    lines.pop();
    let lineNumber = 0;
    for (let itrt = lines.length - 1; itrt >= 0; itrt--) {
      const l = lines[itrt];
      lineNumber++;
      const data = this.CSVtoArray(l);
      if (data != null) {
        const selectedItems = [];
        const clientCat = data[0] || '';
        const clientSubcat = data[1] || '';
        const clientGender = data[2] || '';
        const prductsNumber = data[3] || '';
        const wideeyesCategory = data[4] || '';
        const wideeyesGender = data[5] || '';
        if (wideeyesCategory !== "") {
          const mappings = wideeyesCategory.split(",");
          for (const itr of Object.keys(mappings)) {
            selectedItems.push(this.dropdownList.filter(obj => {
              return obj.detect_value === mappings[itr];
            })[0]);
          }
        }
        const newObj = {
          lineNumber: lineNumber,
          clientCat: clientCat,
          clientSubcat: clientSubcat,
          clientGender: clientGender,
          prductsNumber: prductsNumber,
          wideeyesCategory: wideeyesCategory,
          wideeyesGender: wideeyesGender,
          selectedItems: selectedItems
        };
        this.csvLines.push(newObj);
      }
    }
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'map_id',
      textField: 'detect_value',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      clearSearchFilter: true,
      enableCheckAll: false,
      searchPlaceholderText: "click here to remove after select"
    };
    this.isLoading = false;
    for (let i = 0; i < this.csvLines.length; i = i + 200) {
      const quotient = Math.floor(i / 200);
      this.pager.push(quotient);
    }
    this.parsialLines = this.csvLines.slice(0, 200);
    $(document).ready(function () {
      $(".multiselect").on('click', function (event) {
        const input_field = $(this).find('input[type=text]');
        input_field.addClass('fa fa-eraser');
        input_field.focus();
      });
      $(".filter-textbox").on("click", function (event) {
        const input_field = $(this).find('input[type=text]');
        input_field.val("");
      });
    });
  }

  setPage(number) {
    this.parsialLines = this.csvLines.slice(number * 200, (number + 1) * 200);
    this.selectedPage = number;
  }

  onItemSelect(line: any) {
    let detectLine = "";
    for (const i of Object.keys(line.selectedItems)) {
      detectLine = detectLine + "," + line.selectedItems[i].detect_value;
    }
    this.csvLines[line.lineNumber - 1].wideeyesCategory = detectLine.substr(1);
  }
  onDeSelect(line: any) {
    let detectLine = "";
    for (const i of Object.keys(line.selectedItems)) {
      detectLine = detectLine + "," + line.selectedItems[i].detect_value;
    }
    this.csvLines[line.lineNumber - 1].wideeyesCategory = detectLine.substr(1);
  }

  showProducts(line, content) {
    this.isLoading = true;
    const options: NgbModalOptions = {
      size: 'lg',
      windowClass: 'animated bounceInUp',
      beforeDismiss: () => {
        return true;
      }
    };
    this.modalService.open(content, options).result.then((result) => {
    }, (reason) => {
      console.log("reason ", reason);
      this.modalProducts = [];
    });
    if (this.lineNumber === -1 || this.lineNumber !== line.lineNumber) {
      this.lineNumber = line.lineNumber;
      this.modalProductPageNum = 1;
    } else {
      this.modalProductPageNum++;
    }
    this.productService.getProductMany(line.clientGender, line.clientCat, line.clientSubcat, this.modalProductPageNum.toString(), this.limit, this.token, null).then(data => {
      if (data.results && data.results.length) {
        this.modalProducts = data.results;
      }
      if (data.results && data.results.length < 10) {
        this.modalProductPageNum = 0;
      }
      this.isLoading = false;
    }).catch(error => {
      this.toast.setMessage(JSON.stringify(error), "danger");
    });
  }

  postUsersCSVDetectors() {
    this.isLoading = true;
    let csvToSend = '';
    for (let i = this.csvLines.length - 1; i >= 0; i--) {
      const line = this.csvLines[i];
      csvToSend = csvToSend + "\"" + line.clientCat + "\",\"" + line.clientSubcat + "\",\"" + line.clientGender + "\",\"" + line.prductsNumber + "\",\"" + line.wideeyesCategory + "\",\"" + line.wideeyesGender + "\"\n";
    }
    this.configService.postCsvMapping(this.user_id, csvToSend).subscribe(
      (data) => {
        this.isLoading = false;
        this.toast.setMessage("Mapping saved", 'success');
      }
    );
  }

  CSVtoArray(text) {
    const re_valid = /^\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*(?:,\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*)*$/;
    const re_value = /(?!\s*$)\s*(?:'([^'\\]*(?:\\[\S\s][^'\\]*)*)'|"([^"\\]*(?:\\[\S\s][^"\\]*)*)"|([^,'"\s\\]*(?:\s+[^,'"\s\\]+)*))\s*(?:,|$)/g;
    // Return NULL if input string is not well formed CSV string.
    if (!re_valid.test(text)) {
      return null;
    }
    const a = [];                     // Initialize array to receive values.
    text.replace(re_value, // "Walk" the string using replace with callback.
      function (m0, m1, m2, m3) {
        // Remove backslash from \' in single quoted values.
        if (m1 !== undefined) {
          a.push(m1.replace(/\\'/g, "'"));
        } else if (m2 !== undefined) {
          // Remove backslash from \" in double quoted values.

          a.push(m2.replace(/\\"/g, '"'));
        } else if (m3 !== undefined) {
          a.push(m3);
        }
        return ''; // Return empty string.
      });
    // Handle special case of empty last value.
    if (/,\s*$/.test(text)) {
      a.push('');
    }
    return a;
  }

}
