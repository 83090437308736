<ng-template #content let-c="close" class="bg-dark" let-d="dismiss">
  <div class="modal-body bg-dark">
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
    <!-- Modal component to expand image -->
    <div *ngIf='type == "expand"' class="card text-white bg-dark" style="max-width: 26rem;">
      <img class="card-img-top" src="{{look_product.images[0].imageUrl}}" alt="Card image cap">
    </div>
    <!-- Modal component to update candidate -->
    <div *ngIf='type == "updateCandidate"' class="text-white">
      <h3>Change candidate</h3>
      <input type="text" class="form-control" placeholder="type product uid" [(ngModel)]='product_uid' name="uid"
        (keydown)="onKeydown($event)">
      <app-toast [message]="toast.message"></app-toast>
      <i class="fa fa-circle-o-notch fa-spin fa-3x" *ngIf="isLoading"></i>
      <div *ngFor="let product of candidates_similars" style="float:left;">
        <div [ngClass]="{'selected-candidate': look_product.match===product.uid}"
          class="card text-white bg-dark mb-1 m-1" style="max-width: 15rem;" (click)=setPerfectMatch(product)>
          <div class="card-header text-center">similar candidate</div>
          <img class="card-img-top" src="{{product.images[0].imageToProcess || product.images[0].imageUrl}}" alt="Card image cap">
          <div class="card-body" style="line-height: 90%; font-size: 90%;">
            <p class="card-text text-muted small">uid: {{product.uid}}</p>
            <p class="card-text text-muted small">name: {{product.name}}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal component to add new candidate -->
    <div *ngIf='type == "addCandidate"' class="text-white add-candidate">
      <h3>Add candidate</h3>
      <app-toast [message]="toast.message"></app-toast>
      <ul>
        <li>
          <P>Candidate by uid:</P>
        </li>
        <li>
          <input type="text" class="form-control" placeholder="type product uid" [(ngModel)]='product_uid' name="uid">
        </li>
        <li class="buttonInside" (click)="clear()"><i class="fa fa-times" aria-hidden="true"></i></li>
      </ul>

      <ul>
        <ul>
          <li class="list-name">Text Search: </li>
          <li>
            <input type="text" class="form-control" placeholder="type text" [(ngModel)]='textSearch' name="textSearch">
          </li>
          <li class="buttonInside" (click)="clearText()"><i class="fa fa-times" aria-hidden="true"></i></li>
        </ul>
        <ul>
          <li class="list-name">Gender: </li>
          <li>
            <select [disabled]="product_uid !== null && product_uid !== ''" [(ngModel)]="selectedGender"
              class="form-control" (click)="selectGender(selectedGender)">
              <option value="">{{selectedGender}}</option>
              <option *ngFor="let item of genders">{{item}}</option>
            </select>
          </li>
        </ul>
        <ul>
          <li class="list-name">
            category:
          </li>
          <li>
            <select [disabled]="product_uid !== null && product_uid !== ''" [(ngModel)]="selectedCategory"
              class="form-control" (click)="selectCategory(selectedCategory)">
              <option value="">--select category--</option>
              <option *ngFor="let item of categories[selectedGender]"> {{item.category}} </option>
            </select>
          </li>
        </ul>
        <ul>
          <li class="list-name">
            subcategory:
          </li>
          <li>
            <select [disabled]="product_uid !== null && product_uid !== ''" [(ngModel)]="selectedSubcategory"
              class="form-control" (click)="selectSubcategory(selectedSubcategory)">
              <option value="">--select subcategory--</option>
              <option *ngFor="let item of subcategories[selectedGender+selectedCategory]">{{item}}</option>
            </select>
          </li>
        </ul>
        <li>
          <button type="submit" class="btn btn-primary" [disabled]="" (click)="listProducts()"><i class="fa"
              [ngClass]="{'fa-circle-o-notch fa-spin': buttonLoading}"></i>&nbsp; Search</button>
        </li>
      </ul>
      <div *ngFor="let product of new_products" style="float:left;">
        <div [ngClass]="{'selected-candidate': selected_new_candidate.uid===product.uid}"
          class="card text-white bg-dark mb-1 m-1" style="max-width: 15rem;">
          <div class="card-header text-center">new candidate</div>
          <img class="card-img-top" (click)="setNewCandidate(product)" src="{{product.images[0].imageUrl}}"
            alt="Card image cap">
          <div class="card-body" style="line-height: 90%; font-size: 90%;">
            <p class="card-text text-muted small">uid: {{product.uid}}</p>
          </div>
        </div>
      </div>
      <div class="pagination" [hidden]="product_uid !== null && product_uid !== ''">
        <div class="text-center">
          <ul *ngIf="pager.pages && pager.pages.length">
            <li [ngClass]="{disabled:pager.currentPage === 1}">
              <a (click)="setPage(1)">First</a>
            </li>
            <li [ngClass]="{disabled:pager.currentPage === 1}">
              <a (click)="setPage(pager.currentPage - 1)">Previous</a>
            </li>
            <li *ngFor="let page of pager.pages" [ngClass]="{'selected-number': pager.currentPage===page}">
              <a (click)="setPage(page)">{{page}}</a>
            </li>
            <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
              <a (click)="setPage(pager.currentPage + 1)">Next</a>
            </li>
            <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
              <a (click)="setPage(pager.totalPages)">Last</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<div *ngIf='type == "expand"' class="expand-image">
  <i class="fa fa-arrows-alt" style="color:black" aria-hidden="true" (click)="open(content)"></i>
</div>
<div *ngIf='type == "updateCandidate"' class="expand-image">
  <button class="btn btn-block btn-primary" (click)="open(content)">Change Candidate</button>
</div>
<div *ngIf='type == "addCandidate"' class="addonecard">
  <img class="card-img-top addone" src="../../../assets/plus_sign.png" alt="Card image cap" (click)="open(content)">
</div>
