import { AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { AuthService } from './services/AuthService';
var AppComponent = /** @class */ (function () {
    function AppComponent(auth, cdr) {
        this.auth = auth;
        this.cdr = cdr;
    }
    AppComponent.prototype.ngAfterViewChecked = function () {
        this.cdr.detectChanges();
    };
    return AppComponent;
}());
export { AppComponent };
