import { ToastComponent } from '../../shared/toast/toast.component';
import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../../shared/toast/toast.component";
var Shared = /** @class */ (function () {
    function Shared(router, toast) {
        this.router = router;
        this.toast = toast;
    }
    Shared.prototype.RouteTo = function (path) {
        switch (path) {
            case ("styleAdvisor"):
                this.router.navigate(['/styleadvisor']);
                break;
            case ("computeDetectors"):
                this.router.navigate(['/computedetectors']);
                break;
            case ("visionproducts"):
                this.router.navigate(['/visionproducts']);
                break;
            default:
                this.toast.setMessage("Coming soon", "info");
        }
    };
    Shared.ngInjectableDef = i0.defineInjectable({ factory: function Shared_Factory() { return new Shared(i0.inject(i1.Router), i0.inject(i2.ToastComponent)); }, token: Shared, providedIn: "root" });
    return Shared;
}());
export { Shared };
