/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./load.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../shared/toast/toast.component.ngfactory";
import * as i3 from "../shared/toast/toast.component";
import * as i4 from "../shared/loading/loading.component.ngfactory";
import * as i5 from "../shared/loading/loading.component";
import * as i6 from "./load.component";
import * as i7 from "../services/AuthService";
import * as i8 from "@angular/router";
var styles_LoadComponent = [i0.styles];
var RenderType_LoadComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoadComponent, data: {} });
export { RenderType_LoadComponent as RenderType_LoadComponent };
export function View_LoadComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-toast", [], null, null, null, i2.View_ToastComponent_0, i2.RenderType_ToastComponent)), i1.ɵdid(1, 49152, null, 0, i3.ToastComponent, [], { message: [0, "message"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "container my-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-loading", [], null, null, null, i4.View_LoadingComponent_0, i4.RenderType_LoadingComponent)), i1.ɵdid(5, 49152, null, 0, i5.LoadingComponent, [], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.toast.message; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_LoadComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-load", [], null, null, null, View_LoadComponent_0, RenderType_LoadComponent)), i1.ɵdid(1, 114688, null, 0, i6.LoadComponent, [i7.AuthService, i8.Router, i3.ToastComponent], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoadComponentNgFactory = i1.ɵccf("app-load", i6.LoadComponent, View_LoadComponent_Host_0, {}, {}, []);
export { LoadComponentNgFactory as LoadComponentNgFactory };
