import { Component, OnInit } from '@angular/core';
import { ToastComponent } from "../shared/toast/toast.component";
import { ProductService } from "../services/ProductService";
import { ErrFmt } from "../util/helpers/err.helper";
import * as moment from 'moment';
import { Router } from '@angular/router';
import { AuthService } from '../services/AuthService';

import * as _ from 'lodash';

@Component({
  selector: 'app-load',
  templateUrl: './load.component.html',
  styleUrls: ['./load.component.scss']
})
export class LoadComponent implements OnInit {

  tags = [];
  posts = [];
  isLoading = false;

  constructor(
    private auth: AuthService,
    private router: Router,
    public toast: ToastComponent) {
  }

  ngOnInit() {
    if (localStorage.getItem("access_token")) {
      this.auth.loggedIn = true;
      this.router.navigate(['/styleadvisor']);
    } else {
      this.router.navigate(['/account/login']);
    }
  }
}
