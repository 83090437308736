import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { User } from '../shared/models/user.model';
import { map } from 'rxjs/operators';

@Injectable()
export class AuthService {
  loggedIn = false;
  token = null;
  user_id = null;
  loggedInUser = 0;

  currentUser: User = new User();

  constructor(
    private userService: UserService,
    private router: Router,
  ) {
    this.token = localStorage.getItem('access_token');
    this.user_id = localStorage.getItem('user_id');
  }

  public isAuthenticated(): boolean {
    if (this.token) {
      return true;
    }
    return false;
  }

  login(emailAndPassword) {
    return this.userService
      .login(emailAndPassword)
      .pipe(
        map(
          res => {
            if (res.access_token) {
              localStorage.setItem('access_token', res.access_token);
              this.loggedIn = false;
              this.token = res.access_token;
              return this.loggedIn;
            } else {
              throw new Error('Invalid Credentials');
            }
          },
        ),
      );
  }

  getUser(token) {
    return this.userService.getUser(token)
      .pipe(
        map(
          res => {
            if (res) {
              localStorage.setItem('user_id', res[0].user_id);
              localStorage.setItem('apikey', res[0].hash);
              this.user_id = localStorage.getItem('user_id');
              return true;
            } else {
              throw new Error('Could not get user');
            }
          }
        )
      );
  }

  logout() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('user_id');
    this.loggedIn = false;
    this.user_id = null;
    this.currentUser = new User();
    this.router.navigate(['/account/login']);
  }
}
