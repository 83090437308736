// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  apiURL: "http://api.wide-eyes.it",
  apiURLSTAGING: "http://api-staging.wide-eyes.net",
  apiURLMapping: "http://mapping.prod.wide-eyes.net",
  apiMirrorURL: "http://pro.api-mirror.wide-eyes.it",
  apiMirrorURLSTAGING: "http://api-mirror.staging.wide-eyes.net"
};
