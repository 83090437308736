var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
var ProductService = /** @class */ (function () {
    function ProductService(http) {
        this.http = http;
        this.apiURL = environment.apiURL;
        this.apiURLSTAGING = environment.apiURLSTAGING;
        this.apiURLMapping = environment.apiURLMapping;
        this.apiMirrorURL = environment.apiMirrorURL;
        this.apiMirrorURLSTAGING = environment.apiMirrorURLSTAGING;
    }
    ProductService.prototype.getProduct = function (uid, token, status) {
        var response = this.http.get(this.apiURL + "/v2/product/" + uid + "?status=" + status, { headers: this.getHeaders(token) });
        return response.toPromise();
    };
    ProductService.prototype.getProductMany = function (gender, category, subcategory, page, limit, token, NextToken) {
        if (NextToken === void 0) { NextToken = null; }
        var params = this.generateQueryString(gender, category, subcategory, page, limit, NextToken);
        return this.http.get(this.apiURL + "/v2/product?" + params, { headers: this.getHeaders(token) }).toPromise();
    };
    ProductService.prototype.insertOrUpdate = function (product, token) {
        return this.http.put(this.apiURL + "/v2/product/" + product.uid, product, { headers: this.getHeaders(token) }).toPromise();
    };
    ProductService.prototype.changeProductCategory = function (uid, body, token) {
        return this.http.put(this.apiURL + "/v2/product/" + uid + "/changeCategory", body, { headers: this.getHeaders(token) }).toPromise();
    };
    ProductService.prototype.getCategories = function (token) {
        return this.http.post(this.apiURL + "/v2/get_category_data", {}, { headers: this.getHeaders(token) }).toPromise();
    };
    ProductService.prototype.getCount = function (gender, category, subcategory, token) {
        var params = this.generateQueryString(gender, category, subcategory);
        return this.http.get(this.apiURL + "/v2/product/count?" + params, { headers: this.getHeaders(token) }).toPromise();
    };
    ProductService.prototype.getSimilars = function (uid, body, token) {
        return __awaiter(this, void 0, void 0, function () {
            var httpHeaders;
            return __generator(this, function (_a) {
                httpHeaders = new HttpHeaders({
                    'Content-Type': 'application/json',
                    'apikey': "" + token
                });
                return [2 /*return*/, this.http.post(this.apiURL + "/v4/SearchById", body, { headers: httpHeaders }).toPromise()];
            });
        });
    };
    ProductService.prototype.getTextSearch = function (body, token) {
        return __awaiter(this, void 0, void 0, function () {
            var httpHeaders;
            return __generator(this, function (_a) {
                httpHeaders = new HttpHeaders({
                    'Content-Type': 'application/json',
                    'apikey': "" + token
                });
                return [2 /*return*/, this.http.post(this.apiMirrorURL + "/v5/textSearch", body, { headers: httpHeaders }).toPromise()];
            });
        });
    };
    ProductService.prototype.deleteProduct = function (uid, token) {
        return this.http.delete(this.apiURL + "/v2/product/" + uid, { headers: this.getHeaders(token) }).toPromise();
    };
    ProductService.prototype.getHeaders = function (token) {
        var httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': "bearer " + token
        });
        return httpHeaders;
    };
    ProductService.prototype.generateQueryString = function (gender, category, subcategory, page, limit, NextToken) {
        if (page === void 0) { page = null; }
        if (limit === void 0) { limit = null; }
        if (NextToken === void 0) { NextToken = null; }
        var params = new HttpParams();
        if (gender) {
            params = params.append('gender', gender);
        }
        if (category) {
            params = params.append('category', category);
        }
        if (subcategory) {
            params = params.append('subcategory', subcategory);
        }
        if (page) {
            params = params.append('page', page);
        }
        if (limit) {
            params = params.append('limit', limit);
        }
        if (NextToken) {
            params = params.append('token', NextToken);
        }
        return params;
    };
    return ProductService;
}());
export { ProductService };
