<app-toast [message]="toast.message"></app-toast>
<div class="container">
  <div class="text-center">
    <app-loading [condition]="isLoading"></app-loading>
  </div>
  <ngb-tabset>
    <ngb-tab title="SuperCat mapping" id="SuperCat">
      <ng-template ngbTabContent>
        <table class="table table-striped">
          <tr>
            <th>Line number</th>
            <th>Detector's category</th>
            <th>Super category</th>
          </tr>
          <tr *ngFor="let line of DetectorMappings">
            <td>{{line.map_id}}</td>
            <td>{{line.detect_value}}</td>
            <td class="multiselect">
              <ng-multiselect-dropdown [placeholder]="'empty'" [settings]="getDropDownSettings('supercat_value')"
                [(ngModel)]="line.supercat">
              </ng-multiselect-dropdown>
            </td>
          </tr>
        </table>
      </ng-template>
    </ngb-tab>
    <ngb-tab>
      <ng-template ngbTabTitle>
        <div (click)="loadCategories()" class="black-list-load">Black List</div>
      </ng-template>
      <ng-template ngbTabContent>
        <ul class="mt-2">
          <h5>Check the values to add them into the black list</h5>
        </ul>
        <div class="black-list">
          <table class="table table-striped">
            <tr>
              <th>Num</th>
              <th>Gender</th>
              <th>Category</th>
              <th>Subcategory</th>
              <th><span class="d-inline-block" tabindex="0" data-toggle="tooltip"
                  title="Check it if do not generate outfit">AvoidCreateLook</span></th>
              <th><span class="d-inline-block" tabindex="0" data-toggle="tooltip"
                  title="Check it if do not be recommended by style advisor">AvoidStyleAdvisor</span></th>
              <th><span class="d-inline-block" tabindex="0" data-toggle="tooltip"
                  title="Check it if do not be recommemended by image search">AvoidRecognize</span></th>
            </tr>
            <tr style="width:10" *ngFor="let item of gender_cat_line; index as i">
              <td>{{i+1}}</td>
              <td>{{item.gender}}</td>
              <td>
                <input type="checkbox" [checked]=item.blackCategory id="category"
                  (click)="categoryToBlackList(item, $event, i)">
                <label class="gender-line-label">{{item.category}}</label>
              </td>
              <td>
                <input type="checkbox" [checked]=item.blackSubcategory id="subcategory"
                  (click)="categoryToBlackList(item, $event, i)">
                <label class="gender-line-label"> {{item.subcategory}} </label>
              </td>
              <td>
                <input type="checkbox" [checked]=item.AvoidLook id="AvoidLook"
                  (click)="categoryToBlackList(item, $event, i)">
              </td>
              <td>
                <input type="checkbox" [checked]=item.AvoidStyleAdvisor id="AvoidStyleAdvisor"
                  (click)="categoryToBlackList(item, $event, i)">
              </td>
              <td>
                <input type="checkbox" [checked]=item.AvoidRecognize id="AvoidRecognize"
                  (click)="categoryToBlackList(item, $event, i)">
              </td>
            </tr>
          </table>
          <button class="btn btn-success pull-right m-3" (click)="saveBlackList(content)">save
            blacklist</button>
        </div>
      </ng-template>
    </ngb-tab>
    <ngb-tab title="Recommend Order">
      <ng-template ngbTabContent>
        <div class="supercat-list noselect">
          <ul class="mt-2">
            <h5>Default category type fallback order</h5>
          </ul>
          <ul>
            <li *ngFor="let cat_fallback of DefaultCatTypeFallBack" (click)="addFallbackToList(cat_fallback)">
              <label for="cat_fallback">{{cat_fallback.fallback_value}}</label>
            </li>
            <li>
              <input type="text" class="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default"
                [(ngModel)]="newCatFallBack">
            </li>
            <li>
              <div class="add-more">
                <button class="btn btn-success" (click)="addCatFallback(newCatFallBack)">
                  <i class="fa fa-plus" aria-hidden="true"></i>&nbsp;add</button>
              </div>
            </li>
          </ul>
          <div class="col-md-10 order-fallbacks noselect">
            <div class="options example-list">
              <ul cdkDropList (cdkDropListDropped)="drop($event)">
                <li *ngFor="let cat_fallback of OrderedCatTypeFallBack; let i = index" cdkDrag class="example-box">
                  <h6>{{i+1}}.</h6>
                  <label for="cat_fallback">
                    <i class="fa fa-times pull-right mt-1 mr-1" aria-hidden="true"
                      (click)="removeFallbackFromList(cat_fallback)"> </i>
                    {{cat_fallback.fallback_value}}
                  </label>
                </li>
                <li class="m-10">
                  <div class="mb-10">
                    <button class="btn btn-success btn-long" (click)="saveCatFallbackOrder()">Save</button>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="clear"></div>
        </div>
      </ng-template>
    </ngb-tab>
  </ngb-tabset>
</div>

<ng-template #content let-c="close" class="bg-dark" let-d="dismiss">
  <div class="modal-body bg-dark">
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
    <div class="text-center">
      <app-loading [condition]="isLoading"></app-loading>
    </div>
    <div>
      <textarea name="" id="" cols="50" rows="50">
        {{showJsonBody}}
      </textarea>
    </div>
  </div>
</ng-template>
